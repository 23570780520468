<template>
  <!--  eslint-disable-->
  <div class="part-list supplier-rfq-view-part-list-container" @touchstart="touchClose" id="supplier-rfq-view-table">
    <b-table-simple :sticky-header="true"
                    class="supplier-rfq-view-part-list-container-2"
                    hover>
      <b-thead id="supplier-rfq-view-thead" class="additional-part-list--block-thead">
        <b-tr>
          <b-th class="part-list-line" id="supplier-rfq-view-line-column" style="left: 0px;">
            Line
          </b-th>
          <b-th class="part-list-part-name" id="supplier-rfq-view-part-list-column"
                :style="{left: getLeftPosition('supplier-rfq-view-part-list-column')}">
            Part List
            <span @click="rfq.sortBy('name')"></span>
          </b-th>
          <b-th v-if="isShowOePartNumberColumn" class="part-list-number text-center"
                id="supplier-rfq-view-oem-part-number-column">
            OE Part Number
            <span v-if="!isNewStatus" @click="rfq.sortBy('oemPartNumber')"></span>
          </b-th>
          <b-th v-if="isShowOePartNumberColumn" class="part-list-number text-center"
                id="supplier-rfq-view-oe-part-number-column">
            OE Reference Number
            <span v-if="!isNewStatus" @click="rfq.sortBy('oePartNumber')"></span>
          </b-th>
          <b-th class="part-list-number text-center" id="supplier-rfq-view-part-number-column">
            Part Number
            <span v-if="!isNewStatus" @click="rfq.sortBy('partNumber')"></span>
          </b-th>
          <b-th class="part-list-in-stock text-center" id="supplier-rfq-view-stock-column">
            <div v-if="!(isNewStatus || priceResubmitFlag)">
              <p>In Stock</p>
              <span v-if="!isNewStatus" @click="rfq.sortBy('status')"
                    style="top: 43px; left: 140px;"></span>
            </div>
            <div v-else>
              <p class="m-0">Stock Status</p>
              <template>
                <stock-select
                    :id="0"
                    :options="stockOptions"
                    v-model="stockForAll"
                    :is-disabled="isDisabledFields"
                    :isNeedDateRange="true"
                    :isStockAll="true"
                    @onSelected="selectedAllStock"
                    class="stock-select-header"
                    style="top: auto !important;"
                >
                </stock-select>
              </template>
            </div>

          </b-th>
          <b-th class="part-list-qty text-center" id="supplier-rfq-view-qty-column">
            Qty
            <span @click="rfq.sortBy('qty')"></span>
          </b-th>
          <b-th v-if="isRfqToOemGen" class="part-list-rrp text-center" id="supplier-rfq-view-rrp-column">
            List
            <span @click="rfq.sortBy('rrp')"></span>
          </b-th>
          <b-th class="part-list-company text-center"
                :id="'supplier-rfq-view-supplier'"
                :class="{'supplier-selected-column': isColumnClicked(companyInfo.id)}"
                @click="setClickedSupplier(companyInfo.id)"
                @mouseover="setActiveSupplier(companyInfo.id, isRfqToOemGen ? 7 : 6)"
                @mouseleave="setActiveSupplier(null, null)"
          >
            <div class="supplier-name text-center">
              <template v-if="isMultipleLocations">
                {{ rfq.supplierBusinessName }}
              </template>
              <template v-else>
                {{ companyInfo.businessName }}
              </template>
            </div>
            <div class="supplier-icon text-center">
              <img v-if="1==-1" :src="companyInfo.logoImgPath">

              <template v-if="isPricedStatus && isExistRequestBestPrice">
                <i
                    v-if="isLockedSupplierMain()"
                    class='bx bx-lock-alt' style="color: #bd5008;"
                    v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                    html: true,
                    title: 'This suppliers pricing is locked in.',
                    placement:'righttop',
                  }"></i>
                <i
                    v-if="isUnLockedSupplierMain()"
                    class='bx bx-lock-open-alt'
                    v-b-tooltip.hover="{
                      customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                      html: true,
                      title: '<b>Pricing field Unlocked,</b> Customers will see changes instantly.',
                      placement:'righttop',
                    }"
                    style="color: #4b8823;"></i>
              </template>
            </div>
            <div class="supplier-type text-center">
              {{ rfq.partsType }}
            </div>
            <div class="part-list-company-color" :style="{ background: getSupplierColor({type: rfq.partsType}) }"></div>
          </b-th>

          <b-th v-if="rfq.isRebateEnabled"
                @mouseover="setActiveSupplier('rebate', isRfqToOemGen ? 8 : 7)"
                @mouseleave="setActiveSupplier(null, null)"
                class="part-list-company text-center"
                :id="'supplier-rfq-view-rebate'">
            <div class="supplier-icon text-center">
            </div>
            <div style="min-height: 18px" class="supplier-name text-center">
              Rebate
            </div>
            <div class="supplier-type text-center">
              {{ rfq.partsType }}
            </div>
            <div class="part-list-company-color" :style="{ background: getSupplierColor({type: rfq.partsType}) }"></div>
          </b-th>


          <!-- Other suppliers block -->
          <b-th v-for="(supplier, ind) in filterSuppliers()" :key="supplier.id"
                @click="setClickedSupplier(supplier.id)"
                :class="{'supplier-selected-column': isColumnClicked(supplier.id)}"
                @mouseover="setActiveSupplier(supplier.id, getColumnIndex(ind))"
                @mouseleave="setActiveSupplier(null, null)"
                :id="'supplier-rfq-view-supplier-' + supplier.id"
                class="part-list-company-other text-center">
            <div class="supplier-name text-center">
              {{ supplier.name }}
              <div
                  v-if="supplier.isNonRegistered"
                  v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip supplier-icon-ps-tooltip-error non-reg-tooltip',
                    html: true,
                    title: '<b>Non-Registered Supplier.</b> This supplier has been added by the customer. The customer will add proof of pricing before ordering',
                    placement:'righttop',
                  }"
                  style="color: red">
                Non-Registered
              </div>
            </div>
            <div class="supplier-icon text-center">
              <img v-if="1==-1" :src="companyInfo.logoImgPath">
              <template v-if="isPricedStatus && isExistRequestBestPrice">
                <i
                    v-if="isLockedSupplier(supplier)"
                    class='bx bx-lock-alt' style="color: #bd5008;"
                   v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                    html: true,
                   title: supplier.isNonRegistered ? '<b>Pricing Locked.</b> This will not change while the RFQ is open again.' : 'This suppliers pricing is locked in.',
                    placement:'righttop',
                  }"></i>
                <i class='bx bx-error' style="color: #bd5008;"
                   v-if="supplier.isNonRegistered"
                   v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip supplier-icon-ps-tooltip-error',
                    html: true,
                    title: 'Repairer has shared pricing from a Non registered supplier.',
                    placement:'righttop',
                  }"></i>
                <i
                    v-if="supplier.isNonRegistered && supplier.pdfFile && supplier.pdfFile.src"
                    @click="$emit('selectedPdf', supplier.pdfFile.src)"
                    class='bx bxs-file-pdf'
                    v-b-tooltip.hover="{
                        customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                        html: true,
                        title: '<b>View PDF pricing</b> proof added by the customer',
                        placement:'righttop',
                      }"
                    style="color: #bd5008;"></i>
                <i
                    v-if="isUnLockedSupplier(supplier)"
                    v-b-tooltip.hover="{
                      customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                      html: true,
                      title: '<b>Pricing field Unlocked,</b> Customers will see changes instantly.',
                      placement:'righttop',
                    }"
                    class='bx bx-lock-open-alt' style="color: #4b8823;"></i>
              </template>

              <template v-if="(isNewStatus || (isPricedStatus && !isExistRequestBestPrice)) && supplier.isNonRegistered">
                <i class='bx bx-error' style="color: #bd5008;"
                   v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip supplier-icon-ps-tooltip-error',
                    html: true,
                    title: '<b>Non-Registered Supplier.</b> This supplier has been added by the customer. The customer will add proof of pricing before ordering',
                    placement:'righttop',
                  }"></i>
                <i
                    v-if="supplier.pdfFile && supplier.pdfFile.src"
                    @click="$emit('selectedPdf', supplier.pdfFile.src)"
                    class='bx bxs-file-pdf'
                    v-b-tooltip.hover="{
                        customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                        html: true,
                        title: '<b>Click to view</b> PDF added by the repairer',
                        placement:'righttop',
                      }"
                    style="color: #bd5008;"></i>
              </template>
            </div>
            <div class="supplier-type text-center">
              {{ supplier.type }}
            </div>
            <div class="part-list-company-color" :style="{ background: getSupplierColor(supplier) }"></div>
          </b-th>

          <b-th class="part-list-gst text-center" id="supplier-rfq-view-gst">
            GST
            <span @click="rfq.sortBy('gst')"></span>
          </b-th>
          <b-th class="part-list-total text-center" id="supplier-rfq-view-total">
            Total
            <span @click="rfq.sortBy('total')"></span>
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody class="table-hover-none">
        <b-tr>
          <b-td class="subheader subheader-p-l text-right supplier-info-cell" colspan="2">Pricing Completed</b-td>
          <b-td style="position: relative; z-index: 1" class="p-c-value text-left"
                v-for="(itm) in getColspanArray(isShowOePartNumberColumn ? 9 : 7)" :key="itm" colspan="1"
                :class="{'supplier-column-highlight-2': isActiveColumn2(itm + (isShowOePartNumberColumn ? 0 : 2))}">
            <template v-if="itm == 1">
              {{ rfq.pricingCompletedPercent }}%
            </template>
          </b-td>
        </b-tr>
        <b-tr>
          <template v-if="isHasNillSupply">
            <b-td style="opacity: 1; padding-left: 5px !important;"
                  class="subheader subheader-p-l bold text-right supplier-info-cell"
                  colspan="2">
              <div class="d-flex align-items-center">
                <button v-if="isShowNillSupply" @click="isShowNillSupply = false" class="btn btn-primary"
                        style="color: #1c1f39; box-shadow: none; background-color: white; width: 184px;">
                  Nil Supply Filter Applied
                </button>
                <button v-else class="btn btn-primary" @click="isShowNillSupply = true"
                        style="color: #29BBC1; box-shadow: none; border-color: #29BBC1; background-color: white; width: 184px;">
                  Apply: Nil Supply Filter
                </button>
                <span style="opacity: 0.5; padding-left: 145px;">Totals</span>
              </div>
            </b-td>
          </template>
          <template v-else>
            <b-td class="subheader subheader-p-l bold text-right supplier-info-cell" colspan="2">Totals</b-td>
          </template>
          <b-td class="p-c-value text-center" :colspan="(isRfqToOemGen)? 4: (isShowOePartNumberColumn ? 5 : 3)"
                style="position: relative; z-index: 1"></b-td>
          <b-td v-if="isDeclineStatus"

              :class="{'supplier-column-highlight-2': isActiveColumn(companyInfo.id),}"  class="p-c-value text-center h-totals bold decline-color"
                style="position: relative; z-index: 1">
            Declnd
          </b-td>
          <b-td v-else
                :class="{'supplier-column-highlight-2': isActiveColumn(companyInfo.id),}"
                class="p-c-value text-center h-totals bold"
                style="position: relative; z-index: 1">
            {{ rfq.total | formatMoney }}
          </b-td>
          <b-td v-if="rfq.isRebateEnabled" :class="{'supplier-column-highlight-2': isActiveColumn('rebate'),}"  style="position: relative; z-index: 1">
          </b-td>
          <!-- Other suppliers block -->

          <b-td v-for="(supplier) in filterSuppliers()" :key="supplier.id"
                :class="{'supplier-column-highlight-2': isActiveColumn(supplier.id),}"
                style="position: relative; z-index: 1"
                class="p-c-value text-center h-totals bold">
            <template v-if="supplier.status === 'Decline'">
              <span class="decline-color">Declnd</span>
            </template>
            <template v-else>
              {{ calculateSum(supplier.parts) | formatMoney }}
            </template>
          </b-td>
          <b-td class="subheader subheader-p-l bold" colspan="2" style="position: relative; z-index: 1"></b-td>
        </b-tr>
      </b-tbody>

      <draggable
          v-model="parts"
          class="dragArea"
          :class="{
                      'disabled-drag-area': isDisabledFields
                    }"
          tag="tbody"
          @change="onPartReorderChange"
          :move="checkMove"
          handle=".handle"
          :scroll-sensitivity="150"
          :force-fallback="true"
          :animation="150"
          :group="{
                       name: 'items',
                       put: true // not allow(false) or allow(true) items to be put into this list
                    }"
          ghost-class="ghost-items"
          :disabled="!(isNewStatus || priceResubmitFlag) || isDraggableDisabled || isDisabledFields"
          @start="startDraggable"
          @end="endDraggable"
      >
        <b-tr
            v-for="(part, key) in orderBy(parts)" :key="part && part.id ? part.id : getRandom()"
            :id="'part-' + (part && part.id ? part.id : getRandom())"
            v-show="!(part.stock == 7 && isShowNillSupply)"
            @mousedown="handleMouseDown"
            :class="{
              'part-values': true,
              'handle': !part.isOriginalPart && !part.isAdditional && !part.isLinked && !part.isManualByOemSupplier && !part.isExcludeFromDirectOrder,
              'opacity-06': part.stock == 7 || part.isExcludeFromDirectOrder, 'selected-row': part.isSelected || isPartInFocus(part),  'selected-part': selectedPartId === part.id }"
            class="supplier-rfq-view-part-line"
        >
          <!-- TODO: Recheck on merge into release because this strings can be overwritten -->
          <b-td class="part-list-line supplier-rfq-view-line-cell"
                :style="{left: getLeftPosition('supplier-rfq-view-line-column')}">
            <span class="part-list-line-number">{{ key + 1 }}</span>
          </b-td>
          <b-td class="part-list-part-name areaTouch supplier-rfq-view-part-list-cell"
                :style="{left: getLeftPosition('supplier-rfq-view-part-list-column')}"
                :class="{
                           'td-add-alt-name p-2': showAltPartNameId===part.id,
                            'td-alt-name': showAltPartNameId!==part.id,
                              'td-optional-part': part.isOptional,
                              'part-list-supplier-non-non-active': part.isAdditional
                            }"
                @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                @touchstart="touchStart($event, part, key)" @touchend="touchEnd" @touchmove="handleTouchMove">
            <div class="td-wrapper">
              <div class="part-list-item-name areaTouch" :class="{'has-link': part.isLinked,
                            }"
                   @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                   @touchstart="touchStart($event, part, key)" @touchend="touchEnd" @touchmove="handleTouchMove">
                <template
                    v-if="part.isManual && (isNewStatus || (isPricedStatus && priceResubmitFlag)) && !part.isAdditional">
                  <div class="d-flex flex-row align-items-center">
                    <b-input
                        :ref="'manual-name-' + part.id"
                        @change="onChange(part)"
                        @blur="onBlur(part, 'name')"
                        @mouseover="isDraggableDisabled = true"
                        @mouseleave="isDraggableDisabled = false"
                        @dblclick="$event.target.select()"
                        @focus="onFocus('partName-' + part.id)"
                        placeholder="Add Part Name"
                        class="form-control"
                        type="text"
                        :disabled="part.isNotApplicable || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                        @keydown.left="debounceOnKeyPressLeft('manual-name-' + part.id, $event, getCaretPosition($event.srcElement))"
                        @keydown.right="debounceOnKeyPressRight('manual-name-' + part.id, $event, getCaretPosition($event.srcElement))"
                        @keydown.up="debounceOnKeyPressUp('manual-name-' + part.id)"
                        @keydown.down="debounceOnKeyPressDown('manual-name-' + part.id)"
                        v-model="part.name">
                    </b-input>
                    <i @click="handleSaving(removePart(part, key))"
                       v-if="!isDisabledFields && !part.isManualByOemSupplier"
                       @mouseover="isDraggableDisabled = true"
                       @mouseleave="isDraggableDisabled = false"
                       class='bx bx-x-circle font-20 mr-2 remove-part ml-2'
                       v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table', html: true, title: 'Remove', placement:'leftbottom'}">
                    </i>
                  </div>
                </template>
                <template
                    v-else-if="part.isDelivery && (isNewStatus || (isPricedStatus && priceResubmitFlag)) && !part.isAdditional">
                  <div class="d-flex flex-row align-items-center">
                    <b-input
                        :ref="'deliveryName-' + part.id"
                        @keydown.left="debounceOnKeyPressLeft('deliveryName-' + part.id, $event, getCaretPosition($event.srcElement))"
                        @keydown.right="debounceOnKeyPressRight('deliveryName-' + part.id, $event, getCaretPosition($event.srcElement))"
                        @mouseover="isDraggableDisabled = true"
                        @mouseleave="isDraggableDisabled = false"
                        @keydown.up="debounceOnKeyPressUp('deliveryName-' + part.id)"
                        @keydown.down="debounceOnKeyPressDown('deliveryName-' + part.id)"
                        @dblclick="$event.target.select()"
                        @change="onChange(part)"
                        @blur="onBlur(part, 'deliveryName')"
                        placeholder="Add Delivery Name"
                        class="form-control"
                        type="text"
                        :disabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                        v-model="part.deliveryName">
                    </b-input>

                    <i @click="handleSaving(removePart(part, key))"
                       v-if="!isDisabledFields && !part.isManualByOemSupplier"
                       @mouseover="isDraggableDisabled = true"
                       @mouseleave="isDraggableDisabled = false"
                       class='bx bx-x-circle font-20 mr-2 remove-part ml-2'
                       v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table', html: true, title: 'Remove', placement:'leftbottom'}">
                    </i>
                  </div>
                </template>
                <template v-else-if="part.isOptional">
                                    <span v-if="!part.isRemovedByRepairer" class="d-block">
                                  {{ part.name }}
                                </span>
                  <span v-else class="d-block" style="color: #afafaf">
                                  {{ part.name }}
                                  (Removed By Repairer)
                                </span>
                  <b-form-invalid-feedback :state="checkName(part.name)" class="m-0 font-12"
                                           v-if="enableOptionalCheck">
                    <span>Part type has not been set</span>
                  </b-form-invalid-feedback>
                </template>
                <template v-else>
                  <template v-if="part.isDelivery || part.isManual">
                                    <span v-if="!part.isRemovedByRepairer" class="d-block part-name-from-supplier">
                                      {{ (part.isDelivery) ? part.deliveryName : part.name }}
                                  </span>
                    <span v-else class="d-block">
                                    {{ (part.isDelivery) ? part.deliveryName : part.name }}
                                    (Removed By Repairer)
                                  </span>
                  </template>
                  <template v-else>
                                    <span v-if="!part.isRemovedByRepairer" class="d-block">
                                     {{ (part.isDelivery) ? part.deliveryName : part.name }}
                                  </span>
                    <span v-else class="d-block" style="color: #afafaf">
                                    {{ (part.isDelivery) ? part.deliveryName : part.name }}
                                    (Removed By Repairer)
                                  </span>
                  </template>
                  <!-- <div> -->

                  <b-form-invalid-feedback :state="checkName(part.name)" class="m-0 font-12"
                                           v-if="part.isOptional && enableOptionalCheck">
                    <span>Part type has not been set</span>
                  </b-form-invalid-feedback>
                  <template
                      v-if="showAltPartNameId===part.id || (isPricedStatus && priceResubmitFlag) && !part.isAdditional">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                      <b-input
                          :ref="'altPartName-' + part.id"
                          @change="onChange(part)"
                          @blur="onBlur(part, 'altPartName')"
                          @mouseover="isDraggableDisabled = true"
                          @mouseleave="isDraggableDisabled = false"
                          @dblclick="$event.target.select()"
                          @focus="onFocus('altPartName-' + part.id)"
                          placeholder="Add Part Name" class="w-90" type="text"
                          :disabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                          v-model="part.altPartName"></b-input>
                      <div class="ml-2">
                        <i class='bx bx-x-circle  mr-2 remove-part'
                           v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table', html: true, title: 'Remove', placement:'leftbottom'}"
                           @click="showAltPartNameId=0; part.altPartName=''; onChange(part)"></i>
                      </div>
                    </div>
                    <div v-if="isShowErrorMessage(part)" style="max-width: 305px;" class="error-text">
                      {{ getErrorMessage(part) }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-block div-add-alt-name">
                      {{ part.altPartName }}
                    </div>
                  </template>
                  <!-- </div> -->
                </template>
              </div>

              <!-- icons -->
              <div class="part-list-part-name__icon-group">
                <template v-if="part.photoForPart && part.photoForPart.id">
                  <i v-if="isMobile" class='bx bx-image-alt text-warning'
                     @touchstart="onPhotoIconTouch(part.id)"
                     :ref="'icon-photo-' + part.id"
                     v-b-tooltip.click="{customClass: 'ps-tooltip', title: tooltipContent(part), html: true, placement:'leftbottom'}"></i>
                  <i v-else class='bx bx-image-alt text-warning'
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', title: tooltipContent(part), html: true, placement:'leftbottom'}"></i>
                </template>

                <i v-if="part.isLinked"
                   class='bx bx-link part-list-part-name__icon'
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${linkedTo(part)}`, placement:'leftbottom'}"></i>
                <!-- <i v-if="isHasComment(part)"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getComment(part), placement:'leftbottom'}"
                   class='bx bx-note part-list-part-name__icon part-list-part-name__icon--comment text-warning'></i>-->
                <i v-if="rfq.isPartSelected(part.originalPartId, part.id)"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Part Ordered', placement:'leftbottom'}"
                   class='bx bxs-check-circle check-icon float-right position-relative'></i>
                <i v-else-if="isWonStatus || isOrderedStatus"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Part not Ordered', placement:'leftbottom'}"
                   class='bx bxs-minus-circle check-icon float-right position-relative'></i>
                <!------------------->
                <!-- NEW ICONS GROUP -->
                <i class="font-20"
                   v-if="isNewStatus"
                   :class="{
                                    // In Stock
                                    'bx bx-check-circle green-icon': part.selectedOption.label === 'In Stock',
                                    // EX Warehouse
                                    'bx bx-check-circle orange-icon': part.selectedOption.label === 'EX Warehouse',
                                    // Low Stock
                                    'bx bx-check-circle blue-icon': part.selectedOption.label === 'Low Stock',
                                    // No Stock, No Longer Available, Not Applicable
                                    'bx bx-minus-circle gray-icon': part.selectedOption.label === 'No Stock' || part.selectedOption.label === 'No Longer Avail' || part.selectedOption.label === 'Not Applicable',
                                    // Back Order
                                    'bx bx-check-circle red-icon icon-red-blinking': part.selectedOption.label === 'On Back Order',
                                  }"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockLabel(part), placement:'leftbottom'}">
                </i>
                <!-------------------><i
                    v-if="part.nonReturnable"
                    class='bx bx-error font-20 orange-icon'
                    v-b-tooltip.hover="{customClass: getCustomClass(part), html: true, title: getNRTooltip(part), placement:'leftbottom'}"></i>

              </div>
              <!--              <div class="" v-if="isNewStatus && !isRfqToOemGen && part.hasOwnProperty('oemPartNumber')">-->
              <!--                <b-input-->
              <!--                    placeholder="OEM Part Number"-->
              <!--                    class="form-control"-->
              <!--                    v-model="part.oemPartNumber"-->
              <!--                    type="text"-->
              <!--                    :readonly="true"-->
              <!--                    :disabled="isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"-->
              <!--                >-->
              <!--                </b-input>-->
              <!--              </div>-->
            </div>
          </b-td>

          <b-td v-if="isShowOePartNumberColumn" class="part-list-number text-center p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)">
            <div class="part-list-item-number">
              <div class="">
                <!--              <div class="" v-if="isNewStatus && !isRfqToOemGen && part.hasOwnProperty('oemPartNumber')">-->
                <b-input
                    placeholder="OEM Part Number"
                    class="form-control"
                    v-model="part.oemPartNumber"
                    type="text"
                    :readonly="true"
                    :disabled="isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder || rfq.secondRequestBestPrice || rfq.thirdRequestBestPrice"
                >
                </b-input>
              </div>
            </div>
          </b-td>

          <b-td v-if="isShowOePartNumberColumn" class="part-list-number text-center p-2 align-top oe-part-number" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'position-relative': isShowGrayTriangle(part)}">
            <div class="d-flex">
              <input
                  placeholder="OE Ref Nbr"
                  class="form-control"
                  :ref="'oe-part-number-' + part.id"
                  @blur="onBlur(part, 'oePartNumber')"
                  @focus="onFocus('oe-number-' + part.id)"
                  @change="onChange(part)"
                  v-model="part.oePartNumber"
                  type="text"
                  @input="handleInput"
                  @keydown.left="debounceOnKeyPressLeft('oe-part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                  @keydown.right="debounceOnKeyPressRight('oe-part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                  @keydown.up="debounceOnKeyPressUp('oe-part-number-' + part.id)"
                  @keydown.down="debounceOnKeyPressDown('oe-part-number-' + part.id)"
                  :disabled="isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder || rfq.secondRequestBestPrice || rfq.thirdRequestBestPrice"
              />
              <i
                  v-if="isShowGreenCheck(part)"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'OE reference nbr matches', placement:'leftbottom'}"
                  class='bx bx-check oe-part-number-check'
              ></i>
              <div
                  v-if="isShowGrayTriangle(part)"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'OE reference nbr does not match', placement:'leftbottom'}"
                  class='oe-part-number-icon'></div>
            </div>
          </b-td>

          <b-td class="part-list-number text-center p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)">
            <div :class="{'color-black': rfq.isPartSelected(part.originalPartId, part.id)}"
                 class="part-list-item-number">
              <template v-if="(isNewStatus || priceResubmitFlag) && !part.isAdditional">
                <input
                    :ref="'part-number-' + part.id"
                    :disabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                    v-if="part.isDelivery"
                    @dblclick="$event.target.select()"
                    @mouseover="isDraggableDisabled = true"
                    @mouseleave="isDraggableDisabled = false"
                    @change="onChange(part)"
                    @blur="onBlur(part, 'deliveryZone')"
                    @focus="onFocus('name-' + part.id)"
                    type="text"
                    @input="handleInput"
                    class="form-control"
                    :class="{
                        'direct-blinking-red': isClickedOnSendPrices && _isEmpty(part.deliveryZone) && !part.isExcludeFromDirectOrder
                    }" @keydown.left="debounceOnKeyPressLeft('part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                    @keydown.right="debounceOnKeyPressRight('part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                    @keydown.up="debounceOnKeyPressUp('part-number-' + part.id)"
                    @keydown.down="debounceOnKeyPressDown('part-number-' + part.id)"
                    v-model="part.deliveryZone"/>
                <input
                    :ref="'part-number-' + part.id"
                    :disabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                    v-else
                    @change="onChange(part)"
                    @dblclick="$event.target.select()"
                    @mouseover="isDraggableDisabled = true"
                    @mouseleave="isDraggableDisabled = false"
                    @blur="onBlur(part, 'number')"
                    @input="handleInput"
                    class="form-control"
                    @focus="onFocus('name-' + part.id)"
                    type="text" :class="{
                      'direct-blinking-red': isClickedOnSendPrices && _isEmpty(part.number) && !part.isExcludeFromDirectOrder
                    }"
                    @keydown.left="debounceOnKeyPressLeft('part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                    @keydown.right="debounceOnKeyPressRight('part-number-' + part.id, $event, getCaretPosition($event.srcElement))"
                    @keydown.up="debounceOnKeyPressUp('part-number-' + part.id)"
                    @keydown.down="debounceOnKeyPressDown('part-number-' + part.id)"
                    v-model="part.number"/>
              </template>
              <template v-else>
                <div class="part-list-color-black">
                  <div style="padding: 10px 0">
                    {{ (part.isDelivery) ? part.deliveryZone : part.number }}
                  </div>
                </div>
              </template>
            </div>
            <div class="">
              <div v-if="isHasShortComment(part)"
                   class="part-number-stock-gray"
                   :style="{'text-align':(isNewStatus || priceResubmitFlag) && !part.isAdditional? 'start':'center',
                               'padding-left':(isNewStatus || priceResubmitFlag) && !part.isAdditional? '10px':'0px'
                               }"
                   :class="{
                                'part-number-stock-orange': part.stock === 6,
                                'part-number-stock-red': part.stock === 4,
                               }">
                {{ getShortComment(part) }}
              </div>
            </div>
          </b-td>

          <b-td class="part-list-in-stock text-center p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'td-selected': rfq.isPartSelected(part.originalPartId, part.id)}">
            <div class="part-list-item-in-stock">
              <template v-if="part.stock == 7">
                <div class="" style="padding-top: 10px;">
                  Nil Supply
                </div>
              </template>
              <template v-else-if="(part.isLinked && part.isLinkedWithNoCost) || part.isDelivery">
                <div></div>
              </template>
              <template v-else-if="(isNewStatus || priceResubmitFlag || (isUnLockedSupplierMain() && isPricedStatus)) && !part.isAdditional">
                <stock-select
                    :id="part.id"
                    :options="stockOptions"
                    @mouseover="isDraggableDisabled = true"
                    @mouseleave="isDraggableDisabled = false"
                    v-model="part.selectedOption"
                    @onSelected="selectStockStatus($event, key)"
                    @onKeyPressRight="debounceOnKeyPressRight('stock-select-' + part.id, $event,)"
                    @onKeyPressLeft="debounceOnKeyPressLeft('stock-select-' + part.id, $event,)"
                    :ref="'stock-select-' + part.id"
                    :isNeedDateRange="true"
                    :class="{
                      'direct-blinking-red-stock': isClickedOnSendPrices && part.stock == 0 && !part.isExcludeFromDirectOrder
                    }"
                    :is-disabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                    @blur="onBlur(part, 'selectedOption')"
                    @focus="onFocus('stock-select-' + part.id)"
                >
                </stock-select>
              </template>
              <template v-else>
                <div class="part-list-color-black d-flex align-items-center justify-content-center">
                  <div class="text-right" style="padding: 10px 5px; white-space: nowrap;">
                    {{ part.selectedOption.label }}
                  </div>
                  <i class="font-20"
                     v-if="(isNewStatus || priceResubmitFlag || (isUnLockedSupplierMain() && isPricedStatus)) && !part.isAdditional"
                     style="margin-left: 5px;"
                     :class="{
                       // In Stock
                       'bx bx-check-circle green-icon': part.selectedOption.label === 'In Stock',
                       // EX Warehouse
                       'bx bx-check-circle orange-icon': part.selectedOption.label === 'EX Warehouse',
                       // Low Stock
                       'bx bx-check-circle blue-icon': part.selectedOption.label === 'Low Stock',
                       // No Stock, No Longer Available, Not Applicable
                       'bx bx-minus-circle gray-icon': part.selectedOption.label === 'No Stock' || part.selectedOption.label === 'No Longer Avail' || part.selectedOption.label === 'Not Applicable',
                       // Back Order
                       'bx bx-check-circle red-icon icon-red-blinking': part.selectedOption.label === 'On Back Order',
                     }"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockLabel(part), placement:'leftbottom'}">
                  </i>
                </div>
              </template>
            </div>
          </b-td>

          <b-td class="part-list-qty text-center align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'td-selected': rfq.isPartSelected(part.originalPartId, part.id)}">
            <template v-if="(part.isLinked && part.isLinkedWithNoCost)  || part.isDelivery">
              <div></div>
            </template>
            <template v-else>
              <div class="part-list-item-qty p-0">
                <span v-if="part.isNotApplicable"></span>
                <numeral
                    :ref="'qty-' + part.id"
                    v-else-if="(isRFQNew || priceResubmitFlag) && !part.isAdditional"
                    @change="onChange(part)"
                    @blur="onBlur(part, 'qty')"
                    @focus="onFocus('qty-' + part.id)"
                    format="0,0"
                    class="w-100 form-control float-right"
                    :isDisabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                    @mouseover="isDraggableDisabled = true"
                    @mouseleave="isDraggableDisabled = false"
                    @onKeyPressRight="event => debounceOnKeyPressRight('qty-' + part.id, event, getCaretPosition(event.srcElement))"
                    @onKeyPressLeft="event => debounceOnKeyPressLeft('qty-' + part.id, event, getCaretPosition(event.srcElement))"
                    @onKeyPressUp="debounceOnKeyPressUp('qty-' + part.id)"
                    @onKeyPressDown="debounceOnKeyPressDown('qty-' + part.id)"
                    style="width: 44px !important; text-align: center; padding: 0.375rem 0rem;"
                    v-model="part.qty">
                </numeral>
                <div class="d-flex" style="padding-top: 10px" v-else>
                  <span>{{ part.qty }}</span>
                  <i v-if="part.originalQty - part.qty < 0"
                     style="transform: translateY(0px)"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
                     class="bx bx-up-arrow-alt qty-icon-arrow-up qty-icon-arrow">
                  </i>
                  <i v-else-if="part.originalQty - part.qty > 0"
                     style="transform: translateY(0px)"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
                     class="bx bx-down-arrow-alt qty-icon-arrow-down qty-icon-arrow">
                  </i>
                </div>
              </div>
            </template>
          </b-td>

          <b-td v-if="isRfqToOemGen" class="part-list-rrp text-right p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'td-selected': rfq.isPartSelected(part.originalPartId, part.id)}">
            <div class="part-list-item-rrp text-center"
                 :class="{'green-cell': (part.firstRrp > 0 && part.firstRrp<Number(part.rrp)), 'red-cell':(part.firstRrp > 0 && part.firstRrp>Number(part.rrp))}">
              <template v-if="(part.isLinked && part.isLinkedWithNoCost)  || part.isDelivery">
                <div></div>
              </template>
              <template v-else-if=" (isNewStatus || priceResubmitFlag || (isUnLockedSupplierMain() && isPricedStatus)) && !part.isAdditional">
                <numeral :ref="'rrp-' + part.id"

                         @change="onChange(part)"
                         @blur="onBlur(part, 'rrp')"
                         @focus="onFocus('rrp-' + part.id)"
                         @mouseover="isDraggableDisabled = true"
                         @mouseleave="isDraggableDisabled = false"
                         @onKeyPressRight="event => debounceOnKeyPressRight('rrp-' + part.id, event, getCaretPosition(event.srcElement))"
                         @onKeyPressLeft="event => debounceOnKeyPressLeft('rrp-' + part.id, event, getCaretPosition(event.srcElement))"
                         @onKeyPressUp="debounceOnKeyPressUp('rrp-' + part.id)"
                         @onKeyPressDown="debounceOnKeyPressDown('rrp-' + part.id)" format="$0.00"
                         class="w-100 form-control float-right"
                         :class="{
                      'green-cell': (part.firstRrp > 0 && part.firstRrp<Number(part.rrp)),
                      'red-cell':(part.firstRrp > 0 && part.firstRrp>Number(part.rrp)),
                      'direct-blinking-red': isClickedOnSendPrices && Number(part.rrp) == 0 && !part.isExcludeFromDirectOrder
                    }"
                         :isDisabled=" isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                         v-model="part.rrp"></numeral>
                <!--                                <div v-else class="form-control" style="height: 32px;"></div>-->
              </template>
              <template v-else>
                <div class="" style="padding-top: 10px">
                  {{ part.rrp | formatMoney }}
                </div>
              </template>
            </div>
          </b-td>

          <b-td class="part-list-company-price text-center p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{
                  'td-selected': rfq.isPartSelected(part.originalPartId, part.id),
                  'supplier-column-highlight-2': isActiveColumn(companyInfo.id),
                  'supplier-selected-cell': isColumnClicked(companyInfo.id),

                }">
            <div class="part-list-item-company-price"
                 :class="{
              'decline-color': (isDeclineStatus),
              'green-cell': (!isDeclineStatus && part.firstPrice > 0 && part.firstPrice < Number(part.price)),
               'red-cell':(!part.isRfqToSupplierDecline &&part.firstPrice > 0 && part.firstPrice>Number(part.price)),
                  'waiting-cell': !isDeclineStatus && !(part.isLinked && part.isLinkedWithNoCost) && ((isNewStatus || priceResubmitFlag) && !part.isAdditional) && isShowCalculatedUSEDPrice(part) && ((!_isNumber(part.oemListPrice) && !_isString(part.oemListPrice)) || part.oemListPrice === -1)
            }">
              <template v-if="isDeclineStatus">
                Declnd
              </template>
              <template v-else-if="(part.isLinked && part.isLinkedWithNoCost) ">
                <div></div>
              </template>
              <template v-else-if=" (isNewStatus || priceResubmitFlag || (isUnLockedSupplierMain() && isPricedStatus)) && !part.isAdditional">
                <template v-if="isShowCalculatedUSEDPrice(part)">
                  <template
                      v-if="(!(Number(part.oemListPrice) > 0) && !(Number(part.price) > 0)) && !part.isClickedOnWaitingText">
                    <span class="price-blinking-black" @click="clickOnWaitingText(part)">
                      WTNG
                    </span>
                  </template>
                  <template v-else>
                    <div
                        v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-for-used-price', html: true, title: getTooltipForUsedSupp(part), placement:'leftbottom'}">
                      <numeral
                          v-if="!part.isNotApplicable"
                          :ref="'price-' + part.id"
                          @change="onChange(part)"
                          @blur="onBlur(part, 'price'); $set(part,'isClickedOnWaitingText',false)"
                          @focus="onFocus('price-' + part.id)"
                          @mouseover="isDraggableDisabled = true"
                          @mouseleave="isDraggableDisabled = false"
                          @onKeyPressRight="event => debounceOnKeyPressRight('price-' + part.id, event, getCaretPosition(event.srcElement))"
                          @onKeyPressLeft="event => debounceOnKeyPressLeft('price-' + part.id, event, getCaretPosition(event.srcElement))"
                          @onKeyPressUp="debounceOnKeyPressUp('price-' + part.id)"
                          @onKeyPressDown="debounceOnKeyPressDown('price-' + part.id)"
                          format="$0.00"
                          class="w-100 form-control"
                          :class="{
                            'green-cell': (part.firstPrice > 0 && part.firstPrice<Number(part.price)),
                            'red-cell':(part.firstPrice > 0 && part.firstPrice>Number(part.price)),
                            'direct-blinking-red': isClickedOnSendPrices && Number(part.price) == 0 && !part.isExcludeFromDirectOrder
                          }"
                          :isDisabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                          v-model="part.price">
                      </numeral>
                      <!--                      <rebate-numeral-->
                      <!--                          v-if="!part.isNotApplicable"-->
                      <!--                          :ref="'used-price-' + part.id"-->
                      <!--                          v-model="part.price"-->
                      <!--                          @change="onChange(part)"-->
                      <!--                          @blur="onBlur(part, 'price'); $set(part,'isClickedOnWaitingText',false)"-->
                      <!--                          @focus="onFocus('used-price-' + part.id)"-->
                      <!--                          @mouseover="isDraggableDisabled = true"-->
                      <!--                          @mouseleave="isDraggableDisabled = false"-->
                      <!--                          @onKeyPressRight="event => debounceOnKeyPressRight('used-price-' + part.id, event)"-->
                      <!--                          @onKeyPressLeft="event => debounceOnKeyPressLeft('used-price-' + part.id, event)"-->
                      <!--                          @onKeyPressUp="debounceOnKeyPressUp('used-price-' + part.id)"-->
                      <!--                          @onKeyPressDown="debounceOnKeyPressDown('used-price-' + part.id)"-->
                      <!--                          :isRebate="true"-->
                      <!--                          :rebate="getPriceForUSEDSupp(part)"-->
                      <!--                          :isUseAnotherValueForComparison="false"-->
                      <!--                          :anotherValue="part.price"-->
                      <!--                          format="$0.00"-->
                      <!--                          class="w-100 form-control"-->
                      <!--                          :class="{'green-cell': (part.firstPrice > 0 && part.firstPrice<part.price), 'red-cell':(part.firstPrice > 0 && part.firstPrice>part.price)}"-->
                      <!--                          :isDisabled="part.isNotApplicable || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"-->
                      <!--                      >-->
                      <!--                      </rebate-numeral>-->
                    </div>
                  </template>
                </template>
                <template v-else>
                  <numeral
                      :ref="'price-' + part.id"
                      v-if="!part.isNotApplicable"
                      @change="onChange(part)"
                      @blur="onBlur(part, 'price')"
                      @focus="onFocus('price-' + part.id)"
                      @mouseover="isDraggableDisabled = true"
                      @mouseleave="isDraggableDisabled = false"
                      @onKeyPressRight="event => debounceOnKeyPressRight('price-' + part.id, event, getCaretPosition(event.srcElement))"
                      @onKeyPressLeft="event => debounceOnKeyPressLeft('price-' + part.id, event, getCaretPosition(event.srcElement))"
                      @onKeyPressUp="debounceOnKeyPressUp('price-' + part.id)"
                      @onKeyPressDown="debounceOnKeyPressDown('price-' + part.id)"
                      format="$0.00"
                      class="w-100 form-control"
                      :class="{
                            'green-cell': (part.firstPrice > 0 && part.firstPrice<Number(part.price)),
                            'red-cell':(part.firstPrice > 0 && part.firstPrice>Number(part.price)),
                            'direct-blinking-red': isClickedOnSendPrices && Number(part.price) == 0 && !part.isExcludeFromDirectOrder
                      }"
                      :isDisabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                      v-model="part.price">
                  </numeral>
                </template>
                <div v-else class="form-control" style="height: 32px;"></div>
              </template>
              <template v-else>
                <div
                    class="part-list-color-black"
                    :class="{
                      'green-cell': (!isDeclineStatus && part.firstPrice > 0 && part.firstPrice < Number(part.price)),
                      'red-cell':(!part.isRfqToSupplierDecline && part.firstPrice > 0 && part.firstPrice>Number(part.price)),
                      'part-price-with-icon': !isNewStatus && part.selectedOption.label && part.selectedOption.label !== 'None'
                    }"
                >
                    <div class="text-right" style="padding: 10px 5px; white-space: nowrap;">
                      {{ part.price | formatMoney }}
                    </div>
                  <i class="font-20"
                     v-if="!(isNewStatus)"
                     :class="{
                       // In Stock
                       'bx bx-check-circle green-icon': part.selectedOption.label === 'In Stock',
                       // EX Warehouse
                       'bx bx-check-circle orange-icon': part.selectedOption.label === 'EX Warehouse',
                       // Low Stock
                       'bx bx-check-circle blue-icon': part.selectedOption.label === 'Low Stock',
                       // No Stock, No Longer Available, Not Applicable
                       'bx bx-minus-circle gray-icon': part.selectedOption.label === 'No Stock' || part.selectedOption.label === 'No Longer Avail' || part.selectedOption.label === 'Not Applicable',
                       // Back Order
                       'bx bx-check-circle red-icon icon-red-blinking': part.selectedOption.label === 'On Back Order',
                     }"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockLabel(part), placement:'leftbottom'}">
                  </i>
                </div>
              </template>
            </div>
          </b-td>

          <b-td v-if="rfq.isRebateEnabled" class="part-list-company-price text-center p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'supplier-column-highlight-2': isActiveColumn('rebate'),}">
            <div class="part-list-item-company-price"
                 :class="{'decline-color': (isDeclineStatus)}">
              <template v-if="isDeclineStatus">
                Declnd
              </template>
              <template v-else-if=" (isNewStatus || priceResubmitFlag || (isUnLockedSupplierMain() && isPricedStatus)) && !part.isAdditional">
                <div v-if="part.isLinkedWithNoCost" class=""></div>
                <rebate-numeral
                    :ref="'rebate-' + part.id"
                    v-else-if="!part.isNotApplicable"
                    @change="onChange(part)"
                    @blur="onBlur(part, 'rebate')"
                    @focus="onFocus('rebate-' + part.id)"
                    @mouseover="isDraggableDisabled = true"
                    @mouseleave="isDraggableDisabled = false"
                    @onKeyPressRight="event => debounceOnKeyPressRight('rebate-' + part.id, event, getCaretPosition(event.srcElement))"
                    @onKeyPressLeft="event => debounceOnKeyPressLeft('rebate-' + part.id, event, getCaretPosition(event.srcElement))"
                    @onKeyPressUp="debounceOnKeyPressUp('rebate-' + part.id)"
                    @onKeyPressDown="debounceOnKeyPressDown('rebate-' + part.id)"
                    :isRebate="true"
                    :rebate="getRebate(part)"
                    format="$0.00"
                    class="w-100 form-control"
                    :isDisabled="part.isNotApplicable || part.stock == 7 || isDisabledFields || part.isManualByOemSupplier || part.isExcludeFromDirectOrder"
                    v-model="part.rebate">
                </rebate-numeral>
                <div v-else class="form-control" style="height: 32px;"></div>
              </template>
              <template v-else>
                <div class="part-list-color-black">
                  {{ getRebate(part) }}
                </div>

              </template>
            </div>
          </b-td>

          <!-- Other suppliers block -->
          <b-td
              v-for="(supplier) in filterSuppliers()"
              @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
              :key="supplier.id"
              class="part-list-company-price-other text-center p-2 align-top"
              :class="{
                'supplier-selected-cell': isColumnClicked(supplier.id),
                'supplier-column-highlight-2': isActiveColumn(supplier.id),
              }"
          >
            <div class="part-list-item-company-price-other"
                 :class="{'decline-color':supplier && supplier.status === 'Decline' }">
              <template v-if="supplier && supplier.status && supplier.status === 'Decline'">
                Declnd
              </template>
              <template v-else>
                <div v-for="(sPrice,ind) in supplier.parts" :key="ind">
                  <template
                      v-if="part.originalPartId && Number(sPrice.originalPartId) === Number(part.originalPartId)">
                    <template v-if="sPrice.price || (sPrice.stock && sPrice.stock !== 'none')">
                      <div
                          class="d-flex"
                          :class="{
                                           'blinking-red': sPrice && sPrice.firstPrice > sPrice.price,
                                           'blinking-green': sPrice && sPrice.firstPrice < sPrice.price,
                                           'justify-content-end': !(isNewStatus || isPricedStatus) || (supplier.isNonRegistered) || (isRfqToOemGen && isUnLockedSupplierMain()),
                                           'justify-content-center': (isNewStatus || isPricedStatus) || (supplier.isNonRegistered) || (isRfqToOemGen && isUnLockedSupplierMain()),
                                          }"
                      >
                        <div v-show="sPrice.price !== null" class="text-right" style="padding: 10px 5px; white-space: nowrap;">
                        {{ sPrice.price | formatMoney }}
                      </div>
                        <div v-if="!supplier.isNonRegistered && !(isRfqToOemGen && isUnLockedSupplierMain()) && ((isNewStatus || isPricedStatus) && (sPrice.stock === 'NoStock' || sPrice.stock === 'NilSupply' ))"
                             class="d-flex justify-content-center flex-column">
                          <i v-if="sPrice.stock === 'NoStock'"
                             class="bx bx-minus-circle gray-icon font-20"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'No Stock', placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'NilSupply'"
                           class="bx bx-minus-circle gray-icon font-20"
                           style="margin-left:5px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'Nil Supply', placement:'leftbottom'}">
                        </i>
                      </div>
                        <i
                            v-else-if="!supplier.isNonRegistered && sPrice && sPrice.firstPrice < sPrice.price"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip custom-rfq-price-tooltip', html: true, title: getTooltipForPriceIcon(sPrice), placement:'rightbottom'}"
                            style="font-size: 16px; color: #0cb458; display:flex; align-items: center; "
                            class='bx bx-up-arrow-alt'></i>
                        <i
                            v-else-if="!supplier.isNonRegistered && sPrice && sPrice.firstPrice > sPrice.price"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip custom-rfq-price-tooltip', html: true, title: getTooltipForPriceIcon(sPrice), placement:'rightbottom'}"
                            style="font-size: 16px; color: red; display:flex; align-items: center;"
                            class='bx bx-down-arrow-alt'></i>

                      <div v-if="!(isNewStatus || isPricedStatus) || (supplier.isNonRegistered) || (isRfqToOemGen && isUnLockedSupplierMain())"
                           class="part-list-part-name__icon-group">
                        <i v-if="sPrice.stock === 'InStock'"
                           class="font-20 bx bx-check-circle green-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('In Stock', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'ExStock'"
                           class="font-20 bx bx-check-circle orange-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('EX Warehouse', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'LowStock'"
                           class="font-20 bx bx-check-circle blue-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('Low Stock', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'NoStock'"
                           class="font-20 bx bx-minus-circle gray-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('No Stock', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'NoLongerAvailable'"
                           class="font-20 bx bx-check-circle gray-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('No Longer Available', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'NilSupply'"
                           class="font-20 bx bx-check-circle gray-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('Nil Supply', supplier, sPrice), placement:'leftbottom'}">
                        </i>
                        <i v-else-if="sPrice.stock === 'OnBackOrder'"
                           class="font-20 bx bx-check-circle red-icon"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockIconTooltipForOtherSupp('On Back Order', supplier, sPrice), placement:'leftbottom'}">
                        </i>

                      </div>

                    </div>
                  </template>
                  <template v-else>
                    <template v-if="supplier.isNonRegistered">
                         <span
                             v-if="!(supplier.pdfFile && supplier.pdfFile.id)"
                             class="blinking-nr-text"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Repairer is supplying price and proof of pricing from this supplier', placement:'leftbottom'}"
                         >Rpr to Supply</span>
                    </template>
                    <template v-else>To be Priced</template>
                  </template>
                </template>
                </div>
              </template>
            </div>
          </b-td>

          <!-- GST column -->
          <b-td class="part-list-gst text-right p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'td-selected': rfq.isPartSelected(part.originalPartId, part.id)}">
            <div v-if="isDeclineStatus" class="part-list-item-gst decline-color">Declnd</div>
            <div v-else-if="part.isLinkedWithNoCost || part.isDelivery" class="part-list-item-gst"></div>
            <div v-else class="part-list-item-gst">{{ part.gst | formatMoney }}</div>
          </b-td>

          <!-- Total column -->
          <b-td class="part-list-total text-right p-2 align-top" @contextmenu.prevent.stop="handlePartnameRightClick($event, part, key)"
                :class="{'td-selected': rfq.isPartSelected(part.originalPartId, part.id)}">
            <div v-if="isDeclineStatus" class="part-list-item-total decline-color">Declnd</div>
            <div v-else-if="part.isLinkedWithNoCost || part.isDelivery" class="part-list-item-total"></div>
            <div v-else class="part-list-item-total">{{ part.total | formatMoney }}</div>
          </b-td>
        </b-tr>
      </draggable>

      <b-tbody class="position-relative table-hover-none">
        <b-tr v-if="isNewStatus || priceResubmitFlag">
          <b-td class="subheader subheader-p-l supplier-info-cell" style="opacity:1; border-right: none" :colspan="2">
            <a :class="{'disabled-add-button': isDisabledFields}" href="#"
               @click.prevent="handleSaving(addNewPart('manual'))"
               class="add-part">
              <i class='bx bx-plus'></i>
              <span>Add Part</span>
            </a>
          </b-td>
          <b-td class="p-c-value text-left" style="position: relative; z-index: 1" v-for="(itm) in getColspanArray(isShowOePartNumberColumn ? 9 : 7)" :key="itm" colspan="1" :class="{'supplier-column-highlight-2': isActiveColumn2(itm + (isShowOePartNumberColumn ? 0 : 2)),}">
          </b-td>
        </b-tr>
        <b-tr v-if="isNewStatus || priceResubmitFlag ">
          <b-td class="subheader subheader-p-l supplier-info-cell" style="opacity:1; border-right: none" :colspan="2">
            <a :class="{'disabled-add-button': isDisabledFields}" href="#"
               @click.prevent="handleSaving(addNewPart('delivery'))"
               class="add-part">
              <i class='bx bx-plus'></i>
              <span>Add Freight</span>
            </a>
          </b-td>
          <b-td class="p-c-value text-left" style="position: relative; z-index: 1" v-for="(itm) in getColspanArray(isShowOePartNumberColumn ? 9 : 7)" :key="itm" colspan="1" :class="{'supplier-column-highlight-2': isActiveColumn2(itm + (isShowOePartNumberColumn ? 0 : 2)),}">
          </b-td>
        </b-tr>
        <!--        <b-tr v-if="priceResubmitFlag">-->
        <!--          <b-td colspan="5"></b-td>-->
        <!--          <b-td style="text-align: center">-->
        <!--            <button :disabled="isDisabledSave" @click="handleSaving(savePrices(true))" class="btn btn-primary">Save-->
        <!--            </button>-->
        <!--          </b-td>-->
        <!--          <b-td :colspan="getColspan(2)"></b-td>-->
        <!--        </b-tr>-->
        <b-tr>
          <b-td class="subheader subheader-p-l text-right supplier-info-cell" colspan="2">Parts in Stock Today</b-td>
          <b-td class="p-c-value text-left" style="position: relative; z-index: 1"
                v-for="(itm) in getColspanArray(isShowOePartNumberColumn ? 9 : 7)" :key="itm" colspan="1"
                :class="{'supplier-column-highlight-2': isActiveColumn2(itm + (isShowOePartNumberColumn ? 0 : 2)),}">
            <div style="padding-left: 5px;" v-if="itm == 1">
              <span style="margin-right: 30px;">{{ rfq.partsInStock }} %</span>
              <span>{{ rfq.partsInStockWithOutPercents }}</span>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="subheader subheader-p-l text-right supplier-info-cell" colspan="2">Quote Valid Till</b-td>
          <b-td style="position: relative; z-index: 1" class="p-c-value text-left center"
                v-for="(itm) in getColspanArray(isShowOePartNumberColumn ? 9 : 7)" :key="itm" colspan="1"
                :class="{'supplier-column-highlight-2': isActiveColumn2(itm + (isShowOePartNumberColumn ? 0 : 2)),}">
            <template v-if="itm == 1">
              <template v-if="isNewStatus || priceResubmitFlag">
                <div class="datetime-field-value time-input input-group date">
                  <b-input
                      placeholder="Quote Valid Till"
                      type="text"
                      @keypress="isNumber($event)"
                      :disabled="isDisabledFields"
                      @change="handleSaving(savePrices(false))"
                      v-model="rfq.quoteValidTill"></b-input>
                  <!--                              <i :class="{'disabled-picker-icon': isDisabledFields}" class='bx bx-calendar-week input-group-addon'></i>-->
                  <!--                                <date-picker  :isDisabled="isDisabledFields" v-model="rfq.partsDeliveryETA" @onChange="savePrices"></date-picker>-->
                </div>
              </template>
              <template
                  v-else-if="isPricedStatus && isCanEditPricedQuote && rfq.rfqUpdateRequested === 'UpdateRequested'">
                <span style="padding-left:5px;">Request Update from Repairer</span>
              </template>
              <template v-else>
                <span style="padding-left:5px;">{{ rfq.quoteDateValidTill | formatDate }}</span>
              </template>
            </template>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="subheader  subheader-p-l-b text-right" :colspan="getColspan2()">Subtotal
            <div class="line-border-bottom"></div>
          </b-td>
          <b-td v-if="isDeclineStatus" class="p-c-value b-value text-center font-weight-bold decline-color"
                :colspan="getColspan3()">
            Declnd
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else-if="isWonStatus || isOrderedStatus" class="p-c-value b-value text-center font-weight-bold"
                :colspan="getColspan3()">
            {{ rfq.order.subtotal | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else class="p-c-value b-value text-center font-weight-bold" :colspan="getColspan3()">
            {{ rfq.subtotal | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="subheader subheader-p-l-b text-right" :colspan="getColspan2()">GST
            <div class="line-border-bottom"></div>
          </b-td>
          <b-td v-if="isDeclineStatus" class="p-c-value b-value text-center font-weight-bold decline-color"
                :colspan="getColspan3()">
            Declnd
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else-if="isWonStatus || isOrderedStatus" class="p-c-value b-value text-center font-weight-bold"
                :colspan="getColspan3()">
            {{ rfq.order.gst | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else class="p-c-value b-value text-center font-weight-bold" :colspan="getColspan3()">
            {{ rfq.gst | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="subheader subheader-p-l-b text-right" :colspan="getColspan2()">Grand Total
            <div class="line-border-bottom"></div>
          </b-td>
          <b-td v-if="isDeclineStatus" class="p-c-value b-value text-center font-weight-bold decline-color"
                :colspan="getColspan3()">
            Declnd
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else-if="isWonStatus || isOrderedStatus" class="p-c-value b-value text-center font-weight-bold"
                :colspan="getColspan3()">
            {{ Number(rfq.order.subtotal) + Number(rfq.order.gst) | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
          <b-td v-else class="p-c-value b-value text-center font-weight-bold" :colspan="getColspan3()">
            {{ rfq.subtotal + rfq.gst | formatMoney }}
            <div class="line-border-bottom-value"></div>
          </b-td>
        </b-tr>
        <b-tr v-if="isNewStatus || (priceResubmitFlag)">
          <b-td class="subheader subheader-p-l-b text-right" :colspan="getColspan2()">
            <span v-if="rfq.properties && rfq.properties.isSentAsDirectOrder">Process Order</span>
            <span v-else>Submit Pricing</span>
            <div class="line-border-bottom" style="bottom: -1px"></div>
          </b-td>
          <b-td class="p-c-value b-value text-center" :colspan="getColspan3()">
            <div
                v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTitleForSubmitPricing(), placement:'leftbottom'}">
              <button v-if="isNewStatus || priceResubmitFlag" :disabled="isDisabledFields" class="btn btn-primary"
                      @click="submitPricing(false)">
                <span v-if="rfq.properties && rfq.properties.isSentAsDirectOrder">Process Order</span>
                <template v-else>
                  <span v-if="isNewStatus">Submit Pricing</span>
                  <span v-else>Update Pricing</span>
                </template>
              </button>
            </div>
            <div class="line-border-bottom-value line-border-bottom-value-btn"></div>
          </b-td>
        </b-tr>
        <b-tr v-if="isShowDeclineDirectOrderButton && !isOrderedStatus">
          <b-td class="subheader subheader-p-l-b text-right" :colspan="getColspan2()">
            <span>Decline Direct Order</span>
            <div class="line-border-bottom" style="bottom: -1px"></div>
          </b-td>
          <b-td class="p-c-value b-value text-center" :colspan="getColspan3()">
            <div
                v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTitleForSubmitPricing(), placement:'leftbottom'}">
              <button :disabled="isDisabledFields" class="btn btn-primary decline-direct-order-btn"
                      @click="$emit('declinedOrder')">
                <span>Decline Direct Order</span>
              </button>
            </div>
            <div class="line-border-bottom-value line-border-bottom-value-btn"></div>
          </b-td>
        </b-tr>
        <b-tr id="last-table-line">
          <b-td class="subheader subheader-p-l-b text-right" :colspan="getColspan2()">Customer Rewards
            <div class="line-border-bottom"></div>
          </b-td>
          <b-td class="p-c-value b-value text-center part-values" :colspan="getColspan3()">
            -
            <div class="line-border-bottom-value"></div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div
        class="topscroll"
        style="bottom: 0px; position: fixed; width: 600px; height: 5px; overflow-x: scroll; z-index: 5; background: white"
    >
      <div style="height: 1px" class="fake"></div>
    </div>

    <vue-context
        ref="menuPartName"
        class="context-menu-right-click"
        @open="handleContextOpen"
        @close="closeContextMenu"
        :closeOnClick="false"
        :subMenuOffset="100"
    >
      <template slot-scope="item">
        <!-- bind :menu prop for part-name-menu -->
        <part-name-menu
            v-if="item.data"
            @select="handlePartnameSelected"
            :currentPart="item.data"
            :isDirectOrder="rfq.properties.isSentAsDirectOrder"
            :parts="partsForContext"
            :isOEM="isRfqToOemGen"
            :isUSED="isRfqToUsed"
            :isRECO="isRfqToReco"
            :properties="rfq.properties"
            :isCanNonReturnable="rfq.properties.isNonReturnable"
            :isAFTM="isRfqToAftm"
            @photoUrlData="viewPhotoUrl">
        </part-name-menu>
      </template>

    </vue-context>
    <b-modal @hide="onCloseModal" ref="addPartPhotoModal" id="add-photo-modal" size="custom-add-part-photo"
             title="Add Photo">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-add-part-photo form-container">
        <form>
          <div class="pr-3 pl-3">
            <div class="pl-0 pb-3 font-weight-bold">Add Photo for</div>
            <div>
              <p>Part Name: {{ selectedPart.name }}</p>
              <p>Part Number: {{ selectedPart.number }}</p>
            </div>
            <vue-dropzone
                ref="imgUpDropPinInage"
                id="imgUploadDropPinImage"
                class="dropify-wrapper dropzoneContainer modal-dropzone-add-photo col-12"
                :useCustomSlot=true
                v-on:vdropzone-file-added="addFileUpload"
                v-on:vdropzone-success="successFileUpload"
                v-on:vdropzone-complete="completeFileUpload"
                v-on:vdropzone-sending="sendFileUpload"
                v-on:vdropzone-removed-file="removedFile"
                :options="photoConfig"
            >
              <div id="clickBrowseImagesBtn" class="dropzone-text">
                <i class='bx' :class="{'bx-camera': isMobile, 'bx-cloud-upload': !isMobile}"></i>
                <p class="drop-down-text p-3 mb-0">
                  <template v-if="isMobile">Take Photo</template>
                  <template v-else>
                    Drag and Drop or Browse Images
                  </template>
                </p>
              </div>
            </vue-dropzone>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Cancel</b-button>
          <b-button :disabled="isEmpty(photosForModal)" class="input-btn" @click="setImage">Confirm</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal ref="viewdPartPhotoModal" @hide="onCloseModal" id="view-photo-modal" size="custom-view-part-photo"
             title="View Photo">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-view-part-photo form-container">
        <form>
          <div class="pr-3 pl-3">
            <div class="pl-0 pb-3 font-weight-bold">View Photo for</div>
            <div>
              <p>Part Name: {{ isPartName }}</p>
              <p>Part Number: {{ isPartNumber }}</p>
            </div>
            <img :src='isPhotoUrl' alt='Part photo' class="w-100 mb-3">
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal ref="processDirectOrderCheck" id="view-photo-modal" size="process-direct-order-check-modal"
             title="Process Direct Order - Check">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-view-part-photo form-container">
        <form>
          <div class="pr-3 pl-3">
            <div class="pl-0 pb-3">We cannot Process this Order.</div>
            <div class="pl-0 pb-3">You have not applied Part nbrs, stock and pricing in the RFQ.</div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal size="direct-order-check" ref="direct-order-check"
             title="DIRECT ORDER - CHECK">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-auto-lock-block form-container">
        <div class="pr-3 pl-3">
          <p>Your Customer has asked for parts that are <span v-html="computedAllowedStockStatusesText"></span> only.</p>
        </div>
        <div class="pr-3 pl-3">
          <p>Some parts in the list are <span v-html="computedNotAllowedStatusesText"></span>. Please review stock availability has been set correctly.</p>
        </div>
        <div class="pr-3 pl-3">
          <p>Note: You can exclude the part/s from the order and process the rest, decline the Direct Order or contact the customer.</p>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template>
          <b-button
              style="width: 157px !important; border-color: #29BBC1 !important; color: #29BBC1 !important; background-color: white !important;"
              class="input-btn btn modal-auto-lock-block-btn" @click="cancel()">
            Cancel & Review
          </b-button>
          <b-button @click="submitPricing(true); cancel()"
                    style="width: 150px !important; background-color: #29BBC1 !important; border-color: #29BBC1 !important; color: white !important;"
                    class="input-btn btn modal-auto-lock-block-btn">
            Process Order
          </b-button>
        </template>
      </template>
    </b-modal>

    <div>
      <div v-if="isShowLoader" style="z-index: 999" :style="{left: leftForLoader + 'px', top: topForLoader + 'px'}"
           id="context-loader">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             style="margin:auto;display:block; border-radius: 50%;" width="200px" height="200px" viewBox="0 0 100 100"
             preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" r="0" fill="none" stroke="#29bbc1" stroke-width="17">
            <animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;58" keyTimes="0;1"
                     keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
            <animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1"
                     keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
          </circle>
          <circle cx="50" cy="50" r="0" fill="none" stroke="#131923" stroke-width="17">
            <animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;58" keyTimes="0;1"
                     keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.625s"></animate>
            <animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1"
                     keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.625s"></animate>
          </circle>
        </svg>
      </div>
      <ul v-if="isShowContext"
          class=""
          :style="{left: leftForTouchMenu + 'px', top: topForTouchMenu + 'px', position: 'absolute'}"
          id="context-menu-iphone">
        <li v-if="selectedPart && selectedPart.photoForPart && selectedPart.photoForPart.id"
            class="areaTouch context-menu-iphone-btn">
          <button @click="removePhotoFromMobileContext" class="button-add-photo">Remove Photo</button>
        </li>
        <li v-else class="areaTouch context-menu-iphone-btn">
          <button @click="addPhotoFromMobileContext" class="button-add-photo">Add Photo</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import { mapGetters } from 'vuex'
import Axios from 'axios'
import { States } from '../../../../store/states'
import Numeral from '../../../../components/utility/number-formatter'
import RebateNumeral from '../../../../components/utility/rebate-number-formatter'
import StockSelect from '../../../../components/StockSelect'
import { VueContext } from 'vue-context'
import partNameMenu from './part-name-menu'
import draggable from 'vuedraggable'
import _ from 'lodash'
import Part from '@/components/rfq/models/Part'
import DatePicker from '@/components/datePicker'
import Multiselect from 'vue-multiselect'
import { isMobile } from 'mobile-device-detect'
import vueDropzone from 'vue2-dropzone'
import FileUpload from '../rfq-view/file-upload.vue'
import { uuid } from 'vue-uuid'
import accounting from 'accounting'
import * as dayjs from 'dayjs'
import brandList from "@/store/modules/brandList/brandList";

export default {
  name: 'SupplierPartList',
  data: function () {
    return {
      selectedPart: {},
      highlight: [
        'whatever',
        '$',
        'dollar',
        'dollars',
        '00.00',
        '0.00',
        'price match',
        'price beat',
        'percentage',
        'discount',
        '%',
        'competition',
        'reduction',
        'pricing',
        'rebate',
      ],
      isShowLoader: false,
      isShowContext: false,
      isTouchedEnd: false,
      topForLoader: 0,
      topForTouchMenu: 0,
      leftForLoader: 0,
      selectedImage: null,
      leftForTouchMenu: 0,
      touchStartTime: 0,
      touchMoved: false,
      photosForModal: [],
      isNeedRemoveImg: false,
      isDraggabling: false,
      selectedPartIndex: null,
      isDisabledSave: true,
      stockOptions: States.stockOptions,
      stockForAll: { 'label': 'Set All', 'textValue': 'selectAll', 'value': -1, 'comment': '' },
      headerStockOptions: ['None', 'In Stock'],
      isLoading: false,
      showAltPartNameId: 0,
      enableOptionalCheck: false,
      lastManualPartId: null,
      activeSupplierId: null,
      clickedSupplierId: null,
      activeColumnIndex: null,
      parts: [],
      leftPositions: {},
      columnWidths: {},
      isClickedOnSendPrices: false,
      originalParts: [],
      isDraggableDisabled: false,
      focusedElement: '',
      selectedPartId: null,
      photoConfig: {
        clickable: '#clickBrowseImagesBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        capture: 'camera',
        url: appConfig.baseAPIURL + '/ir/supplier/rfq/add-photo-for-part',
        //      url: appConfig.baseAPIURL + '/ir/user/photo/upload',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        params: {
          rfqId: this.rfq.id,
        },
        maxFilesize: 50,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      isStartImporting: false,
      isPhotoUrl: '',
      isPartName: '',
      isPartNumber: '',
      isShowNillSupply: true,
    }
  },
  props: {
    rfq: {
      type: Object,
      required: true
    },
    priceResubmitFlag: {
      type: Boolean,
      default: false,
    },
    isAdditionalRfq: {
      type: Boolean,
      default: false,
    },
    isPartListTab: {
      type: Boolean,
      default: false,
    },
    isCanEditPricedQuote: {
      type: Boolean,
      default: false,
    },
    isLockedRfq: {
      type: Boolean,
      default: false,
    },
    isRFQNew: {
      type: Boolean,
      default: false,
    },
    importedParts: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
      isMultipleLocations: 'settings/isMultipleLocations',
      getterAllUsers: 'users/getAllUsersForAssignedTo',
      currentUser: 'currentUser/getCurrentUserInfo',
    }),
    isExistRequestBestPrice(){
       return ((this.rfq?.secondRequestBestPrice === true || this.rfq?.secondRequestBestPrice === false) || (this.rfq?.thirdRequestBestPrice === true || this.rfq?.thirdRequestBestPrice === false))
    },
    brandList() {
      return brandList
    },
    isShowOePartNumberColumn () {
      return (this.isRfqToAftm || this.isRfqToOemPar || this.isRfqToOemAlt || this.isRfqToCertAftm)
    },
    computedAllowedStockForDirectOrder () {
      let r = []
      if (_.isEmpty(this.rfq?.directOrder?.comment)) {
        return r
      }
      if (_.includes(this.rfq?.directOrder?.comment, 'In Stock') && !_.includes(r, 1)) {
        r.push(1)
      }
      if (_.includes(this.rfq?.directOrder?.comment, 'EX Warehouse') && !_.includes(r, 6)) {
        r.push(6)
      }
      if (_.includes(this.rfq?.directOrder?.comment, 'Back Order') && !_.includes(r, 4)) {
        r.push(4)
      }

      return r
    },
    computedAllowedStockStatusesText() {
      let r = '';
      if (_.includes(this.computedAllowedStockForDirectOrder, 1)) {
        r += '<b style="color: rgb(60, 199, 169)">In Stock</b>'
      }
      if (_.includes(this.computedAllowedStockForDirectOrder, 6)) {
        if (_.includes(this.computedAllowedStockForDirectOrder, 1)) {
          r += ' and/or '
        }
        r += '<b style="color: orange">Ex Warehouse</b>'
      }
      if (_.includes(this.computedAllowedStockForDirectOrder, 4)) {
        if (_.includes(this.computedAllowedStockForDirectOrder, 1 || _.includes(this.computedAllowedStockForDirectOrder, 6))) {
          r += ' and/or '
        }
        r += '<b style="color: red">Back Order</b>'
      }
      return r
    },
    computedNotAllowedStatusesText() {
      let s = [];
      _.forEach(this.parts, (prt) => {
        if (!prt.isExcludeFromDirectOrder && (!_.isEmpty(this.computedAllowedStockForDirectOrder) && !_.includes(this.computedAllowedStockForDirectOrder, (Number(prt.stock)))) && !_.includes(s, prt.stock)) {
          s.push(prt.stock)
        }
      })
      s = _.orderBy(s)
      let r = '';
      _.forEach(s, (itm, ind) => {
        let v = _.find(this.stockOptions, (opt) => {
          return Number(opt.value) === Number(itm)
        })
        if (v) {
          r += `<b>${v.label}</b>`
          if (ind + 1 !== s.length) {
            r += ', '
          }
        }
      })
      return r
    },
    isHasNillSupply () {
      return _.some(this.parts, (prt) => {
        return prt.stock == 7
      })
    },
    isRfqToOemGen () {
      return this.rfq.partsType === 'OEM-GEN'
    },
    isRfqToOemPar () {
      return this.rfq.partsType === 'OEM-PAR'
    },
    isRfqToOemAlt () {
      return this.rfq.partsType === 'OEM-ALT'
    },
    isRfqToUsed () {
      return this.rfq.partsType === 'USED'
    },
    isRfqToAftm () {
      return this.rfq.partsType === 'AFTM'
    },
    isRfqToCertAftm () {
      return this.rfq.partsType === 'CERT-AFTM'
    },
    isRfqToReco () {
      return this.rfq.partsType === 'RECO'
    },
    isShowDeclineDirectOrderButton () {
      return this.rfq.status !== 'Closed' && this.rfq.status !== 'Decline' && this.rfq?.properties?.isSentAsDirectOrder
    },
    isDisabledFields: function () {
      return (this.rfq.dueCountdown == '-1' && this.isNewStatus && !this.isRfqToOemGen) || this.isLockedRfq
    },
    partsForContext () {
      let parts = _.filter(this.parts, (prt) => {
        return Number(prt.id) !== Number(this.selectedPartId)
      })
      _.forEach(parts, (prt) => {
        prt.isHasLinked = false
        let linkedPrt = _.find(parts, (p) => {
          return Number(prt.id) === Number(p.linkId)
        })
        if (linkedPrt) {
          prt.isHasLinked = true
        }
      })
      return parts
    },
    computedAvailableRefs () {
      let result = []
      _.forEach(this.parts, (part) => {
        let cells = ['manual-name-' + part.id]
        if (this.isShowOePartNumberColumn) {
          cells.push('oe-part-number-' + part.id)
        }
        cells = _.concat(cells, ['part-number-' + part.id, 'stock-select-' + part.id, 'qty-' + part.id, 'rrp-' + part.id, 'price-' + part.id])
        if (part.isDelivery) {
          cells[0] = 'deliveryName-' + part.id
        }
        if (this.rfq.isRebateEnabled) {
          cells.push('rebate-' + part.id)
        }
        result.push(cells)
      })
      return result
    },
    isPricedStatus () {
      return this.rfq.status === 'Priced'
    },
    isNewStatus () {
      return this.rfq.status === 'New'
    },
    isWonStatus () {
      return this.rfq.status === 'Won'
    },
    isOrderedStatus () {
      return this.rfq.status === 'Ordered'
    },
    isDeclineStatus () {
      return this.rfq.status === 'Decline'
    },
    isMobile () {
      return isMobile
    },
  },
  watch: {
    'rfq.parts': {
      handler (val) {
        if (this.isDraggabling) {
          console.log('rfq.parts.update - cancel')
          return
        }
        this.originalParts = val
        _.forEach(val, (part) => {
          console.log('watch.load.part', part)
          let originalPart = _.find(this.parts, (prt) => {
            return Number(prt.id) === Number(part.id)
          })
          if (originalPart) {
            // console.log('rfq.parts.listOrderNumber', part.id, part.listOrderNumber, originalPart.listOrderNumber)
          } else {
            // console.log('rfq.parts.listOrderNumber.NoOriginalPart', part.id)
          }
          if (this.isInFocusElement('qty-' + part.id)) {
            part.qty = originalPart.qty
          }
          if (this.isInFocusElement('partName-' + part.id)) {
            part.name = originalPart.name
          }
          if (this.isInFocusElement('deliveryName-' + part.id)) {
            part.deliveryName = originalPart.deliveryName
          }
          if (this.isInFocusElement('altPartName-' + part.id)) {
            part.altPartName = originalPart.altPartName
          }
          if (this.isInFocusElement('name-' + part.id)) {
            if (part.isDelivery) {
              part.deliveryZone = originalPart.deliveryZone
            } else {
              part.number = originalPart.number
            }
          }
          if (this.isInFocusElement('stock-select-' + part.id)) {
            part.selectedOption = originalPart.selectedOption
          }
          if (this.isInFocusElement('rrp-' + part.id)) {
            part.rrp = originalPart.rrp
          }
          if (this.isInFocusElement('oe-number-' + part.id)) {
            part.oePartNumber = originalPart.oePartNumber
          }
          if (this.isInFocusElement('price-' + part.id)) {
            part.price = originalPart.price
            // part.price2 = originalPart.price2
          }

        })
        this.parts = val

        let isHasPartWithoutListOrderNumber = _.some(this.parts, (prt) => {
          return Number(prt.listOrderNumber2) === 999
        })
        if (isHasPartWithoutListOrderNumber && this.isRFQNew) {
          this.setListOrderNumbers()
        }
      },
      immediate: true
    },
    importedParts: {
      handler (val) {
        console.log(val, 'value')
        if (!_.isEmpty(val)) {
          this.addImportedParts(val)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getStockIconTooltipForOtherSupp(v, s, prt) {
      if (!s.isNonRegistered) {
        return v
      }
      let r = v
      if (prt.stockComment && _.trim(prt.stockComment)) {
        r += '<br/>' + prt.stockComment
      }
      if (_.trim(prt.number)) {
        r += '<br/>Part Number:' + prt.number
      }
      if (_.trim(prt.oePartNumber)) {
        r += '<br/>OE Part Number:' + prt.oePartNumber
      }
      return r
    },
    isLockedSupplier(supplier) {
      return supplier.isNonRegistered || (supplier.thirdRequestBestPrice === false || (supplier.secondRequestBestPrice === false && supplier.thirdRequestBestPrice !== false && supplier.thirdRequestBestPrice !== true))
    },
    isUnLockedSupplier(supplier) {
      return !supplier.isNonRegistered && (supplier.thirdRequestBestPrice === true || supplier.secondRequestBestPrice === true)
    },
    isLockedSupplierMain() {
      return  (this.rfq?.thirdRequestBestPrice === false || (this.rfq?.secondRequestBestPrice === false && this.rfq?.thirdRequestBestPrice !== false && this.rfq?.thirdRequestBestPrice !== true))
    },
    isUnLockedSupplierMain() {
     return (this.rfq?.thirdRequestBestPrice === true || this.rfq?.secondRequestBestPrice === true)
    },
    excludeFromDirectOrder (part) {
      part.setExcludeFromDirectOrder = true
      part.isExcludeFromDirectOrder = true
      part.price = 0
      part.stock = 3
      part.rrp = 0
      // part.qty = 0
      if (_.isNumber(part.rebate) || _.isString(part.rebate)) {
        part.rebate = 0
      }
      part.stockComment = ''
      this.isDisabledSave = false
      this.savePart(part)
    },
    includeToDirectOrder (part) {
      part.setIncludeToDirectOrder = true
      part.isExcludeFromDirectOrder = false
      part.setExcludeFromDirectOrder = false
      this.isDisabledSave = false
      this.savePart(part)
    },
    getCustomClass (part) {
      if (this.rfq?.order?.id) {
        let orderPart = _.find(this.rfq.order.parts, (p) => {
          return Number(p.originalPartId) == Number(part.originalPartId)
        })
        if (orderPart) {
          return 'ps-tooltip order-non-returnable-tooltip'
        }
      }
      return 'ps-tooltip non-returnable-tooltip'
    },
    getNRTooltip (part) {
      if (this.rfq?.order?.id) {
        let orderPart = _.find(this.rfq.order.parts, (p) => {
          return Number(p.originalPartId) == Number(part.originalPartId)
        })
        if (orderPart) {
          let r = '<b>Non-Returnable part Accepted</b>'
          if (orderPart.nonReturnableAcceptedBy) {
            r += '<br/> by ' + orderPart.nonReturnableAcceptedBy
          }
          if (orderPart.nonReturnableAcceptedOn) {
            r += '<br/> ' + this.formatDateTime(orderPart.nonReturnableAcceptedOn)
          }
          return r
        }
      }
      return '<b>Marked Non-Returnable Part</b> <br/>' + this.formatDateTime(part.nonReturnableAt) + '<br/>' + this.getUserNameById(part.nonReturnableBy)
    },
    formatDateTime (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY || hh:mmA') : ''
    },
    getUserNameById (userId) {
      let user = _.find(this.getterAllUsers, (u) => {
        return Number(u.id) === Number(userId)
      })
      if (user) {
        return user.name
      }
    },
    isShowGrayTriangle (item) {
      if (_.isEmpty(item.oemPartNumber) || _.isEmpty(item.oePartNumber)) {
        return false
      }
      return _.trim(item.oemPartNumber) !== _.trim(item.oePartNumber)
    },
    isShowGreenCheck (item) {
      if (_.isEmpty(item.oemPartNumber) || _.isEmpty(item.oePartNumber)) {
        return false
      }
      return _.trim(item.oemPartNumber) === _.trim(item.oePartNumber)
    },
    changedClickedSupplierId (v) {
      this.clickedSupplierId = v
    },
    changedActiveColumnIndex (v) {
      this.activeColumnIndex = v
    },
    changedActiveSupplierId (v) {
      this.activeSupplierId = v
    },
    getColumnIndex (ind) {
      ind += 7
      if (this.isRfqToOemGen) {
        ind += 1
      }
      if (this.rfq.isRebateEnabled) {
        ind += 1
      }
      return ind
    },
    setActiveSupplier (id, index) {
      this.activeSupplierId = id
      this.activeColumnIndex = index
      this.$emit('changedActiveSupplierId', this.activeSupplierId)
      this.$emit('changedActiveColumnIndex', this.activeColumnIndex)
    },
    setClickedSupplier (id) {
      if (this.clickedSupplierId == id) {
        this.clickedSupplierId = null
      } else {
        this.clickedSupplierId = id
      }
      this.$emit('changedClickedSupplierId', this.clickedSupplierId)
    },
    isColumnClicked (id) {
      return id === this.clickedSupplierId
    },
    isActiveColumn (id) {
      return id === this.activeSupplierId
    },
    isActiveColumn2 (id) {
      return id === this.activeColumnIndex
    },
    keyDownEvent (e) {
      if (!this.isPartListTab) {
        return
      }
      let el = document.getElementsByClassName('b-table-sticky-header')[0]
      if (!el) {
        return
      }
      if (e.code == 'ArrowRight') {
        el.scrollLeft += 15
      } else if (e.code == 'ArrowLeft') {
        el.scrollLeft -= 15
      }
    },
    getColspanArray (number = 7, isNeedIncrease = false) {
      if (isNeedIncrease) {
        if (this.isRfqToOemGen) {
          number++
        }
      } else {
        if (!this.isRfqToOemGen) number--
      }
      if (this.rfq.isRebateEnabled) {
        number++
      }
      number += this.getSuppliersSize()
      let result = []
      for (let i = 1; i <= number; i++) {
        result.push(i)
      }
      return result
    },
    onTableScroll () {
      this.$nextTick(() => {
        let el = document.getElementsByClassName('b-table-sticky-header')[0]
        let el2 = document.getElementById('supplier-rfq-view-thead-wrapper')

        if (el2 && el) {
          el2.scrollLeft = el.scrollLeft
        }
      })
    },
    getWidthForElement (id) {
      if (this.columnWidths[id]) {
        return this.columnWidths[id]
      }
      return 0
    },
    getWidth (id) {
      let el = document.getElementById(id)
      return (el.clientWidth - 5) + 'px'
    },
    setFakeScroll () {
      let container = $('.b-table-sticky-header')
      let topscroll = $('.topscroll')

      $('.fake').width($('#supplier-rfq-view-thead').width())
      $('.topscroll').width($('.b-table-sticky-header').width())

      if (document.getElementById('last-table-line').getBoundingClientRect().bottom + 13 <= window.innerHeight) {
        $('.topscroll').hide()
      } else {
        $('.topscroll').show()
      }

      topscroll.scroll(function(e){
        container.scrollLeft($(this).scrollLeft());
        let el = document.getElementsByClassName("b-table-sticky-header")[0];
        let stockAll = document.getElementById("rfq-stock-all-wrapper");
        if (stockAll) {
          stockAll.style.transform = `translateX(-${el.scrollLeft}px)`
        }
      })
      container.scroll(function (e) {
        topscroll.scrollLeft($(this).scrollLeft())
      })
    },
    setLeftPosition () {
      this.setFakeScroll()
      let lineWidth = document.getElementById('supplier-rfq-view-line-column').clientWidth
      // let partListWidth = document.getElementById('supplier-rfq-view-part-list-column').clientWidth;
      // let partNumberWidth = document.getElementById('supplier-rfq-view-part-number-column').clientWidth;
      // let qtyWidth = document.getElementById('supplier-rfq-view-qty-column').clientWidth;
      // let rrpWidth = document.getElementById('supplier-rfq-view-rrp-column').clientWidth;

      this.$nextTick(() => {
        this.$set(this.leftPositions, 'supplier-rfq-view-line-column', '0px')
        this.$set(this.leftPositions, 'supplier-rfq-view-part-list-column', lineWidth + 'px')
        // this.$set(this.leftPositions, 'supplier-rfq-view-part-number-column', lineWidth + partListWidth + 'px')
        // this.$set(this.leftPositions, 'supplier-rfq-view-qty-column', lineWidth + partListWidth + partNumberWidth + 'px')
        // this.$set(this.leftPositions, 'supplier-rfq-view-rrp-column', lineWidth + partListWidth + partNumberWidth + qtyWidth + 'px')
        this.$set(this.leftPositions, 'info-line', '0px')
      })

      let ids = ['supplier-rfq-view-line-column', 'supplier-rfq-view-part-list-column',
        'supplier-rfq-view-stock-column', 'supplier-rfq-view-part-number-column', 'supplier-rfq-view-qty-column', 'supplier-rfq-view-supplier',
        'supplier-rfq-view-gst', 'supplier-rfq-view-total']
      _.forEach(this.filterSuppliers(), (s) => {
        ids.push('supplier-rfq-view-supplier-' + s.id)
      })
      if (this.rfq.isRebateEnabled) {
        ids.push('supplier-rfq-view-rebate')
      }
      if (this.isRfqToOemGen) {
        ids.push('supplier-rfq-view-rrp-column')
      }

      _.forEach(ids, (id) => {
        this.$nextTick(() => {
          this.$set(this.columnWidths, id, this.getWidth(id))
        })
      })

    },
    getLeftPosition (type) {
      if (this.leftPositions[type]) {
        return this.leftPositions[type]
      }
      return '0px'
    },
    getSupplierColor (supplier) {
      switch (supplier.type) {
        case 'OEM-GEN':
          return '#083d77'
        case 'OEM-PAR':
          return '#ee964b'
        case 'OEM-ALT':
          return '#f4d35e'
        case 'CERT-AFTM':
          return '#fa7a61'
        case 'AFTM':
          return '#6b8f71'
        case 'USED':
          return '#b287a3'
        case 'RECO':
          return '#57b8ff'

        default:
          return '#a3a3a3'
      }
    },
    setListOrderNumbers () {
      let data = { sorts: [] }
      this.parts.forEach((prt, ind) => {
        prt.listOrderNumber = ind
        data.sorts.push({
          id: prt.id,
          listOrderNumber: prt.listOrderNumber
        })
      })
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
          .then(() => {})
          .catch(error => {
            console.log(error)
          })
    },
    handleInput (e) {
      const el = e.target
      const sel = el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel)
      })
    },
    onPhotoIconTouch (id) {
      this.$refs?.['icon-photo-' + id]?.[0]?.click()
    },
    removePhotoFromMobileContext () {
      let part = _.find(this.parts, (prt) => {
        return Number(prt.id) === Number(this.selectedPart.id)
      })
      if (part) {
        part.photoId = null
        part.photoForPart = null
        this.savePart(part)
      }
      this.isShowContext = false
    },
    submitPricing (isIgnoreCheckOnStock = false) {
      if (this.rfq.properties.isSentAsDirectOrder) {
        this.isClickedOnSendPrices = true
        let isHasEmptyValues = _.some(this.parts, (prt) => {
          return !prt.isExcludeFromDirectOrder && ((_.isEmpty(prt.partNumber) && !prt.isDelivery) || (_.isEmpty(prt.deliveryZone) && prt.isDelivery) || prt.stock == 0 || (this.isRfqToOemGen && Number(prt.rrp) == 0) || Number(prt.price) == 0)
        })
        if (isHasEmptyValues) {
          this.$refs.processDirectOrderCheck.show()
          return
        }

        let isHasWrongStocks = _.some(this.parts, (prt) => {
          return !prt.isExcludeFromDirectOrder && (!_.isEmpty(this.computedAllowedStockForDirectOrder) && !_.includes(this.computedAllowedStockForDirectOrder, (Number(prt.stock))))
        })
        if (isHasWrongStocks && !isIgnoreCheckOnStock) {
          this.$refs['direct-order-check'].show()
          return
        }
      }
      this.handleSaving(this.sendPrices())
    },
    clickOnWaitingText (part) {
      this.$set(part, 'isClickedOnWaitingText', true)
      this.$nextTick(() => {
        this.focusElement('price-' + part.id)
      })
    },
    isShowCalculatedUSEDPrice (prt) {
      if (prt.isManual && !prt.isManualByOemSupplier) {
        return false
      }
      return this.isRfqToUsed && this.rfq.properties.isExistOemSupplier && this.rfq.properties.isEnableAutocalculationPrice
    },
    // onChangePriceForUsedSupp (part) {
    //   return;
    //   console.log('onChangePriceForUsedSupp', _.cloneDeep(part.price), _.cloneDeep(part.price2))
    //   part.price2 = part.price
    //   this.onChange(part)
    // },
    getTooltipForUsedSupp (prt) {
      let buy = accounting.formatMoney(prt.price)
      let percent = null
      if (Number(prt.oemListPrice > 0)) {
        percent = accounting.toFixed(((prt.price / prt.oemListPrice) * 100), 0)
      } else {
        percent = null
      }
      let r = `<b>List</b> = ${accounting.formatMoney(prt.oemListPrice)} <br/>
                <b>Buy</b> = ${buy} <br/>`
      if (percent && percent > 0) {
        r += `(${percent}% of List)`
      }
      return r
    },
    // getPriceForUSEDSupp (prt) {
    //   if (!_.isNumber(prt.price2) && !_.isString(prt.price2)) {
    //     return accounting.formatMoney(prt.oemListPrice * ((100 - parseFloat(this.rfq.properties.defaulPercentForAutocalculationPrice)) / 100))
    //   } else {
    //     return accounting.formatMoney(prt.price)
    //   }
    // },
    setParts (v) {
      this.parts = v
    },
    getDataForPreSave (part = null, isFromImportPdf = false) {
      let parts = []
      if (_.isEmpty(part)) {
        parts = _.map(this.parts, (prt, index) => {
          let data = {
            price: prt.price,
            partNumber: prt.number,
            rrp: prt.rrp,
            name: prt.name,
            stock: String(prt.selectedOption.value),
            altPartName: prt.altPartName,
            stockComment: !_.isEmpty(prt.stockComment) ? String(prt.stockComment) : '',
            linkId: prt.linkId,
            linkType: prt.linkType,
            qty: prt.qty,
            listOrderNumber: prt.listOrderNumber || prt.listOrderNumber === 0 ? prt.listOrderNumber : index,
            condition: prt.condition,
            photoId: prt.photoId,
          }
          if (isFromImportPdf) {
            if (prt.isDelivery) {
              data.isDelivery = true
            } else if (prt.isManual) {
              data.isManual = true
            }
          }

          if (prt.isNotApplicable && prt.isNotApplicable === true) {
            data.isNotApplicable = true
            data.beforeMarkNotApplicable = prt.beforeMarkNotApplicable
          } else {
            data.isNotApplicable = false
          }
          if (prt.isDelivery) {
            data.deliveryName = prt.deliveryName
            data.deliveryZone = prt.deliveryZone
          }
          if (isFromImportPdf) {
            data.id = prt.id ? prt.id : null
          } else if (prt.id) {
            data.id = prt.id
          }
          return data
        })
      } else {
        let index = this.parts.indexOf(part)
        let newPrt = {
          price: part.price,
          partNumber: part.number,
          rrp: part.rrp,
          name: part.name,
          stock: part.selectedOption && part.selectedOption.value !== null ? String(part.selectedOption.value) : null,
          altPartName: part.altPartName,
          stockComment: !_.isEmpty(part.stockComment) ? String(part.stockComment) : '',
          linkId: part.linkId,
          linkType: part.linkType,
          qty: part.qty,
          listOrderNumber: part.listOrderNumber || part.listOrderNumber === 0 ? part.listOrderNumber : index,
          type: part.type,
          condition: part.condition,
          photoId: part.photoId,
        }

        if (isFromImportPdf) {
          if (part.isDelivery) {
            newPrt.isDelivery = true
          } else if (part.isManual) {
            newPrt.isManual = true
          }
        }

        if (part.isNotApplicable && part.isNotApplicable === true) {
          newPrt.isNotApplicable = true
          newPrt.beforeMarkNotApplicable = part.beforeMarkNotApplicable
        } else {
          newPrt.isNotApplicable = false
        }
        if (part.isDelivery) {
          newPrt.deliveryName = part.deliveryName
          newPrt.deliveryZone = part.deliveryZone
        }

        if (isFromImportPdf) {
          newPrt.id = part.id ? part.id : null
        } else if (part.id) {
          newPrt.id = part.id
        }

        parts.push(newPrt)
      }
      return parts
      // return {
      // rfqId: this.rfq.id,
      // discountApplied: this.rfq.discountsApplied,
      // partsDeliveryETA: this.rfq.partsDeliveryETA,
      // quoteValidity: this.rfq.quoteValidTill,
      // parts: parts,
      // }
    },
    preSaveData (part) {
      let data = this.getDataForPreSave(part)
      if (this.isPricedStatus && this.priceResubmitFlag) {
        let isHasEmptyName = _.some(data.parts, (p) => {
          let bool = false
          let originalPart = _.find(this.parts, (prt) => {
            return Number(prt.id) === Number(p.id)
          })
          if (originalPart) {
            if (originalPart.type === 'delivery') {
              bool = _.isEmpty(p.deliveryName)
            } else if (originalPart.type === 'manual') {
              bool = _.isEmpty(p.name)
            }
          }
          return bool
        })
        if (isHasEmptyName) {
          this.$toast.error('Manual and Delivery part name can not be empty')
          return
        }
      }

      NProgress.start()

      Axios.post('/ir/supplier/rfq/' + this.rfq.id + '/pre-save-data-for-part', data)
          .then(response => {
            if (response.data.status) {

            }
          })
          .catch(error => {
            reject(error)
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    getRebate (prt) {
      if (!_.isNumber(prt.rebate) && !_.isString(prt.rebate)) {
        return accounting.formatMoney(prt.price * ((100 - parseFloat(this.rfq.rebatePercent)) / 100))
      } else {
        return accounting.formatMoney(prt.rebate)
      }
    },
    _isEmpty (v) {
      return _.isEmpty(v)
    },
    _isNumber (v) {
      return _.isNumber(v)
    },
    _isString (v) {
      return _.isString(v)
    },
    isShowErrorMessage (prt) {
      let b = false
      b = _.some(this.highlight, (w) => {
        return _.toLower(prt?.altPartName)?.includes(w)
      })
      return b
    },
    getErrorMessage (prt) {
      let words = []
      let text = ''

      _.forEach(this.highlight, (w) => {
        if (_.toLower(prt?.altPartName)?.includes(w)) {
          words.push(w)
        }
      })

      if (words.length === 1) {
        text += 'Word'
      } else if (words.length > 1) {
        text += 'Words'
      }

      _.forEach(words, (w, ind) => {
        text += ` "${w}"`
        if (words[ind + 1]) {
          text += ' and'
        }
      })

      return text + ' cannot be used in the comment'
    },
    getTooltipForPriceIcon (part) {
      let r = ''
      r += `<div>
        <b style="display: block; height: 10px">Changes</b> <br/>
      <div style="display: flex; flex-direction: column-reverse;">`

      _.forIn(part.priceHistory, (p) => {
        if (parseFloat(p) > parseFloat(part.firstPrice)) {
          r += `<span style="color: #0cb458">$${p}</span> `
        } else if (parseFloat(p) < parseFloat(part.firstPrice)) {
          r += `<span style="color: red">$${p}</span> `
        } else {
          r += `<span>$${p}</span>`
        }
      })

      r += '</div></div>'
      return r
    },
    getTitleForSubmitPricing () {
      if (this.rfq?.lock?.active) {
        return 'This quote is locked'
      }
      if (this.isDisabledFields) {
        return 'The due date & time for this quote has been expired'
      }
      return ''
    },
    isHasComment (part) {
      return _.trim(part?.stockComment) || _.trim(part?.condition)
    },
    isHasShortComment (part) {
      return (part.stock > 0 && part.stock < 7) && _.trim(part?.stockComment)
    },
    getShortComment (part) {
      let r = ''
      let stockComment = _.trim(part?.stockComment)

      if (stockComment) {
        r += stockComment.substr(0, 15)
        if (stockComment.length > 15) {
          r += '...'
        }
      }
      return r
    },
    addPhotoFromMobileContext () {
      this.addPhoto()
      this.isShowContext = false
    },
    touchStart (event, item) {
      if (!this.isRfqToUsed) {
        return
      }
      this.isTouchedEnd = false
      this.isShowContext = false
      this.isShowLoader = false
      setTimeout(() => {
        event.preventDefault()
        console.log(this.isTouchedEnd, 'this.istouched')
        if (!this.isTouchedEnd && !this.touchMoved) {
          document.body.style.WebkitUserSelect = 'none'

          try {
            if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
              this.isShowContext = false
            }
          } catch (err) {
            this.isShowContext = false
          }

          this.touchStartTime = new Date()
          this.topForLoader = event.touches[0].pageY
          // mobile menu height offset
          // if (event.touches[0].clientY + 50 > document.body.offsetHeight) {
          //   this.topForTouchMenu = event.touches[0].pageY - 160
          // } else if (event.touches[0].clientY + 100 > document.body.offsetHeight) {
          //   this.topForTouchMenu = event.touches[0].pageY - 120
          // } else if (event.touches[0].clientY + 150 > document.body.offsetHeight) {
          //   this.topForTouchMenu = event.touches[0].pageY - 70
          // } else {
          this.topForTouchMenu = event.touches[0].pageY
          // }
          this.leftForLoader = event.touches[0].pageX

          if (event.touches[0].pageX + 150 > window.screen.width) {

            this.leftForTouchMenu = window.screen.width - 150
          } else {
            this.leftForTouchMenu = event.touches[0].pageX
          }

          this.selectedPart = item
          this.isShowLoader = true
        }
      }, 300)
    },
    touchEnd (event) {
      this.touchMoved = false
      if (!this.isRfqToUsed) {
        return
      }
      event.preventDefault()
      console.log('end', event)
      this.isTouchedEnd = true
      let touchEndTime = new Date()
      let touchDelay = (touchEndTime - this.touchStartTime) / 700
      if (this.isShowLoader && touchDelay >= 0.7) {
        this.isShowLoader = false
        this.isShowContext = true
        console.log('this.isShowContext', this.isShowContext)
      }
      this.isShowLoader = false
      document.body.style.WebkitUserSelect = ''
    },
    touchClose (event) {
      if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
        this.isShowContext = false
      }
    },
    handleTouchMove () {
      console.log('Touch Move')
      this.touchMoved = true
    },
    isPartInFocus (part) {
      if (this.focusedElement && this.focusedElement.includes('-')) {
        let id = this.focusedElement.split('-')[1]
        if (this.focusedElement.includes('stock-select')) {
          id = this.focusedElement.split('-')[2]
        }
        return Number(part.id) === Number(id)
      }
      return false
    },
    tooltipContent (part) {
      if (part?.photoForPart?.src) {
        return `<img style='max-width:190px; max-height:190px; width:100%;' src='${part.photoForPart.src}' alt='Part photo'>`
      }
      return ''
    },
    viewPhotoUrl (photoUrl, isPartName, isPartNumber) {
      this.isPhotoUrl = photoUrl
      this.isPartName = isPartName
      this.isPartNumber = isPartNumber
      this.$refs['viewdPartPhotoModal'].show()
    },
    isEmpty (v) {
      return _.isEmpty(v)
    },
    setImage () {
      if (_.isEmpty(this.photosForModal)) {
        this.$toast.error('Please upload the photo')
        return
      }
      let part = _.find(this.rfq.parts, (prt) => {
        return Number(prt.id) === Number(this.selectedPart.id)
      })
      if (part) {
        part.photoId = this.photosForModal[0].id
        this.savePart(part)
      }
      this.$refs.addPartPhotoModal.hide()
    },
    getComment (part) {
      let r = ''
      let stockComment = _.trim(part?.stockComment)
      let condition = _.trim(part?.condition)

      if (stockComment) {
        r += stockComment
      }
      if (condition) {
        if (stockComment) {
          r += '<br/>'
        }
        r += 'Condition: ' + condition
      }
      return r
    },
    getStockLabel (part) {
      let result = ''
      if (part.selectedOption.label !== 'None') {
        result += part.selectedOption.label + '<br/>'
      }
      let stockComment = _.trim(part?.stockComment)
      let condition = _.trim(part?.condition)

      if (stockComment) {
        result += stockComment
      }
      if (condition) {
        if (stockComment) {
          result += '<br/>'
        }
        result += 'Condition: ' + condition
      }
      return result
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event
      let charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getRandom () {
      return uuid.v1()
    },
    addFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    successFileUpload (file, response) {
      this.isNeedRemoveImg = true
      if (response.status || response._status) {
        if (this.photosForModal.length === 1) {
          this.isNeedRemoveImg = false
          this.$refs.imgUpDropPinInage.removeFile(file)
          this.$toast.error('The limit is one picture')
        } else {
          this.photosForModal.push({
            dataUrl: file.dataURL,
            id: response.fileId
          })
        }
      }
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    sendFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    removedFile (file) {
      if (!this.isNeedRemoveImg) {
        this.isNeedRemoveImg = true
      } else {
        let removeIndex = _.find(this.photosForModal, (p) => {
          return p.dataUrl === file.dataUrl
        })
        if (removeIndex !== -1) {
          this.photosForModal.splice(removeIndex, 1)
        }
      }
    },
    onCloseModal () {
      console.log('on close')
      this.selectedPart = {}
      this.photosForModal = []
    },
    markAsDelivery (prt) {
      prt.type = 'delivery'
      prt.deliveryName = prt.altPartName || prt.name
      prt.deliveryZone = prt.partNumber
      this.savePart(prt)
    },
    orderBy (prts) {
      return _.orderBy(prts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
    },
    focusElement (ref) {
      if (this.$refs[ref] && this.$refs[ref][0]) {
        this.$refs[ref][0].focus()
      }
    },
    checkMove (q) {
      if (q.related && q.related.id && q.related.id.split('-').length === 2) {
        let id = q.related.id.split('-')[1]
        let part = _.find(this.parts, (prt) => {
          return Number(prt.id) === Number(id)
        })
        let index = this.parts.indexOf(part)
        if ((part && part.linkId > 0) || (this.parts[index + 1] && this.parts[index + 1].linkId > 0 && q.willInsertAfter)) {
          return false
        }
      }
    },
    startDraggable (e) {
      this.isDraggabling = true
      console.log('startDraggable', e)
    },
    endDraggable (e) {
      this.isDraggabling = false
      console.log('endDraggable', e)
    },
    selectedAllStock (value) {
      this.isDisabledSave = false
      _.forEach(this.parts, (prt) => {
        if (!prt.isHighlight && !prt.isNotApplicable && prt.stock != 7 && !prt.isAdditional && !prt.isExcludeFromDirectOrder) {
          prt.stock = value.value
          prt.stockComment = value.comment
        }
      })
      this.handleSaving(this.savePrices())
      // this.stockForAll.comment = '';
    },
    onFocus (ref) {
      this.isDraggableDisabled = true
      this.focusedElement = ref
    },
    isInFocusElement (ref) {
      if (ref === this.focusedElement) {
        return true
      }
      return false
    },
    onBlur (part, field) {
      this.isDraggableDisabled = false
      let updatedPart = _.find(this.originalParts, prt => {
        return Number(prt.id) === Number(part.id)
      })
      if (updatedPart) {
        if (field === 'selectedOption') {
          if (!_.isEqual(updatedPart[field], part[field])) {
            this.savePart(part)
          }
        } else if (updatedPart[field] !== part[field]) {
          this.savePart(part)
        }
      }
      this.focusedElement = ''
    },
    onKeyPressUp (ref) {
      console.log('up', ref)
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow === 0) {
        resultRef = this.computedAvailableRefs[this.computedAvailableRefs.length - 1][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow - 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
        this.onKeyPressUp(resultRef)
        return
      }
      this.$refs[resultRef][0].focus()
    },
    onKeyPressDown (ref) {
      console.log('down')
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow + 1 === this.computedAvailableRefs.length) {
        resultRef = this.computedAvailableRefs[0][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
        this.onKeyPressDown(resultRef)
        return
      }
      this.$refs[resultRef][0].focus()
    },
    onKeyPressLeft (ref, e, position) {
      if (e) {
        var elem = e.srcElement
        if (position > 0) {
          return
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentElement === 0) {
        let resultRow = []
        if (indexOfCurrentRow === 0) {
          resultRow = this.computedAvailableRefs[this.computedAvailableRefs.length - 1]
        } else {
          resultRow = this.computedAvailableRefs[indexOfCurrentRow - 1]
        }
        let resultRowLastIndex = resultRow.length - 1
        resultRef = resultRow[resultRowLastIndex]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement - 1]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      }
      if (resultRef.includes('stock-select')) {
        this.$refs[resultRef][0].focusOnInput()
      } else {
        this.$refs[resultRef][0].focus()
      }
    },
    onKeyPressRight (ref, e, position) {
      if (e) {
        var elem = e.srcElement
        if (position < elem.value.length) {
          return false
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null

      if (indexOfCurrentElement === this.computedAvailableRefs[indexOfCurrentRow].length - 1) {
        if (indexOfCurrentRow === this.computedAvailableRefs.length - 1) {
          resultRef = this.computedAvailableRefs[0][0]
        } else {
          resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][0]
        }
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement + 1]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      }
      if (resultRef.includes('stock-select')) {
        this.$refs[resultRef][0].focusOnInput()
      } else {
        this.$refs[resultRef][0].focus()
      }
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    addImportedParts (data) {
      console.log('addImprtedData', data)
      // return
      this.isStartImporting = true

      if (_.isEmpty(data.parts)) {
        this.isStartImporting = false
        return
      }
      let newPartParams = {
        addPart: false,
        addDelivery: false,
      }
      let param = null
      let isChangedOrder = false
      if (data.isFromCSV) {
        param = { updateFromCsvFile: true }
      } else if (data.isFromPDF) {
        param = { updateFromPdfFile: true }
      }
      if (param) {
        newPartParams = _.merge(newPartParams, param)
      }
      let newData = []
      _.forEach(data.parts, (item, index) => {
        console.log('IMPORT.LOG', index)
        let repairerPart = _.find(this.rfq.parts.slice(), (prt) => {
          return Number(prt.id) === Number(item?.left?.id)
        })
        if (item?.left?.id && item.left.id > 0 && !(repairerPart && repairerPart.stock != 7 && !repairerPart.isAdditional && !repairerPart.isExcludeFromDirectOrder)) {

        } else if (item?.left?.id && item.left.id > 0) {
          console.log('IMPORT.LOG.Exist', item.left.id)
          let originalPart = _.find(this.rfq.parts.slice(), (prt) => {
            return Number(prt.id) === Number(item?.left?.id)
          })
          originalPart.listOrderNumber = item.listOrderNumber
          if (item?.right) {
            if (item.isMarkedNoStock) {
              originalPart.stock = 3
            } else {
              originalPart.qty = item.qty
              originalPart.rrp = item.rrp
              originalPart.price = item.price
              originalPart.partNumber = item.number
              if (item?.right?.stockComment !== null) {
                originalPart.stockComment = item?.right?.stockComment
              }
              if (item?.right?.stockStatus !== null) {
                originalPart.stock = item?.right?.stockStatus
              }
              if (item?.right?.oeNumber !== null && this.isShowOePartNumberColumn) {
                originalPart.oePartNumber = item?.right?.oeNumber
              }

              if (_.trim(item.repairerName) !== _.trim(item.name) && !(item.isMarkNotApplicable || item.name === 'Not Applicable')) {
                originalPart.altPartName = item.name
                //commented this stroke when working on #165  originalPart.partNumber = item.number
                originalPart.name = item.repairerName
                if (originalPart.isNotApplicable) {
                  originalPart.isNotApplicable = false
                  originalPart.stockComment = ''
                  originalPart.stock = 0
                }
              }
            }
          }
          if (item.isMarkNotApplicable || item.name === 'Not Applicable') {
            originalPart.name = item.repairerName
            originalPart.partNumber = 'NOT APPLICABLE'
            originalPart.stock = 3
            originalPart.stockComment = 'NOT APPLICABLE'
            originalPart.isNotApplicable = true
          }
          newData.push(originalPart)
        } else {
          let isFreight = (item.isFreight || item?.right?.isFreight || item?.left?.isFreight)
          let prt = {
            comment: 0,
            id: null,
            price: 0,
            status: 0,
            qty: 1,
            number: '',
            rrp: 0,
            name: item.name,
            gstTax: 10,
            type: isFreight ? 'delivery' : 'manual',
            listOrderNumber: item.listOrderNumber,
          }
          if (isFreight) {
            prt.deliveryName = item.name
            prt.deliveryZone = item.number
          }

          if (item?.left?.isLinked) {
            prt.linkId = item?.left?.linkedId
            if (parseFloat(item.price) > 0 && parseFloat(item.rrp) > 0) {
              prt.linkType = 'withCost'
            } else {
              prt.linkType = 'withNoCost'
            }
          }

          let repairerName = item?.right?.name

          let newPart = new Part(prt)
          newPart.number = item.number
          newPart.qty = item.qty
          newPart.rrp = item.rrp
          newPart.price = item.price
          newPart.listOrderNumber = item.listOrderNumber
          newPart.name = item.name ? item.name : item.repairerName

          if (item?.right?.stockComment !== null) {
            if (item.isMarkedNoStock) {
              newPart.stockComment = 0
            } else {
              newPart.stockComment = item?.right?.stockComment
            }
          }
          if (item?.right?.stockStatus !== null) {
            newPart.stock = item?.right?.stockStatus
          }
          if (item?.right?.oeNumber !== null && this.isShowOePartNumberColumn) {
            newPart.oePartNumber = item?.right?.oeNumber
          }

          if (item.isMarkNotApplicable || newPart.name === 'Not Applicable') {
            newPart.name = repairerName
            newPart.partNumber = 'NOT APPLICABLE'
            newPart.stock = 3
            newPart.stockComment = 'NOT APPLICABLE'
            newPart.isNotApplicable = true
          }

          // if (item.isFreight) {
          //   newPart.isDelivery = true;
          //   newPart.isManual = false;
          // } else {
          //   newPart.isDelivery = false;
          //   newPart.isManual = true;
          // }

          if (index === 0) {
            newData.unshift(newPart)
          } else {
            newData = _.concat(newData.slice(0, index), newPart, newData.slice(index))
          }
        }
        if (index === data.parts.length - 1) {
          let interval = setInterval(() => {
            if (newData.length === this.filterParts(data.parts).length) {
              if (this.isAdditionalRfq) {
                let additionalParts = _.filter(this.rfq.parts, (p) => {
                  return p.isAdditional
                })

                if (!_.isEmpty(additionalParts)) {
                  additionalParts = _.orderBy(additionalParts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
                }

                if (!this.isRFQNew) {
                  newData = _.concat(newData, additionalParts)
                } else {
                  newData = _.concat(additionalParts, newData)
                }
                _.forEach(newData, (part, index) => {
                  part.listOrderNumber = index
                })
              }
              newData = _.orderBy(newData, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
              this.$set(this.rfq, 'parts', newData)
              setTimeout(() => {
                let interval = setInterval(() => {
                  if (!this.isLoading) {
                    clearInterval(interval)
                    this.savePrices(false, null, param, true).then((data) => {
                      console.log('save prices then data', data)
                      if (data.parts && data._status) {
                        _.forEach(data.parts, (id, ind) => {
                          if (Number(this.rfq.parts[ind].id) !== Number(id)) {
                            this.$set(this.rfq.parts[ind], 'id', id)
                          }
                        })
                        if (isChangedOrder) {
                          setTimeout(() => {
                            this.changedPartsOrder()
                          }, 200)
                        }
                      }
                      this.isStartImporting = false
                    })
                        .catch(() => {
                          this.isStartImporting = false
                        })
                  }
                }, 10)
                this.$emit('addedImportedParts')
              }, 500)
              clearInterval(interval)
            }
          }, 10)

        }
      })
    },
    filterParts (data) {
      return _.filter(data, (itm) => {
        if (itm?.left) {
          return itm?.left?.stock != 7 && !itm?.left?.isAdditional && !itm?.left?.isExcludeFromDirectOrder
        }
        return true
      })
    },
    linkedTo (part) {
      if (!part.isLinked) {
        return ''
      }
      let id = part.linkId
      //console.log('part.linkId', part.linkId)
      //console.log('this.rfq.parts', this.rfq.parts)
      let linkedToPart = _.filter(this.parts, function (i) {
        return id == i.id
      })
      // console.log('linkedToPart', linkedToPart)
      if (!linkedToPart || !linkedToPart[0]) {
        return ''
      }
      if (linkedToPart[0].type === 'delivery') {
        return linkedToPart[0].deliveryName
      }
      return linkedToPart[0].name
    },
    calculateSum (parts = []) {
      let result = 0
      _.forEach(parts, part => {
        _.forEach(this.parts, partRFQ => {
          if (partRFQ.originalPartId === Number(part.originalPartId)) {
            result += Number(part.price) * Number(partRFQ.qty)
          }
        })
      })
      return result
    },
    filterSuppliers () {
      if (!this.isRfqToOemGen && !this.isExistRequestBestPrice) {
        return {}
      }

      let order = {
        'OEM-GEN': 0,
        'OEM-PAR': 1,
        'OEM-ALT/AFTM': 2,
        'OEM-ALT': 3,
        'AFTM/USED': 4,
        'CERT-AFTM': 5,
        AFTM: 6,
        USED: 7,
        RECO: 8,
      }
      let orderedSuppliers = _.sortBy(this.rfq.suppliers, [
        s => order[s.type],
        s => s.name
      ])
      return orderedSuppliers
    },
    getSuppliersSize () {
      return _.size(this.filterSuppliers())
    },
    getColspanArray (number = 7, isNeedIncrease = false) {
      if (isNeedIncrease) {
        if (this.isRfqToOemGen) {
          number++
        }
      } else {
        if (!this.isRfqToOemGen) number--
      }
      if (this.rfq.isRebateEnabled) {
        number++
      }
      number += this.getSuppliersSize()
      let result = []
      for (let i = 1; i <= number; i++) {
        result.push(i)
      }
      return result
    },
    getColspan (number = 7, isNeedIncrease = false) {
      if (isNeedIncrease) {
        if (this.isRfqToOemGen) {
          number++
        }
      } else {
        if (!this.isRfqToOemGen) number--
      }
      if (this.rfq.isRebateEnabled) {
        number++
      }
      return String(number + this.getSuppliersSize())
    },
    getColspan2 () {
      if (this.isShowDeclineDirectOrderButton) {
        return this.getColspan() - 1
      }
      return this.getColspan()
    },
    getColspan3 () {
      if (this.isShowDeclineDirectOrderButton) {
        if (this.isShowOePartNumberColumn) {
          return 5
        }
        return 3
      }
      if (this.isShowOePartNumberColumn) {
        return 4
      }
      return 2
    },
    getData (part = null, isFromImportPdf = false) {
      let parts = []
      if (_.isEmpty(part)) {
        parts = _.map(this.parts, (prt, index) => {
          let rebate = null
          if (!_.isNumber(prt.rebate) && !_.isString(prt.rebate)) {
            rebate = prt.price * (100 - parseFloat(this.rfq.rebatePercent)) / 100
          } else {
            rebate = prt.rebate
          }
          let data = {
            price: prt.price,
            partNumber: prt.number,
            rrp: prt.rrp,
            name: prt.name,
            oePartNumber: prt.oePartNumber,
            rebate: this.rfq.isRebateEnabled ? rebate : null,
            stock: prt.stock == 7 ? '7' : String(prt.selectedOption.value),
            altPartName: prt.altPartName,
            stockComment: !_.isEmpty(prt.stockComment) ? String(prt.stockComment) : '',
            linkId: prt.linkId,
            linkType: prt.linkType,
            qty: prt.qty,
            listOrderNumber: prt.listOrderNumber || prt.listOrderNumber === 0 ? prt.listOrderNumber : index,
            condition: prt.condition,
            photoId: prt.photoId,
          }
          if (this.rfq.properties.isNonReturnable) {
            data.nonReturnable = prt.nonReturnable
          }
          if (isFromImportPdf) {
            if (prt.isDelivery) {
              data.isDelivery = true
            } else if (prt.isManual) {
              data.isManual = true
            }
          }

          if (prt.isNotApplicable && prt.isNotApplicable === true) {
            data.isNotApplicable = true
            data.beforeMarkNotApplicable = prt.beforeMarkNotApplicable
          } else {
            data.isNotApplicable = false
          }
          if (prt.isDelivery) {
            data.deliveryName = prt.deliveryName
            data.deliveryZone = prt.deliveryZone
          }
          if (isFromImportPdf) {
            data.id = prt.id ? prt.id : null
          } else if (prt.id) {
            data.id = prt.id
          }
          return data
        })
      } else {
        if (part.setExcludeFromDirectOrder && part.setExcludeFromDirectOrder === true) {
          let data = {
            id: part.id,
            setExcludeFromDirectOrder: true,
          }
          parts.push(data)
        } else if (part.setIncludeToDirectOrder && part.setIncludeToDirectOrder === true) {
          let data = {
            id: part.id,
            setIncludeToDirectOrder: true,
          }
          parts.push(data)
        } else {
          let rebate = null
          let stock = null
          if (part.selectedOption && part.selectedOption.value !== null) {
            stock = String(part.selectedOption.value)
          }
          if (part.stock == 7) {
            stock = '7'
          }
          if (!_.isNumber(part.rebate) && !_.isString(part.rebate)) {
            rebate = part.price * (100 - parseFloat(this.rfq.rebatePercent)) / 100
          } else {
            rebate = part.rebate
          }
          let index = this.parts.indexOf(part)
          let newPrt = {
            price: part.price,
            partNumber: part.number,
            rrp: part.rrp,
            name: part.name,
            stock: stock,
            altPartName: part.altPartName,
            oePartNumber: part.oePartNumber,
            stockComment: !_.isEmpty(part.stockComment) ? String(part.stockComment) : '',
            linkId: part.linkId,
            linkType: part.linkType,
            qty: part.qty,
            listOrderNumber: part.listOrderNumber || part.listOrderNumber === 0 ? part.listOrderNumber : index,
            type: part.type,
            condition: part.condition,
            photoId: part.photoId,
            rebate: this.rfq.isRebateEnabled ? rebate : null,
          }

          if (this.rfq.properties.isNonReturnable) {
            newPrt.nonReturnable = part.nonReturnable
          }

          if (isFromImportPdf) {
            if (part.isDelivery) {
              newPrt.isDelivery = true
            } else if (part.isManual) {
              newPrt.isManual = true
            }
          }

          if (part.isNotApplicable && part.isNotApplicable === true) {
            newPrt.isNotApplicable = true
            newPrt.beforeMarkNotApplicable = part.beforeMarkNotApplicable
          } else {
            newPrt.isNotApplicable = false
          }
          if (part.isDelivery) {
            newPrt.deliveryName = part.deliveryName
            newPrt.deliveryZone = part.deliveryZone
          }

          if (isFromImportPdf) {
            newPrt.id = part.id ? part.id : null
          } else if (part.id) {
            newPrt.id = part.id
          }
          parts.push(newPrt)
        }
      }
      return {
        rfqId: this.rfq.id,
        // discountApplied: this.rfq.discountsApplied,
        partsDeliveryETA: this.rfq.partsDeliveryETA,
        quoteValidity: this.rfq.quoteValidTill,
        parts: parts,
      }
    },
    handleSaving (callback = false) {
      if (callback) {
        let interval = setInterval(() => {
          if (!this.isLoading) {
            clearInterval(interval)
            callback
          }
        }, 10)
      }
    },
    sendPrices () {
      // check AFTM or USED has mark their optional parts (if any)
      if (!this.optionalPartCheck()) {
        this.$toast.error('An optional part/s type has not been set. Check again')
        // enable notification
        this.enableOptionalCheck = true
        return
      }
      // return;

      this.isLoading = true
      NProgress.start()
      let data = this.getData(this.rfq)
      Axios.post('/ir/supplier/rfq/' + this.rfq.id + '/submit-pricing', data)
          .then(response => {
            NProgress.done()
            this.$emit('priced')

            if (this.priceResubmitFlag) {
              this.$emit('resubmitting')
            }

            if (response.data._status) {
              if (this.isRFQNew) {
                this.$toast.success('Quote has been priced')
              } else {
                this.$toast.success('Quote has been updated')
              }
              this.showAltPartNameId = 0
              this.isDisabledSave = true

              if (this.rfq?.properties?.isSentAsDirectOrder && response.data?.orderId) {
                this.$router.push({ name: 'SupplierPartsOrderView', params: { orderId: response.data.orderId } })
              }
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in pricing Request For Quote')
            NProgress.done()
          }).finally(() => {
        this.isLoading = false
      })
    },
    changedPartsOrder () {
      let data = { sorts: [] }
      console.log('changedPartsOrder')
      let order = 0
      this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      this.parts.forEach((prt, ind) => {
        prt.listOrderNumber = ind
        data.sorts.push({
          id: prt.id,
          listOrderNumber: prt.listOrderNumber
        })
      })
      // this.rfq.parts = this.parts
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
          .then(() => {
            this.isDraggabling = false
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
        this.isDraggabling = false
      })
    },
    linkPart (part, linkId, linkType) {
      this.isDraggabling = true
      this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      this.parts.forEach((prt, ind) => {
        prt.listOrderNumber = ind
      })
      let linkedPrt = _.find(this.parts, (prt) => {
        return Number(linkId) === Number(prt.id)
      })
      part.linkId = linkId
      part.linkType = linkType
      // if (linkType === 'withCost') {
      //   part.rrp = 0
      // }
      let index = Number(linkedPrt.listOrderNumber) + 0.5
      let linkedParts = _.filter(this.parts, (prt) => {
        return Number(prt.linkId) === Number(linkId) && Number(prt.id) !== Number(part.id)
      })
      if (!_.isEmpty(linkedParts)) {
        let lastLinkedPart = linkedParts[linkedParts.length - 1]
        index = _.findIndex(this.parts, (p) => {
          return Number(p.id) === Number(lastLinkedPart.id)
        }) + 0.5
      }

      part.listOrderNumber = index
      console.log(index, 'link part')
      this.changedPartsOrder()
      // this.savePart(part)
      setTimeout(() => {
        this.savePart(part)
      }, 1000)
    },
    unlinkPart (part) {
      // console.log('unlinkPart', part, (part.linkId>part.id))
      part.linkId = 0
      part.linkType = null
      if (part.rrp === '-1.00') {
        part.rrp = '0.00'
      }

      // let id = Number(part.id)
      // let indexOld = Number(_.cloneDeep(part).listOrderNumber)
      // let index = null
      // let isIndexLast = false

      // _.forEach(this.parts, (prt, prtIndex) => {
      //   // console.log(prt, prtIndex, prt.id, id, this.parts,)
      //   if (Number(prtIndex) === 0) {
      //     if ((Number(prt.id) - 1) === id) {
      //       index = 0
      //       return false
      //     }
      //   } else {
      //     let prevPrt = this.parts[prtIndex - 1]
      //     let isNotLinkedPrevPart = !!(prevPrt && !(prevPrt.linkId > 0))
      //     let isNotLinkedCurrentPart = !!(prt && !(prt.linkId > 0))
      //     if (isNotLinkedCurrentPart && isNotLinkedPrevPart && (Number(prt.id) - 1) === id) {
      //       if (prtIndex + 1 === this.parts.length) {
      //         isIndexLast = true
      //         index = Number(prt.listOrderNumber)
      //         return false
      //       }
      //       index = Number(prt.listOrderNumber)
      //       return false
      //     } else if (prt.id >= id) {
      //       id = prt.id
      //     }
      //   }
      // })
      //
      // if (index !== null) {
      //   part.listOrderNumber = index
      // } else {
      //   part.listOrderNumber = 999
      // }
      //
      // if (index === 999) {
      //   _.forEach(this.parts, (prt) => {
      //     if (Number(prt.listOrderNumber) >= indexOld && Number(prt.id) !== Number(part.id)) {
      //       prt.listOrderNumber--
      //     } else if (Number(prt.id) === Number(part.id)) {
      //       part.listOrderNumber = this.parts.length - 1
      //     }
      //   })
      // } else if (isIndexLast) {
      //   _.forEach(this.parts, (prt) => {
      //     if (Number(prt.listOrderNumber) >= indexOld && Number(prt.id) !== Number(part.id)) {
      //       prt.listOrderNumber--
      //     }
      //   })
      // } else {
      //   _.forEach(this.parts, (prt) => {
      //     if (Number(prt.listOrderNumber) >= index) {
      //       prt.listOrderNumber++
      //     }
      //   })
      // }

      // this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      // console.log(this.parts, 'index', index)
      // this.changedPartsOrder()
      setTimeout(() => {
        this.savePart(part)
      }, 1000)
    },
    isHasLinkedParts (part) {
      return _.some(this.parts, (prt) => {
        return Number(prt.linkedId) === Number(part.id)
      })
    },
    onChange (part) {
      if (this.isShowErrorMessage(part)) {
        return
      }
      this.isDisabledSave = false
      if (!_.isEmpty(part.number)) {
        part.number = this.formatPartNumber(part.number)
      }
      if (!_.isEmpty(part.deliveryZone)) {
        part.deliveryZone = this.formatPartNumber(part.deliveryZone)
      }
      if (!_.isEmpty(part.oePartNumber)) {
        part.oePartNumber = this.formatPartNumber(part.oePartNumber)
      }
      this.savePart(part)
    },
    formatPartNumber(v) {
      return _.trim(v.replace(/\s\s+/g, ' '))
    },
    savePart (part) {
      if (this.isStartImporting) {
        return
      }
      this.handleSaving(this.savePrices(false, part))
    },
    savePrices (afterSubmitting = false, part, params, isFromImportPdf = false) {
      // if (this.isPricedStatus && !this.priceResubmitFlag) return

      let data = this.getData(part, isFromImportPdf)
      if (this.isPricedStatus && this.priceResubmitFlag) {
        let isHasEmptyName = _.some(data.parts, (p) => {
          let bool = false
          let originalPart = _.find(this.parts, (prt) => {
            return Number(prt.id) === Number(p.id)
          })
          if (originalPart) {
            if (originalPart.type === 'delivery') {
              bool = _.isEmpty(p.deliveryName)
            } else if (originalPart.type === 'manual') {
              bool = _.isEmpty(p.name)
            }
          }
          return bool
        })
        if (isHasEmptyName) {
          this.$toast.error('Manual and Delivery part name can not be empty')
          return
        }
      }

      this.isLoading = true
      NProgress.start()
      console.log('save prices get data', data)
      if (params) {
        data = _.merge(data, params)
      }
      return new Promise((resolve, reject) => {
        Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
            .then(response => {
              if (response.data.status) {
                if (response?.data?.photosAddedUrl) {
                  let partIndex = _.findIndex(this.rfq.parts, (prt) => {
                    return Number(prt.id) === Number(part.id)
                  })
                  if (partIndex !== -1) {
                    this.$set(this.rfq.parts[partIndex], 'photoForPart', response.data.photosAddedUrl[part.id])
                  }
                }
                resolve(response.data)
                this.showAltPartNameId = 0
              }
            })
            .catch(error => {
              reject(error)
              console.log(error)
            })
            .finally(() => {
              this.isLoading = false
              NProgress.done()
            })
      })
    },
    addNewPart (type) {
      if (this.isDisabledFields) {
        return
      }
      this.isLoading = true
      NProgress.start()
      let id = null
      let payload = type === 'delivery' ? { addDelivery: true } : { addPart: true }
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, payload).then(response => {
        if (response.data.status && response.data.manualPartId && response.data.manualPartId > 0) {
          id = response.data.manualPartId
          let prt = {
            comment: 0,
            id: response.data.manualPartId,
            price: 0,
            status: 0,
            qty: 1,
            number: '',
            rrp: 0,
            name: '',
            gstTax: 10,
            type: type,
            listOrderNumber: 999,
          }

          this.isDisabledSave = false

          let newPart = new Part(prt)
          let newData = this.parts
          newData.push(newPart)
          newData = _.orderBy(newData, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
          this.$set(this.rfq, 'parts', newData)
          setTimeout(() => {
            // this.savePrices()
            if (type === 'delivery') {
              this.focusElement('deliveryName-' + response.data.manualPartId)
            } else {
              this.focusElement('manual-name-' + response.data.manualPartId)
            }
          }, 500)

          // this.rfq.addManualPart(response.data.manualPartId) //#153 - remove this
        }
      }).catch(error => {
        console.log(error)
        this.$toast.error('An error occurred in submitting your request')
      }).finally(() => {
        this.isLoading = false
        NProgress.done()
        this.lastManualPartId = id
      })
    },
    removePart (part, key) {
      console.log('removePart', this.isPricedStatus, this.priceResubmitFlag)
      if (this.isPricedStatus && this.priceResubmitFlag) {
        if (part.type === 'delivery') {
          part.deliveryName = ''
        } else {
          part.name = ''
        }
        this.isDisabledSave = false
        if (part.isOriginalPart) {
          return
        }
      }
      this.isLoading = true
      NProgress.start()
      let data = {
        removePartId: part.id
      }
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
          .then(response => {
            if (response.data.status && response.data.removedManualPart && response.data.removedManualPart == part.id) {

              let partIndex = _.findIndex(this.rfq.parts, (prt) => {
                return Number(prt.id) === Number(part.id)
              })

              if (partIndex !== -1) {
                key = partIndex
              }

              this.rfq.removePartByKey(key)
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in submitting your request')
          }).finally(() => {
        this.isLoading = false
        NProgress.done()
      })
    },
    addOptionalPart (part) {
      const index = this.parts.findIndex(_part => part.id === _part.id)
      if (index >= 0) {
        this.isLoading = true
        NProgress.start()
        Axios.post('/ir/supplier/rfq/' + this.rfq.id, {
          addOptionalPart: true,
          toPartId: part.originalPartId,
        }).then(response => {
          if (response.data.status && response.data.optionalPartId && response.data.optionalPartId > 0) {
            this.parts.splice(index + 1, 0, new Part({
              id: response.data.optionalPartId,
              qty: this.parts[index].qty,
              comment: '',
              name: 'Optional Part',
              partNumber: '',
              rrp: null,
              stock: 0,
              stockComment: '',
              optionalPartComplete: false,
              type: 'optional' // assumed optional part type defined here
            }))
          }
        }).catch(error => {
          console.log(error)
          this.$toast.error('An error occurred in Add Optional Part')
        }).finally(() => {
          this.isLoading = false
          NProgress.done()
        })

      } else {
        console.log('Part selected not found')
      }
    },
    setOptionalPartType (optionalPart, typeName) {
      const partIndex = this.parts.findIndex(_part => _part === optionalPart)
      if (partIndex) {
        this.parts[partIndex].name = this.parts[partIndex].name.split(' - ')[0].concat([` - ${typeName}`])
        this.parts[partIndex].optionalPartComplete = true
        this.handleSaving(this.savePrices())
      }
    },
    removeOptionalPart (optionalPart) {
      console.log('removeOptionalPart', optionalPart)
      const partIndex = this.parts.findIndex(_part => _part === optionalPart)
      console.log('partIndex', partIndex)
      if (partIndex) {
        this.handleSaving(this.removePart(optionalPart, partIndex))
      }
    },
    handleMouseDown(e) {
      if (e.button == 2) {
        e.preventDefault()
      }
    },
    handlePartnameRightClick (e, part, index) {
      if (this.isMobile) {
        return
      }
      if (this.isDisabledFields || part.isManualByOemSupplier) {
        return
      }
      if (this.isNewStatus || this.priceResubmitFlag) {
        let newIndex = _.findIndex(this.parts, (prt) => {
          return Number(prt.id) === Number(part.id)
        })
        if (newIndex !== -1) {
          index = newIndex
        }
        this.closeContextMenu()
        this.$set(this.parts[index], 'isSelected', true)
        this.selectedPartId = part.id
        this.selectedPartIndex = index
        this.$refs.menuPartName.open(e, part)
      }
    },
    closeContextMenu () {
      console.log('close', this.selectedPartId, this.parts,)
      this.selectedPartId = null
      if (this.selectedPartIndex === null) {
        return
      }
      _.forEach(this.parts, (prt, index) => {
        this.$set(this.parts[index], 'isSelected', false)
      })
    },
    handlePartnameSelected (part, selectedOption) {
      console.log('handlePartnameSelected', part, selectedOption)

      this.$refs.menuPartName.close()
      if (selectedOption.menu.value === 'markAsFreight') {
        this.markAsDelivery(part)
      } else if (selectedOption.menu.value === 'link' && selectedOption.submenu) {
        this.linkPart(part, selectedOption.submenu.id, selectedOption.menu.linkType)
      } else if (selectedOption.menu.value === 'unlink') {
        this.unlinkPart(part)
      } else if (selectedOption.menu.value === 'altPartName') {
        this.showAltPartNameId = part.id
      } else if (selectedOption.menu.value === 'editPartName') {
        this.showAltPartNameId = part.id
      } else if (selectedOption.menu.value === 'removePartName') {
        this.showAltPartNameId = 0
        part.altPartName = ''
        this.savePart(part)
      } else if (selectedOption.menu.value === 'addOptionalPart') {
        this.handleSaving(this.addOptionalPart(part))
      } else if (selectedOption.menu.value === 'setOptionalPartType') {
        this.setOptionalPartType(part, selectedOption.submenu.name)
      } else if (selectedOption.menu.value === 'removeOptionalPart') {
        this.removeOptionalPart(part)
      } else if (selectedOption.menu.value === 'addPhoto') {
        this.selectedPart = part
        this.addPhoto()
      } else if (selectedOption.menu.value === 'removePhoto') {
        part.photoId = null
        part.photoForPart = null
        this.savePart(part)
      } else if (selectedOption.menu.value === 'markNotApplicable') {
        this.markNotApplicable(part)
      } else if (selectedOption.menu.value === 'unMarkNotApplicable') {
        this.unMarkNotApplicable(part)
      } else if (selectedOption.menu.value === 'setCondition' && selectedOption.menu.subValue) {
        part.condition = selectedOption.menu.subValue
        this.savePart(part)
      } else if (selectedOption.menu.value === 'removeCondition') {
        part.condition = null
        this.savePart(part)
      } else if (selectedOption.menu.value === 'editNotApplicableComment') {
        this.savePart(part)
      } else if (selectedOption.menu.value === 'addToNilSupplyList') {
        this.addToNilSupplyList(part, selectedOption)
      } else if (selectedOption.menu.value === 'removeToNilSupplyList') {
        this.removeFromNilSupplyList(part, selectedOption)
      } else if (selectedOption.menu.value === 'markAsNonReturnable') {
        this.markNonReturnable(part)
      } else if (selectedOption.menu.value === 'removeNonReturnable') {
        this.removeNonReturnable(part)
      } else if (selectedOption.menu.value === 'excludeFromDirectOrder') {
        this.excludeFromDirectOrder(part)
      } else if (selectedOption.menu.value === 'includeToDirectOrder') {
        this.includeToDirectOrder(part)
      }
      this.isDisabledSave = false
    },
    markNonReturnable (part) {
      part.nonReturnable = true
      part.nonReturnableBy = this.currentUser.id
      part.nonReturnableAt = new Date()
      this.savePart(part)
    },
    removeNonReturnable (part) {
      part.nonReturnable = false
      part.nonReturnableBy = null
      part.nonReturnableAt = null
      this.savePart(part)
    },
    addToNilSupplyList (part) {
      NProgress.start()
      Axios.post('/ir/supplier/rfq/add-to-nil-supply', { partId: part.id })
          .then(response => {
            if (response.data._status) {
              part.stock = 7
              part.price = 0
              if (part.rebate && part.rebate > 0) {
                part.rebate = 0
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    removeFromNilSupplyList (part) {
      NProgress.start()
      Axios.post('/ir/supplier/rfq/remove-from-nil-supply', { partId: part.id })
          .then(response => {
            if (response.data._status) {
              part.stock = null
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    markNotApplicable (part) {
      part.beforeMarkNotApplicable = {
        partNumber: part.partNumber,
        stock: part.stock,
        stockComment: part.stockComment,
        price: part.price,
      }
      part.partNumber = 'NOT APPLICABLE'
      part.stock = 3
      part.stockComment = 'NOT APPLICABLE'
      part.isNotApplicable = true
      part.price = 0

      this.$refs['stock-select-' + part.id][0].selectNoStockOption()
      this.savePart(part)
    },
    addPhoto () {
      console.log('addPhoto')
      this.$refs['addPartPhotoModal'].show()
    },
    viewPhoto () {
      console.log('viewPhoto')
      this.$refs['viewdPartPhotoModal'].show()
    },
    unMarkNotApplicable (part) {
      if (!_.isEmpty(part.beforeMarkNotApplicable)) {
        part.partNumber = part.beforeMarkNotApplicable.partNumber
        part.stock = part.beforeMarkNotApplicable.stock
        part.stockComment = part.beforeMarkNotApplicable.stockComment
        part.price = part.beforeMarkNotApplicable.price
      } else {
        part.partNumber = ''
        part.stock = 0
        part.stockComment = ''
        part.price = null
      }
      part.isNotApplicable = false
      this.$refs['stock-select-' + part.id][0].selectNoneStockOption()
      this.savePart(part)
    },
    selectStockStatus ({ comment, label, textValue, value, isHighlight }, partIndex) {
      this.isDisabledSave = false
      let linkedParts = _.filter(this.parts, (p) => {
        return Number(p.linkId) > 0 && p.linkType === 'withNoCost' && Number(p.linkId) === Number(this.parts[partIndex].id)
      })
      if (!isHighlight) {
        this.parts[partIndex].stock = value
        this.parts[partIndex].stockComment = comment
        this.isDisabledSave = false
        if (!_.isEmpty(linkedParts)) {
          _.forEach(linkedParts, (pr) => {
            let prIndex = _.findIndex(this.parts, (orP) => {
              return Number(orP.id) === Number(pr.id)
            })
            if (prIndex !== -1) {
              this.parts[prIndex].stock = value
            }
          })
          this.handleSaving(this.savePrices())
        } else {
          this.savePart(this.parts[partIndex])
        }
      }
    },
    handleContextOpen (e) {
      this.$refs['menuPartName'].$el.querySelector('.partname-menu').focus()
    },
    onPartReorderChange ({ moved }) {
      // console.log('onPartReorderChange', this.rfq.parts)
      // console.log('Part order change', moved, moved.element.name)

      if (moved.oldIndex === moved.newIndex) {
        return
      }

      moved.element.listOrderNumber = moved.newIndex
      let data = { sorts: [] }

      let i = 0
      this.parts.forEach(part => {
        part.listOrderNumber = i++
        data.sorts.push({
          id: part.id,
          listOrderNumber: part.listOrderNumber
        })
      })

      Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
          .then(response => {
            console.log('Reorder Response: %o', response.data)
//          this.rfq.parts = this.parts
            this.isDraggabling = false

          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in submitting your request')
          }).finally(() => {
        this.isDraggabling = false
      })
    },
    checkName (name) {
      const arr = name.split(' - ')
      return arr.length > 1
    },
    optionalPartCheck () {
      let passed = true
      this.parts.forEach(part => {
        if (part.isOptional) {
          passed = this.checkName(part.name)
          // console.log('optionalPartCheck', passed, part.type, part, this.checkName(part.name));
        }
      })
      return passed
    },
  },
  mounted () {
    //$('.b-table-sticky-header').height($(window).height() - 160)
    this.debounceOnKeyPressUp = _.debounce(function (v) {
      this.onKeyPressUp(v)
    }, 10)

    this.debounceOnKeyPressDown = _.debounce(function (v) {
      this.onKeyPressDown(v)
    }, 10)

    this.debounceOnKeyPressLeft = _.debounce(function (v, e, position) {
      this.onKeyPressLeft(v, e, position)
    }, 10)

    this.debounceOnKeyPressRight = _.debounce(function (v, e, position) {
      this.onKeyPressRight(v, e, position)
    }, 10)

    window.addEventListener('resize', this.setLeftPosition)
    window.addEventListener('scroll', this.setLeftPosition)
    window.addEventListener('keydown', this.keyDownEvent)

    let el = document.getElementsByClassName('b-table-sticky-header')[0]
    el.addEventListener('scroll', this.onTableScroll)
    this.setLeftPosition()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setLeftPosition)
    window.removeEventListener('scroll', this.setLeftPosition)
    window.removeEventListener('keydown', this.keyDownEvent)
  },
  components: {
    Numeral,
    StockSelect,
    VueContext,
    partNameMenu,
    draggable,
    DatePicker,
    Multiselect,
    FileUpload,
    vueDropzone,
    RebateNumeral
  },
}
</script>


<style>
.blinking-nr-text {
  cursor: default;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.non-returnable-tooltip .tooltip-inner {
  max-width: 205px !important;
}

.supplier-column-highlight-2 {
  background-color: rgb(245, 245, 245) !important;
}

.supplier-selected-cell {
  box-shadow: inset 0px 0px 0px 2px #29BBC1;
}

th.supplier-selected-column:after,
th.supplier-selected-column:before,
th.supplier-selected-column:hover:after,
th.supplier-selected-column:hover:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

th.supplier-selected-column:before {
  top: 0;
  border: 2px solid #29BBC1;
  /*height: 125px;*/
  height: 100%;
  z-index: 23;
}

th.supplier-selected-column {
  cursor: pointer;
}

#supplier-rfq-view-table table {
  margin-left: 0px !important
}

#supplier-rfq-view-line-column,
#supplier-rfq-view-part-list-column,
.supplier-rfq-view-part-list-cell,
.supplier-rfq-view-line-cell,
.supplier-info-cell {
  position: sticky !important;
  z-index: 5 !important;
  top: 1px
}

#supplier-rfq-view-line-column,
#supplier-rfq-view-part-list-column {
  background-color: rgb(227, 228, 231) !important
}

.supplier-rfq-view-part-line:hover .supplier-rfq-view-part-list-cell,
.supplier-rfq-view-part-line:hover .supplier-rfq-view-line-cell,
.supplier-rfq-view-part-line:hover .supplier-info-cell {
  background-color: #e9f8f9 !important;
}

.supplier-info-cell {
  left: 0px !important;
  opacity: 1 !important;
  background: white !important;
  color: #1C1F39 !important;
}


.supplier-rfq-view-part-list-cell,
.supplier-rfq-view-line-cell {
  background-color: white !important
}

.opacity-06 {
  opacity: 0.6;
}

.direct-blinking-red,
.direct-blinking-red-stock .stock-select__selected {
  animation-name: blinking-border-red;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-border-red {
  20% {
    border-color: #F44236;
    opacity: 1;
  }
  80% {
    border-color: #F44236;
    opacity: 1;
  }
}

.ps-tooltip-for-used-price {
  top: 15px !important;
  left: 5px !important;
}

.waiting-cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
}

.price-blinking-black {
  animation-name: blinking-black;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
  cursor: pointer;
}

@keyframes blinking-black {
  40% {
    color: #212529;
    opacity: 0.6;
  }
  80% {
    color: #212529;
    opacity: 0.2;
  }
}
.custom-rfq-price-tooltip {
  top: 5px !important;
}

.custom-rfq-price-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}

.custom-rfq-price-tooltip {
  top: 5px !important;
}

.custom-rfq-price-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}

.supplier-rfq-view-page .part-list .blinking-red {
  animation-name: blinking-red;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-red {
  20% {
    color: red;
    opacity: 1;
  }
  80% {
    color: red;
    opacity: 1;
  }
}

.supplier-rfq-view-page .part-list .blinking-green {
  animation-name: blinking-green;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-green {
  20% {
    color: #0cb458;
    opacity: 1;
  }
  80% {
    color: #0cb458;
    opacity: 1;
  }
}


.decline-direct-order-btn {
  border: 1px solid #ff5e5e !important;
  color: #ff5e5e !important;
  background: none !important;
  box-shadow: none !important;
}

.ghost-items {
  /*width: 100%;*/
  background-color: #F9F9F9 !important;
  border: 2px solid #29bcc179 !important;
  box-shadow: 0 0 0 0.3rem #29bcc13b !important;
  border-radius: 3px;
  box-sizing: border-box;
  z-index: 2 !important;
}

/*.ghost-items .td.part-list-part-name {*/
/*  min-width: 300px;*/
/*}*/

/*.ghost-items .td.part-list-number {*/
/*  min-width: 190px;*/
/*}*/

/*.ghost-items .td.part-list-in-stock {*/
/*  min-width: 155px;*/
/*}*/

/*.ghost-items .td.part-list-qty {*/
/*  min-width: 20px;*/
/*}*/

/*.ghost-items .td.part-list-rrp {*/
/*  width: 100px;*/
/*}*/

/*.ghost-items .part-list-company-price {*/
/*  min-width: 100px;*/
/*}*/

</style>

<style scoped>
.part-price-with-icon {
  display: flex;
  align-items: center;
  justify-content: end;
}
.supplier-icon-ps-tooltip >>> .tooltip-inner{
  border-radius: 10px 10px 10px 0 !important;
}
.supplier-icon-ps-tooltip.bs-tooltip-left >>> .tooltip-inner{
  border-radius: 10px 10px 0 10px !important;
}
.supplier-icon-ps-tooltip-error >>> .tooltip-inner{
  max-width: 215px;
}
.supplier-icon-ps-tooltip-error.bs-tooltip-left  >>> .tooltip-inner{
  max-width: 215px;
}
.supplier-icon-ps-tooltip.bs-tooltip-right{
  top: -20px !important;
  left: -14px !important;
}
.supplier-icon-ps-tooltip.bs-tooltip-left{
  top: -20px !important;
  left: 18px !important;
}

.supplier-icon-ps-tooltip.bs-tooltip-left.non-reg-tooltip {
  top: -58px !important;
  left: 9px !important;
}
.supplier-icon .bx{
  font-size: 27px;
  opacity: 1 !important;
}
.oe-part-number-check {
  font-size: 24px;
  color: #42B867FF;
  display: inline;
}

.oe-part-number-icon {
  position: absolute;
  display: inline;
  right: 0;
  top: 0;
  border: 8px solid transparent;
  border-top: 8px solid #A3A3A3FF;
  border-right: 8px solid #A3A3A3FF;
}

.supplier-rfq-view-part-list-container-2 {
  overflow-x: auto;
}

.decline-color {
  color: red !important;
}

.part-list-supplier-non-non-active {
  background-color: #e3e4e7 !important;
  cursor: default !important;
}

.selected-row {
  background-color: rgb(233, 248, 249) !important;
}

.selected-part {
  background-color: rgb(233, 248, 249) !important;
}

.selected-part .supplier-rfq-view-line-cell,
.selected-part .supplier-rfq-view-part-list-cell {
  background-color: rgb(233, 248, 249) !important;
}

.part-list-part-name.td-optional-part {
  padding-left: 25px !important;
}

.td-alt-name,
.td-add-alt-name {
  /* height: 56px !important; */
  height: auto !important;
  padding: 0.75em 15px !important;
}

.td-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .td-add-alt-name {
    height: 56px !important; /*
} */

.td-add-alt-name .part-list-item-name {
}

.orange-icon {
  color: orange;
}

.gray-icon {
  color: rgb(121, 121, 121) !important;
}

.green-icon {
  color: rgb(60, 199, 169) !important;
}

.blue-icon {
  color: #2f6da5 !important;
}

.part-list-item-name .div-add-alt-name {
  opacity: 1 !important;
  color: #1C1F39 !important;;
  margin-bottom: 8px !important;
}

.part-list-item-name .div-add-alt-name:last-child {
  margin-bottom: 0px !important;
}

.part-list-item-name > span {
  margin-bottom: 4px;
}

.td-alt-name .form-control,
.td-add-alt-name .form-control {
  display: block;
  width: 200px !important;
}


.part-list .td-selected {
  color: #212529 !important;
}

.part-list .part-name-from-supplier {
  /*color: #212529 !important;*/
  font-weight: bold;
}

.part-list .bxs-check-circle, .part-list .bxs-minus-circle {
  /* left: 30px; */
}

.part-list .bxs-minus-circle {
  color: #c03f4f;
}

.part-list .offer-checked {
  border: 1px solid rgba(46, 169, 255, 0.1);
  background-color: rgba(46, 169, 255, 0.1);
}

.part-list table th:first-child,
.part-list table td:hover:first-child {
  border-radius: 4px 0 0 4px !important;
}

.part-list table th:last-child,
.part-list table td:hover:last-child {
  border-radius: 0 4px 4px 0 !important;
}

.part-list .b-value {
  width: 300px;
  word-break: break-all;
  position: relative;
  border-bottom: none !important;
}

.part-list .remove-part {
  color: #ff5e5e;
  cursor: pointer;
}

.part-list .w-90 {
  width: 90%;
}

.part-list .add-part {
  color: #29BBC1;
  opacity: 1;
}

.part-list .add-part i {
  font-size: 16px;
}

.part-list .add-part span {
  display: inline-block;
  position: relative;
  bottom: 2px;
}

.part-list table th span {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy4yMzkgMTMuMjY1VjIuNzM0bC0xLjkzIDIuMDNhLjczNS43MzUgMCAwMS0xLjA2Ny0uMDEuODMuODMgMCAwMS0uMDEtMS4xMjNMMy40NjIuMjM0YS43MzUuNzM1IDAgMDExLjA3NiAwbDMuMjMgMy4zOTdhLjgyOS44MjkgMCAwMS4yMDcuNzc5Ljc4Mi43ODIgMCAwMS0uNTQzLjU3Mi43MzYuNzM2IDAgMDEtLjc0LS4yMTlsLTEuOTMtMi4wMjh2MTAuNTNsMS45My0yLjAzYS43MzYuNzM2IDAgMDEuNzQtLjIxOC43ODIuNzgyIDAgMDEuNTQzLjU3MmMuMDcuMjgtLjAxLjU3OC0uMjA3Ljc3OWwtMy4yMyAzLjM5N0EuNzQuNzQgMCAwMTQgMTZhLjc0Ljc0IDAgMDEtLjUzOC0uMjM1bC0zLjIzLTMuMzk4YS44MjkuODI5IDAgMDEtLjIwNy0uNzc5Ljc4Mi43ODIgMCAwMS41NDMtLjU3MmMuMjY3LS4wNzMuNTUuMDEuNzQuMjE5bDEuOTMgMi4wM3oiIGZpbGw9IiMxQjFFMzgiIGZpbGwtcnVsZT0ibm9uemVybyIgb3BhY2l0eT0iLjI1Ii8+PC9zdmc+);
  height: 16px;
  width: 8px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  margin-left: 6px;
}

.part-list {
  font-family: "Inter", serif !important;
  font-weight: 600 !important;
}

.part-list .b-table-sticky-header {
  width: auto;
  padding-right: 10px;
}

.part-list .table-width-2 {
  width: 1310px !important;
}

.part-list .table-width-3 {
  width: 1360px !important;
}

.part-list .table-width-4 {
  width: 1400px !important;
}

.part-list .part-list-total {

}

.subheader-p-l {
  padding-left: 150px !important;
  height: 42px;
  border-right: 1px solid rgba(27, 30, 56, 0.25);
}

.subheader-p-l-b {
  padding-left: 950px !important;
  height: 42px;
  border-right: 1px solid rgba(27, 30, 56, 0.25);
  border-bottom: none !important;
  position: relative;
  white-space: nowrap;
}

.part-list-tab-supplier .part-list .table-hover-none tr:hover {
  background-color: #ffffff00 !important;
}

.sticky {
  position: fixed;
  top: 250px;
}

.part-list .h-totals {
  font-size: 14px !important;
  font-weight: bold !important;
}

.part-list .part-values {
  color: #85868e;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.part-list .table th {
  padding: 15px 5px !important;
}

.part-list table {
  width: 1300px !important;
}

.part-list .part-list-item-gst {
  width: 100%;
  word-break: break-all;
  padding-top: 10px;
}

.part-list .part-list-item-total {
  word-break: break-all;
  width: 100%;
  padding-top: 10px;
}

.part-list .part-list-item-in-stock {
  width: 100%;
  min-width: 140px;
}

.supplier-rfq-view-page .import-dms-additional-block {
  justify-content: center;
}

.supplier-rfq-view-page .dropdown-options .import-dms-option a {
  height: auto !important;
}

.supplier-rfq-view-page .part-list {
  margin-top: 15px;
  margin-left: 15px;
  overflow-x: scroll;
}

.supplier-rfq-view-page .part-list .table {
  width: auto;
}

.supplier-rfq-view-page .part-list .table .subheader {
  opacity: 1;
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.supplier-rfq-view-page .part-list .table .subheader-p-c {
  text-align: right;
  padding-right: 30px;
  height: 42px;
  border: 1px solid rgba(27, 30, 56, 0.1);
}

.supplier-rfq-view-page .part-list .table .subheader-t {
  text-align: right;
  padding-right: 98px;
  height: 57px;
  border: 1px solid rgba(27, 30, 56, 0.1);
  position: relative;
}

.supplier-rfq-view-page .part-list .table .p-c-value {
  color: #1C1F39;;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.supplier-rfq-view-page .part-list .table .totals-value {
  color: #1C1F39;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}


.supplier-rfq-view-page .part-list th.part-list-number {
  width: 119px;
}

.supplier-rfq-view-page .part-list th.part-list-rrp {
  width: 70px;
}

.supplier-rfq-view-page .part-list th.part-list-in-stock,
.supplier-rfq-view-page .part-list td.part-list-in-stock {
  width: 155px;
}

.supplier-rfq-view-page .part-list .stock-select-header {
  position: absolute;
  bottom: 2px;
  width: 145px;
}

.supplier-rfq-view-page .part-list th.part-list-part-name,
.supplier-rfq-view-page .part-list td.part-list-part-name {
  min-width: 300px;
}

.supplier-rfq-view-page .part-list td.part-list-part-name {
  padding: 0px 0px 0px 5px;
}

.supplier-rfq-view-page .part-list td.part-list-part-name:hover {
  cursor: context-menu;
}

.supplier-rfq-view-page .part-list th.part-list-qty,
.supplier-rfq-view-page .part-list td.part-list-qty {
  width: 20px;
  padding-top: 7px;
}

.supplier-rfq-view-page .part-list .part-list-company-price-other {
  width: 60px;
}

.supplier-rfq-view-page .part-list .part-list-company-price {
  width: 100px;
}

.supplier-rfq-view-page .part-list .part-list-rrp {
  width: 100px;
  min-width: 70px;
}

.supplier-rfq-view-page .part-list .part-list-item-company-price {
  width: 100%;
  word-break: break-all;
  min-width: 75px;
}

.supplier-rfq-view-page .part-list .part-list-item-rrp {
  width: 100%;
  min-width: 75px;
}

.supplier-rfq-view-page .part-list th.part-list-supplier,
.supplier-rfq-view-page .part-list td.part-list-supplier {
  width: 135px;
}

.supplier-rfq-view-page .part-list th.part-list-gst,
.supplier-rfq-view-page .part-list td.part-list-gst {
  width: 70px;
}

.supplier-rfq-view-page .part-list th.part-list-total,
.supplier-rfq-view-page .part-list td.part-list-total {
  width: 80px;
}

.supplier-rfq-view-page .part-list td div.color-black {
  color: #1C1F39;
}

.supplier-rfq-view-page .part-list td div.part-list-item-name {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;

  /* prevent text highlighting when right click */
  -youbkit-touch-callout: none; /* iOS Safari */
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
}

.supplier-rfq-view-page .part-list .part-list-item-name.has-link span {
  opacity: 0.5;
  color: #1C1F39;
}

.supplier-rfq-view-page .part-list .part-list-item-name.has-link {
  padding-left: 15px;
}

.supplier-rfq-view-page .part-list .part-list-part-name__icon-group {
  margin: 0;
  display: flex;
  align-items: center;
}

.supplier-rfq-view-page .part-list .part-list-part-name__icon {
  font-size: 24px;
  color: grey;
  margin-right: 10px;
}

.supplier-rfq-view-page .part-list .part-list-part-name__icon--comment {
  /* color: #E5A025; */
  font-size: 24px;
}

.supplier-rfq-view-page .part-list th.part-list-number,
.supplier-rfq-view-page .part-list td.part-list-number {
  min-width: 190px;
}

.supplier-rfq-view-page .part-list td div.part-list-item-number {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.supplier-rfq-view-page .part-list td div.color-gray {
  color: #85868e;
}

.supplier-rfq-view-page .part-list td div.part-list-item-qty {
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.supplier-rfq-view-page .part-list td div.part-list-item-price {
  opacity: 0.5;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.multiselect__select:before {
  font-size: 23px;
}

.multiselect__option {
  height: 40px;
}

.v-context {
  width: auto !important;
  border-radius: 5px;
}

/* Drag drop */
.handle {
  cursor: move;
}

.table tbody + tbody {
  border-top-width: 1px;
}

/* end drag drop */

.V3 .supplier-rfq-view-page .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}

.rfq-view-body .datetime-field-value {
  width: 25%;
}

.rfq-view-body .bx-calendar-week {
  padding: 0.8rem 0.75rem;
  position: absolute;
  right: 0;
  z-index: 1;
}

.V3 .supplier-rfq-view-page .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}

.status-multiselect {
  width: 130px;
  margin: 0 auto;
  margin-top: 5px;
}

.supplier-rfq-view-page .part-list .supplier-name {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  min-height: 54px;
  margin-bottom: 10px;
}

.supplier-rfq-view-page .part-list .supplier-type {
  color: #1C1F39;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.green-cell {
  color: #0cb458 !important;
}

.red-cell {
  color: red !important;
}

.part-list .part-values {
  position: relative;
}

.additional-part-list--block-thead {
  position: relative;
  z-index: 99;
}

.supplier-rfq-view-page .part-list .bx-image-alt {
  font-size: 24px;
  margin-right: 10px;
}

.line-border-bottom {
  border-bottom: 1px solid rgba(27, 30, 56, 0.5);
  position: absolute;
  width: 150px;
  bottom: 0px;
  right: 0;
}

.line-border-bottom-value {
  border-bottom: 1px solid #c6c6cd;
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0;
}

.line-border-bottom-value-btn {
  bottom: -1px;
}

.part-list-color-black,
.part-list-item-company-price-other {
  color: #1C1F39;
}

.part-number-stock-orange {
  color: #F59345 !important;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.areaTouch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#context-loader {
  position: absolute;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

#context-loader svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

#context-loader svg .context-loader-background {
  fill: none;
  stroke: #e2eff0;
  stroke-width: 1.8;
}

#context-loader .context-loader-progress {
  fill: none;
  stroke: #1b1e3812;
  stroke: #5e79ff;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: 1.8;
  transition: all 5s ease-in-out;
}

.context-loader-progress.active {
  animation: dashOffset 1s forwards;
}

#context-menu-iphone {
  position: absolute;
  background: #fff;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  border: 1px solid #bdbdbd;
  z-index: 100;
  min-width: 130px;
}

#context-menu-iphone li {
  display: flex;
  text-decoration: none;
  text-align: left;
  padding: 15px 15px 11px 15px;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 130px;
}

.context-menu-iphone-btn .button-add-photo {
  width: 100%;
  border: none;
  background: white;
  display: flex;
  text-decoration: none;
  text-align: left;
  padding: 0;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  min-width: 130px;
}

#context-menu-iphone li:hover,
#context-menu-iphone li .button-add-photo:hover {
  background-color: rgba(46, 169, 255, 0.1) !important;
  color: #79B1BB;
  font-weight: 600;
}

#context-menu-iphone li .button-add-photo:hover {
  background-color: #ebf6ff !important;
  color: #79B1BB;
  font-weight: 600;
}

.part-number-stock-red {
  color: red;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}


.part-list-company-color {
  height: 5px;
  width: 80%;
  background: #083d77;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 9px;
}
</style>

<style>
.ghost-items {
  /*opacity:0.2;*/
}

.part-list-tab-supplier .part-list input.form-control:disabled,
.part-list-tab-supplier .disabled-stock-select,
.part-list-tab-supplier .disabled-add-button,
.part-list-tab-supplier .disabled-picker-icon {
  opacity: 0.7 !important;
  cursor: default !important;
}

.part-list-tab-supplier .disabled-stock-select .stock-select__selected:hover {
  cursor: default !important;
}

tr.handle:hover,
tr.handle:hover td,
tr.handle div.td-wrapper:hover {
  cursor: move !important;
}

.disabled-drag-area tr.handle:hover,
.disabled-drag-area tr.handle:hover td,
.disabled-drag-area tr.handle div.td-wrapper:hover {
  cursor: default !important;
}

.part-list-tab-supplier .part-list .table tr {
  background: none;
}

.part-list-tab-supplier .part-list .table th,
.part-list-tab-supplier .part-list .table td {
  border-top: none;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
  background: none;
}

.part-list-tab-supplier .part-list .table tbody tr:hover {
  background-color: #e9f8f9;
}

/*@-moz-document url-prefix() {*/
/*    .part-list-tab-supplier .part-list .table th,*/
/*    .part-list-tab-supplier .part-list .table td {*/
/*        border-top: none;*/
/*        border-bottom: 2px solid rgba(27, 30, 56, 0.25);*/
/*    }*/
/*}*/

.modal-add-part-photo .modal-dropzone-add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  cursor: pointer;
  margin-bottom: 30px;
}

.modal-add-part-photo .modal-dropzone-add-photo i {
  font-size: 40px;
  margin-bottom: 0 !important;
}

.modal-custom-add-part-photo .modal-title,
.modal-custom-view-part-photo .modal-title,
.modal-process-direct-order-check-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-custom-add-part-photo,
.modal-custom-view-part-photo,
.modal-process-direct-order-check-modal {
  max-width: 500px !important;
  margin: 15rem auto !important;
}

.modal-custom-view-part-photo {
  max-width: 700px !important;
}

.mark-as-returned-text-message {
  height: 100px !important;
}

.modal-custom-add-part-photo .modal-body,
.modal-custom-view-part-photo .modal-body,
.modal-process-direct-order-check-modal .modal-body {
  padding-bottom: 0;
}

.modal-custom-add-part-photo .modal-footer,
.modal-custom-view-part-photo .modal-footer,
.modal-process-direct-order-check-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-custom-add-part-photo .modal-header,
.modal-custom-view-part-photo .modal-header,
.modal-process-direct-order-check-modal .modal-header {
  background-color: #79B1BB;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-custom-add-part-photo .modal-content,
.modal-custom-view-part-photo .modal-content,
.modal-process-direct-order-check-modal .modal-content {
  border-radius: 3px !important;
}

.modal-custom-add-part-photo .input-btn,
.modal-custom-view-part-photo .input-btn,
.modal-process-direct-order-check-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.modal-custom-add-part-photo .cancel,
.modal-custom-view-part-photo .cancel,
.modal-process-direct-order-check-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-custom-add-part-photo .input-btn:focus,
.modal-custom-view-part-photo .input-btn:focus,
.modal-process-direct-order-check-modal .input-btn:focus {
  box-shadow: none !important;
}

.modal-custom-add-part-photo .dropzone .dz-preview {
  width: 120px;
  height: 120px;
}

.modal-custom-add-part-photo .mark-as-returned-photo-img {
  width: 120px;
  height: 120px;
  margin: 0 10px 0 10px;
}

.modal-custom-add-part-photo .mark-as-returned-signature-photo-img-block {
  height: 148px;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-custom-add-part-photo .dropify-wrapper .dz-message,
.modal-custom-add-part-photo .dropify-message {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
}

.modal-custom-add-part-photo .dropify-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-custom-add-part-photo .dropzone .dz-preview .dz-remove {
  margin-left: 7px;
  bottom: 7px;
}

.modal-custom-add-part-photo .dropzone .dz-preview .dz-details {
  padding: 1em 1em;
}

.modal-custom-add-part-photo .dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 0.5rem
}

.modal-custom-add-part-photo .dropzone-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
