import { render, staticRenderFns } from "./part-list.vue?vue&type=template&id=24a8eb0a&scoped=true"
import script from "./part-list.vue?vue&type=script&lang=js"
export * from "./part-list.vue?vue&type=script&lang=js"
import style0 from "./part-list.vue?vue&type=style&index=0&id=24a8eb0a&prod&lang=css"
import style1 from "./part-list.vue?vue&type=style&index=1&id=24a8eb0a&prod&scoped=true&lang=css"
import style2 from "./part-list.vue?vue&type=style&index=2&id=24a8eb0a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a8eb0a",
  null
  
)

export default component.exports