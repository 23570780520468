/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    id: 0,
    name: '',
    timezone: null,
    businessName: '',
    abn: '',
    acn: '',
    phone: '',
    fax: '',
    displayId: null,
    isCCNSW: false,
    supplierTypes: [],
    email: '',
    emailOrders: '',
    emailCredits: '',
    about: '',
    supplierType: '',
    logoImgPath: '',
    settings: {},
    address: {
        address: '',
        unitLevelLot: '',
        street: '',
        suburb: '',
        postcode: '',
        state: '',
        country: ''
    },
    primaryBusinessAddress: {
        address: '',
        unitLevelLot: '',
        street: '',
        suburb: '',
        postcode: '',
        state: '',
        country: ''
    },
    locations: [],
    contacts: {
        inVisible: [],
        visible: []
    },
    isLoadedInfo: false,
    brandingEnabled: false,
    supplierVehiclePartsCategory: 'CAR',
};


export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
