<script>
/* eslint-disable */
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import QuotePricingProgress from '@/components/rfq/QuotePricingProgress'
import RfqTimer from './rfq-view/rfq-timer'
import ImageLightbox from '@/components/image-lightbox/image-lightbox'
import ImageLightboxWithoutGallery from './rfq-view/image-lightbox'
import ImageGallery from './rfq-view/image-gallery'
import Axios from 'axios'
import _ from 'lodash'
import RFQ from '../../../components/rfq/models/RFQ'
import Part from '../../../components/rfq/models/Part'
import PartListView from './rfq-view/part-list'
import vinDate from '../../../components/VinDate'
import PdfViewer from '../../../components/utility/pdf-viewer'
import CustomerAccountDeliver from '../../../components/CustomerAccountDeliver'
import { isMobileOnly } from 'mobile-device-detect'
import FileUpload from './rfq-view/file-upload'
import { mapActions, mapGetters } from 'vuex'
import NProgress from 'nprogress'
import DatePicker from '../../../components/datePicker'
import RfqImport from './rfq-view/rfq-import-quote-pdf-2'
import VueCountdown from '@chenfengyuan/vue-countdown';
import * as dayjs from 'dayjs'
import Vue from 'vue'
import { uuid } from 'vue-uuid'
import 'vue-toastification/dist/index.css'
import imageGallery from '../../../components/image-lightbox/image-gallery'
import ToastTemplate from '../../../components/toastTemplate'
import AssignTo from '../../../components/assign-to'
import imageEditor from '../../../components/image-lightbox/image-editor'
import moment from 'moment'
import StickyHeader from '../rfq/rfq-view/rfq-view-sticky-header'
import Chat from '../../../components/utility/chat/chat'
import axios from "axios"
import {subscribeToRfqChannel} from "@/centrifuge"
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import WindowNotification from "@/components/utility/window-notification.vue";
import BestPriceCountdown from '@/components/rfq/best-price-countdown.vue'

let handleOutsideClick
Vue.directive('closable2', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          let excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl) {
            if (excludedEl._isVue) {
              excludedEl = excludedEl.$el
            }
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })
      // console.log(e.target)
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

export default {
  name: 'supplier-rfq-view',
  data: function () {
    return {
      timestampInterval: null,
      isShowPdfNR: false,
      selectedNRPDFUrl: null,
      customToast: null,
      toastContent: {},
      isShowedCancelNotice: false,
      activeSupplierId: null,
      clickedSupplierId: null,
      activeColumnIndex: null,
      toastOptions: {
        toastClassName: 'my-custom-toast-class-2',
        timeout: false,
        closeButton: false,
        icon: false,
      },
      isMobile: false,
      usersFilter: '',
      declineReason: '',
      isAdditionalRfq: false,
      callComputed: 0,
      currentlyViewing: [],
      isClickRemoveQuote: false,
      scheduleDateTime: null,
      showPlaceholderPaintCode: true,
      priceResubmitActiveFlag: false,
      isPaintCodeInFocus: false,
      widthStyle: {},
      myAccountDropzoneOptions: {
        headers: { Authorization: localStorage.getItem('token') || sessionStorage.getItem('token') },
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/supplier/rfq/upload-scheme',
        thumbnailWidth: null,
        thumbnailHeight: 320,
        method: 'post',
        maxFilesize: 50,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig:
            'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      uploadedFileId: null,
      images: [],
      isDropdown2Visible: false,
      isOldCompactSideBar: null,
      pinImage: null,
      isAlreadyAutoClickedPinImage: false,
      activeTab: null,
      isEditPartList: false,
      isShowStickyHeader: false,
      isShowDirectComment: false,
      isEditVehicleDetails: false,
      searchFilter: '',
      classTabContent: 'tab-content',
      isHiddenOptions: true,
      shownGallery: false,
      galleryCurrentIndex: 0,
      steps: [
        { label: 'New Request for Quote', },
        { label: 'Priced', },
        { label: 'Lost', },
        { label: 'Won', },
        { label: 'Parts Ordered', },
      ],
      isSelectedImportDMS: false,
      isSelectedImportCSV: false,
      isLoadPartsForImporting: false,
      isStartImportingParts: false,
      isLoading: false,
      isQuoteNbrFocus: false,
      isQuoteNbrFocus2: false,
      loadedPDFFiles: [],
      isShowAssignedToContext: false,
      isNeedSavePaintCode: false,
      isShowScheduleContext: false,
      isSchedulePickerClicked: false,
      schedulePickerTag: '',
      isClaimNumberFieldFocused: false,
      isImportingQuoteParts: false,
      isShowDiagram: false,
      isShowedDiagramModal: false,
      rfq: { //TODO remove after testing
        rfqUpdateRequested: '',
        rfqUpdateRequestedOn: '',
        lock: false,
        scheduleTime: null,
        previousNumber: null,
        id: null,
        supplierBusinessName: '',
        claimNumber: '',
        QPNumber: '',
        isRebateEnabled: false,
        rebatePercent: null,
        type: 'Manual',
        secondRequestBestPrice: null,
        secondRequestBestPriceTimeEnd: null,
        thirdRequestBestPrice: null,
        thirdRequestBestPriceTimeEnd: null,

        number: '',
        comments: '',
        undoAvailable: false,
        estimator: '',
        vehicle: {
          rego: '',
          vin: '',
          make: '',
          model: '',
          series: '',
          dom: '',
          colour: '',
          transmission: 'Automatic',
          odometer: '',
        },
        supplierId: 0,
        repairer: '',
        statusId: 1,
        status: '',
        supplierQuoteNumber: '',
        repairerQuoteNumber: '',
        parts: [],
        quoteValidTill: null,
        images: [],
        files: [],
        suppliers: [],
        partsToSuppliers: {},
        partsType: '',
        dueCountdown: 0,
        dmsQuoteNumber: 0,
        paintCodeForRepair: '',
        directOrder: {
          comment: '',
        },
        properties: {
          isAskForPaintCode: false,
          isAskForPartsDiagram: false,
          isSentAsDirectOrder: false,
          isCanOpenChatForDirectOrder: false,
          isAvailableToUpdate: false,
          isInEditMode: false,
          isNonReturnable: false,
          importQuoteOptions: {
            importCheck: false,
            importCsvQuote: false,
            importCheckCsv: false,
            importPdfQuote: false
          },
          isEnableNillStock: false,
          isEnableNilSupply: false,
        }
      },
      importedParts: {},
      imageIds: [],
      selectedSchemeIndex: -1,
      schemeImage: null,
      isClosedPreviewImage: false,
      interval: null,
      interval2: null,
      interval3: null,
      interval4: null,
      interval5: null,
      isLoadedRFQ: false,
      offers: [],
      isMounted: false,
      _chat: null,
      chatMessages: [],
      isShowPdf: false,
      documentHeight: 0,
      documentWidth: 0,
      onlineStatusIntervalId: null,
      chatRepairerIsOnline: false,
      chatTypers: {},
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
      currentRFQ: 'supplier/rfq/getCurrentRFQ',
      quoteNumbers: 'supplier/rfq/getQuoteNbrs',
      currentUser: 'currentUser/getCurrentUserInfo',
      getterQuoteNumbers: 'supplier/rfq/getQuoteNbrs',
      getterQuoteNumbersX: 'supplier/rfq/getQuoteNbrsX',
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
      getterAllUsers: 'users/getAllUsersForAssignedTo',
      getterMultipleBrandIds: 'currentUser/getMultipleBrandIds',
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
      isAdministrator: 'currentUser/isAdministrator',
      isHasOEMGEN: 'currentCompany/isHasOEMGEN',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    isDirectOrder () {
      return this.rfq?.properties?.isSentAsDirectOrder
    },
    isCanOpenChatForDirectOrder () {
      return this.rfq?.properties?.isCanOpenChatForDirectOrder
    },
    isHasPreviousNbrs () {
      return !_.isEmpty(this.rfq.supplierPreviousQuoteNumbers)
    },
    isPartsTypeOemGen () {
      return this.rfq.partsType === 'OEM-GEN'
    },
    isPartsTypeUsed () {
      return this.rfq.partsType === 'USED'
    },
    isActiveSendNillStock () {
      if (this.rfq?.properties?.isEnableNillStock === false) {
        return false
      }
      let isHasNoStockPart = _.some(this.rfq.parts, (prt) => {
        return prt.stock == 3
      })
      let isAllPartsNoneStock = _.filter(this.rfq.parts, (prt) => {
        return prt.stock == 0
      }).length === this.rfq.parts.length && this.rfq.parts.length !== 0
      return (isHasNoStockPart || isAllPartsNoneStock) && this.rfq?.properties?.isEnableNillStock === true
    },
    isHasSchemeImage () {
      return !!_.find(this.rfq.files, (f) => {
        return f.type === 'scheme'
      }) && this.isCanShowSchemeImage
    },
    isCanShowSchemeImage () {
      return this.isRFQNew || this.isRFQPriced
    },
    computedSchemeImages () {
      return _.filter(this.rfq.files, (f) => {
        return f.type === 'scheme' && this.isCanShowSchemeImage
      })
    },
    isRfqToOemGen () {
      return this.rfq.partsType === 'OEM-GEN'
    },
    isRfqToUsed () {
      return this.rfq.partsType === 'USED'
    },
    computedUpdatedQuoteNbr () {
      return _.find(this.getterQuoteNumbersX?.[this.companyInfo?.id], (itm) => {
        return itm.number == this.rfq.supplierQuoteNumber
      })
    },
    computedSteps () {
      if (this.rfq.status === 'Closed') {
        let r = _.filter(this.steps, (v) => {
          return v.label !== 'Parts Ordered'
        })
        r.push({
          label: 'Closed Quote'
        })
        return r
      } else if (this?.rfq?.properties?.isSentAsDirectOrder && this.rfq.status === 'Decline') {
        let r = _.filter(this.steps, (v) => {
          return v.label !== 'Parts Ordered'
        })
        r.push({
          label: 'Declined Direct Order'
        })
        return r
      } else {
        return this.steps
      }
    },
    isAutoConfirm () {
      return !_.isEmpty(this.loadedPDFFiles) || (!this.isEnableSettingsRfqImportPdfShowModal && this.isSelectedImportDMS) || (!this.isEnableSettingsRfqImportCsvShowModal && this.isSelectedImportCSV)
    },
    isEnableSettingsRfqImportPdfQuote () {
      return this.rfq?.properties?.importQuoteOptions?.importPdfQuote === true
    },
    isEnableSettingsRfqImportPdfShowModal () {
      return this.rfq?.properties?.importQuoteOptions?.importPdfQuote === true && this.rfq?.properties?.importQuoteOptions?.importCheck === true
    },
    isEnableSettingsRfqImportCsvShowModal () {
      return this.rfq?.properties?.importQuoteOptions?.importPdfQuote === true && this.rfq?.properties?.importQuoteOptions?.importCsvQuote === true && this.rfq?.properties?.importQuoteOptions?.importCheckCsv === true
    },
    isEnableSettingsRfqImportCsvQuote () {
      return this.rfq?.properties?.importQuoteOptions?.importCsvQuote === true
    },
    computedLockedName () {
      return this.rfq?.lock?.lockedName
    },
    computedAvailableUsers () {
      let users = _.filter(_.cloneDeep(this.getterAllUsers), (u) => {
        return u?.isActive
      })
      if (this.isMultipleLocations) {
        return _.filter(_.cloneDeep(users), (u) => {
          return u?.businessLocation?.includes(String(this.supplierId))
        })
      }
      return users
    },
    computedUsers () {
      let data = this.computedAvailableUsers.slice()
      let currUser = _.find(data, (user) => {
        return Number(user.id) === Number(this.currentUser.id)
      })
      if (currUser) {
        currUser.name = 'Me'
        data = _.filter(data, (itm) => {
          return Number(itm.id) !== Number(currUser.id)
        })
        data.unshift({
          name: 'Me',
          id: this.computedCurrentUser.id,
          isActive: this.computedCurrentUser.isActive,
          businessLocation: this.computedCurrentUser.businessLocation
        })
      }
      return data
    },
    computedCurrentUser () {
      return _.find(this.getterAllUsers, (u) => {
        return Number(u.id) === Number(this.currentUser.id)
      })
    },
    computedUsersForAssigned () {
      let data = this.computedAvailableUsers.slice()
      let currUser = _.find(data, (user) => {
        return Number(user.id) === Number(this.currentUser.id)
      })
      data.unshift({ name: 'None', id: null })
      if (currUser) {
        currUser.name = 'Me'
        data = _.filter(data, (itm) => {
          return Number(itm.id) !== Number(currUser.id)
        })
        data.unshift({
          name: 'Me',
          id: this.computedCurrentUser.id,
          isActive: this.computedCurrentUser.isActive,
          businessLocation: this.computedCurrentUser.businessLocation
        })
      }
      // if (data) {
      // }
      return data
    },
    computedUsersForAssignedFiltered () {
      let users = []
      if (!this.rfq?.assignedTo) {
        users = this.computedUsers.slice()
      } else {
        users = _.filter(this.computedUsersForAssigned, (user) => {
          return Number(user.id) !== Number(this.rfq?.assignedTo?.id)
        })
      }
      return _.filter(users, (u) => {
        return _.includes(_.trim(_.toLower(u.name)), _.trim(_.toLower(this.usersFilter)))
      })
    },
    computedLockedByName () {
      return this.rfq?.lock?.lockedName
    },
    computedLockedText () {
      return this.getterSettings?.rfq?.quoteAssignmentAutoLock?.autoLockMessage
    },
    isCanLock () {
      return !this.rfq?.lock?.active && this.isRFQNew && !this?.getterSettings?.rfq?.quoteAssignmentAutoLock?.quoteAutoLock
    },
    isCanUnLock () {
      return this.rfq?.lock?.active && this.isRFQNew && (Number(this.rfq?.lock?.lockedBy) === Number(this.currentUser?.id) || this.isAdministrator) && !this?.getterSettings?.rfq?.quoteAssignmentAutoLock?.quoteAutoLock
    },
    isShowLockedTo () {
      return this.rfq?.lock?.active && this.isRFQNew
    },
    isLockedRfq () {
      return this.rfq?.lock?.active && this.isRFQNew && Number(this.rfq?.lock?.lockedBy) !== Number(this.currentUser?.id)
    },
    computedFilesAccepted () {
      let result = 'application/pdf'
      if (this.isPartsTypeOemGen && (this.isRFQPriced || this.isRFQNew)) {
        result += ', image/jpeg, image/jpg, image/png'
      }
      return result
    },
    computedCustomCountForTab () {
      let counts = {
        'part-list': 0,
        images: 0,
        files: 0,
      }
      counts['part-list'] = this.rfq?.parts?.length
      counts.images = this.rfq?.images?.length
      counts.files = this.rfq?.files?.length
      return counts
    },
    supplierId () {
      this.callComputed++
      let supplierId = Number(this.rfq?.supplierId)
      console.log('supplierId', this.callComputed, supplierId)
      return supplierId
    },
    quoteNumbers () {
      this.callComputed++
      let quoteNumbers = this.getterQuoteNumbers[this.supplierId]
      console.log('quoteNumbers', _.cloneDeep(this.callComputed), _.cloneDeep(this.supplierId), _.cloneDeep(quoteNumbers), _.cloneDeep(this.getterQuoteNumbers))
      const { [this.supplierId]: nnn } = this.getterQuoteNumbers
      console.log('nnn', nnn)
      return quoteNumbers
    },
    quoteNumbersFiltered () {
      this.callComputed++
      let quoteNumber = this.rfq.supplierQuoteNumber ? this.rfq.supplierQuoteNumber : ''
      if (quoteNumber) {
        quoteNumber = _.toLower(quoteNumber)
      }

      let result = _.filter(this.quoteNumbers, (nbr) => {
        return _.includes(_.toLower(nbr), quoteNumber)
      })
      console.log('quoteNbrs', this.rfq.supplierId, this.getterQuoteNumbers, this.quoteNumbers, result)
      result = result.slice(-10)
      result = _.orderBy(result, (n) => {
        return _.toLower(n).trim()
      }, ['asc'])
      result = _.uniq(result)

      return result
    },
    isFlashingPaintCode () {
      if (!this.isRFQNew && !this.isRFQPriced) {
        return false
      }
      if (this.rfq && this.rfq.paintCodeForRepair && this.rfq.paintCodeForRepair.length > 0 && !this.showPlaceholderPaintCode) {
        return false
      }
      return true
    },
    computedImportedParts () {
      return this.importedParts
    },
    isCanEditPricedQuote () {
      return !!(this.isRFQPriced && this.rfq.properties.isAvailableToUpdate)
    },
    isGotUpdate () {
      return !!(this.isRFQPriced && this.rfq.rfqUpdateRequested === 'UpdateRequested')
    },
    isSentUpdate () {
      return !!(this.isRFQPriced && this.rfq.rfqUpdateRequested === 'UpdatedPricesSent')
    },
    deliveryTo () {
      let result = ''
      if (!_.isEmpty(this.rfq.order) && !_.isEmpty(this.rfq.order.deliveryTo)) {
        result = this.rfq.order.deliveryTo
      } else if (!_.isEmpty(this.rfq.deliveryAddress)) {
        result = this.rfq.deliveryAddress
      } else if (!_.isEmpty(this.rfq.location)) {
        result = this.rfq.location
      }
      return result
    },
    viewRfqsTableLink () {
      if (this.$route?.params?.tab && this.$route.params.tab === 'All') {
        return '/s/rfqs#all'
      }
      let link = '/s/rfqs#'
      link = link + _.toLower(this.rfq.status)
      return link
    },
    isPinImage: function () {
      if (this.pinImage && (this.isCurrentTabVehicleDetails || this.isCurrentTabPartList || this.isCurrentTabFiles) && !this.isMobile) {
        return true
      }
      return false
    },
    imageSource: function () {
      return this.rfq.images
    },
    isEditable: function () {
      if (this.isEditableVehicleDetails || this.isEditablePartList) {
        return true
      }
      return false
    },
    isEditablePartList: function () {
      if (this.isEditPartList) {
        return true
      }
      return false
    },
    isEditableVehicleDetails: function () {
      if (this.isEditVehicleDetails) {
        return true
      }
      return false
    },
    isCurrentTabVehicleDetails: function () {
      if (this.$route.meta.currentTab == 'Vehicle Details' || this.activeTab == 'Vehicle Details') {
        return true
      }
      return false
    },
    isCurrentTabPartList: function () {
      if (this.$route.meta.currentTab == 'Part List' || this.activeTab == 'Part List') {
        return true
      }
      return false
    },
    isCurrentTabFiles: function () {
      if (this.$route.meta.currentTab == 'Files' || this.activeTab == 'Files') {
        return true
      }
      return false
    },
    isRFQOrdered: function () {
      return this.rfq.status === 'Ordered'
    },
    isRFQCancelled: function () {
      return this.rfq.status === 'Closed'
    },
    isRFQClosed: function () {
      return this.rfq.status === 'Closed'
    },
    isRFQDeclined: function () {
      return this.rfq.status === 'Decline'
    },
    isRFQNew: function () {
      return this.rfq.status === 'New'
    },

    isRFQPriced: function () {
      return this.rfq.status === 'Priced'
    },
    isRFQLost: function () {
      return this.rfq.status === 'Lost'
    },
    isRFQWon: function () {
      return this.rfq.status === 'Won'
    },
    computedDueDate: function () {
      return this.rfq.dueCountdown
    },
    currentQPPStep: function () {
      if (this.rfq.statusId > 0) {
        return this.rfq.statusId
      }
      return 1
    },
    stepCanEdit () {
      switch (this.currentQPPStep) {
          // add more step that can't edit here
        case 1:
          return false
        default:
          return true
      }
    },
    pageTitle () {
      let r = 'Edit Quote'
      if (!_.isEmpty(_.trim(this.rfq.number))) {
        r += ` - ${this.rfq.number}`
      }
      if (!_.isEmpty(_.trim(this.rfq.QPNumber))) {
        r += ` | ${this.rfq.QPNumber}`
      }
      if (!_.isEmpty(_.trim(this.rfq.vehicle.make)) || !_.isEmpty(_.trim(this.rfq.vehicle.model))) {
        r += ' |'
        if (!_.isEmpty(_.trim(this.rfq.vehicle.make))) {
          r += ` ${this.rfq.vehicle.make}`
        }
        if (!_.isEmpty(_.trim(this.rfq.vehicle.model))) {
          r += ` ${this.rfq.vehicle.model}`
        }
      }

      return r
    },
    mobileOptionsClass () {
      const titleLength = this.pageTitle.length
      if (
          titleLength >= 27 && titleLength <= 38 ||
          titleLength >= 62 && titleLength <= 69
      ) {
        return 'top-15'
      }
    },
    unviewedMessagesIds () {
      let ids = []
      for (let m of this.chatMessages) {
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, m.companyId)

          if (m.messageId && !m.viewed && isNotMyMessage) {
            ids.push(m.messageId)
          }
        } else {
          if (m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
            ids.push(m.messageId)
          }
        }
      }
      return ids
    },
  },
  methods: {
    ...mapActions({
      getRFQById: 'supplier/rfq/getRFQById'
    }),
    isUnLockedSupplierMain() {
      return this.isRFQPriced && (this.rfq?.thirdRequestBestPrice === true || this.rfq?.secondRequestBestPrice === true)
    },
    endCountdown () {
      this.loadRFQ(this.rfq.id)
    },
    selectedPdfNr(v) {
      this.selectedNRPDFUrl = v;
      // this.selectedNRPDFUrl = v + '?at=' + localStorage.getItem('token');
      this.isShowPdfNR = true
    },
    redirectToRfqView(itm) {
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    openPdf (v) {
      this.isShowPdf = v
      this.handleResize()
    },
    handleResize () {
      this.documentHeight = window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 14

      this.documentWidth = window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth
    },
    isShowChat () {
      return (!_.isEmpty(this.chatMessages) ||
          (this.isHasOEMGEN && (this.isHasSchemeImage || (this.isPartsTypeOemGen && !(this.isRFQNew || this.isRFQPriced || this.isRFQLost))))
          || (this.isDirectOrder && this.isCanOpenChatForDirectOrder))
    },
    onCloseChat () {
      if (this.unviewedMessagesIds.length > 0) {
        this.markMessagesAsViewed(this.unviewedMessagesIds)
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    changedClickedSupplierId (v) {
      this.$refs.partLV.changedClickedSupplierId(v)
      this.clickedSupplierId = v
    },
    changedActiveColumnIndex (v) {
      this.$refs.partLV.changedActiveColumnIndex(v)
      this.activeColumnIndex = v
    },
    changedActiveSupplierId (v) {
      this.$refs.partLV.changedActiveSupplierId(v)
      this.activeSupplierId = v
    },
    changedClickedSupplierId2 (v) {
      this.clickedSupplierId = v
    },
    changedActiveColumnIndex2 (v) {
      this.activeColumnIndex = v
    },
    changedActiveSupplierId2 (v) {
      this.activeSupplierId = v
    },
    onScroll () {
      if (this.activeTab !== 'Part List') {
        return
      }
      let element = document.getElementById('additional-info')
      if (!element) {
        return
      }
      let rect = element.getBoundingClientRect()
      // console.log('rect', rect?.top)
      if (rect.top <= 150) {

        this.isShowStickyHeader = true

        this.onTableScroll()
      } else {
        this.isShowStickyHeader = false
      }
      this.setDirectCommentBlockVisible()
    },
    getDirectBlockWidth () {
      let el = document.getElementById('supplier-rfq-view-table')
      let el2 = document.getElementsByClassName('site-sidebar')[0]
      if (!el || !el2) {
        return
      }
      let place = window.innerWidth - el.clientWidth - el2.clientWidth
      if (place >= 270) {
        if (place <= 410) {
          return (place - 30) + 'px'
        } else {
          return '380px'
        }
      }
      return '0px'
    },

    setDirectCommentBlockVisible () {
      let el = document.getElementById('supplier-rfq-view-table')
      let el2 = document.getElementsByClassName('site-sidebar')[0]
      if (!el || !el2) {
        return
      }
      let place = window.innerWidth - el.clientWidth - el2.clientWidth
      if (place >= 270) {
        this.isShowDirectComment = true
      } else {
        this.isShowDirectComment = false
      }
    },
    onTableScroll () {
      this.$nextTick(() => {
        let el = document.getElementsByClassName('b-table-sticky-header')[0]
        let el2 = document.getElementById('supplier-rfq-view-thead-wrapper')

        if (el2 && el) {
          el2.scrollLeft = el.scrollLeft
        }
      })
    },
    selectedAllStock (v) {
      this.$refs.partLV.selectedAllStock(v)
    },
    filterParts (data) {
      return _.filter(data, (itm) => {
        return itm.stock != 7 && !itm.isAdditional
      })
    },
    saveClaimNbr () {
      NProgress.start()
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, {
        claimNumber: this.rfq.claimNumber
      })
          .then(() => {})
          .catch(error => {
            console.log(error)
          }).finally(() => {
        NProgress.done()
      })
    },
    removedLastFile () {
      if (!this.isPartsTypeOemGen) {
        this.$refs['supplier-rfq-view-table'].selectTab('#vehicle-details')
        this.$refs['supplier-rfq-view-table']?.setTabVisible('#files', false)
      }
    },
    _isEmpty (v) {
      return _.isEmpty(v)
    },
    sendNillStock () {
      NProgress.start()

      Axios.post('ir/supplier/rfq/' + this.rfq.id + '/send-nill-stock',)
          .then(response => {
            if (response.data._status) {
              _.forEach(this.rfq.parts, (prt) => {
                if (prt.stock == 0) {
                  prt.stock = 3
                }
              })
              this.$toast.success('RFQ ' + this.rfq.number + ' - Sent Nil Stock')
              this.loadRFQ(this.rfq.id)
            }
          })
          .finally(() => {
            NProgress.done()
          })
    },
    filterRfqFiles (val) {
      let data = _.filter(val, (f) => {
        return !(f.type === 'scheme' && !this.isCanShowSchemeImage)
      })
      this.$set(this.rfq, 'files', data)
    },
    selectSchemeImage (isEdit = false) {
      if (!this.isCanShowSchemeImage) {
        return
      }
      this.selectedSchemeIndex = _.findIndex(this.computedSchemeImages, (i) => {
        return Number(i.id) === Number(this.schemeImage.id)
      })
      this.$refs['supplier-rfq-view-chat'].openChat()
      this.$refs['supplier-rfq-view-chat'].scrollToTop()
      if (isEdit) {
        this.$nextTick(() => {
          this.$refs['image-gallery']?.onClickEdit()
        })
      }
    },
    selectedFilesRows (rows) {
      if (!this.isCanShowSchemeImage) {
        return
      }
      this.isClosedPreviewImage = true
      if (rows?.[0]?.type === 'scheme') {
        this.schemeImage = rows[0]
      } else {
        this.schemeImage = null
      }
    },
    saveSchemeImg () {
      Axios.post('/ir/supplier/rfq/save-scheme', {
        'fileId': this.uploadedFileId,
        'rfqId': this.rfq.id
      })
          .then((r) => {
            if (r?.data?._status) {
              this.uploadedFileId = null
              this.$toast.success('Scheme has been saved')
              let vm = this
              setTimeout(function () {
                vm.images.splice(0, vm.images.length)
                if (vm.$refs.imgUploadDropMyAccount) {
                  vm.$refs.imgUploadDropMyAccount.removeAllFiles()
                }
              }, 1000)
              _.forEach(r.data.file, (f) => {
                this.rfq.files.push(f)
              })
              if (!this.isClosedPreviewImage && !this.schemeImage) {
                let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
                  return f.type === 'scheme'
                })
                if (indexOfSchemeImg !== -1) {
                  this.schemeImage = this.rfq.files[indexOfSchemeImg]
                  this.$nextTick(() => {
                    this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
                  })
                }
              }
            }
          })
    },
    hideScheduleContext () {
      this.isShowScheduleContext = false
    },
    formatDate (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('DD/MM/YYYY || hh:mmA')
    },
    addedImportedParts () {
      this.isImportingQuoteParts = false
      this.$set(this, 'importedParts', {})
    },
    declineRfq () {
      this.$refs.declineModal.hide()
      Axios.post('ir/supplier/rfq/' + this.rfq.id + '/decline', { declineReason: this.declineReason })
          .then(response => {
            if (response.data._status) {
              this.$toast.success('RFQ ' + this.rfq.number + ' has been declined')
              this.loadRFQ(this.rfq.id)
              this.declineReason = ''
            }
          })
    },
    onDatePickerChange (tag) {
      this.schedulePickerTag = tag
      this.isSchedulePickerClicked = true
    },
    pasteHandler (e) {
      if (e.clipboardData) {
        const items = e.clipboardData.items
        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              const blob = items[i].getAsFile()
              const blobToBase64 = blob => {
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                return new Promise(resolve => {
                  reader.onloadend = () => {
                    resolve(reader.result)
                  }
                })
              }
              blobToBase64(blob).then(res => {
                console.log(res, 'res')
                Axios.post('/ir/supplier/rfq/upload-scheme', {
                  file: res,
                  rfqId: this.rfq.id
                }).then((r) => {
                  if (r?.data?._status) {
                    this.uploadedFileId = r.data.fileId
                    const source = URL.createObjectURL(blob)
                    this.createImage(source)
                  }
                })
              })
            }
          }
        }
      }
    },
    createImage (source) {
      const pastedImage = new Image()
      pastedImage.onload = () => {
        // Обработка загруженного изображения
        this.images.push(source)
      }
      pastedImage.src = source
    },
    addImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    completeImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute('style')
    },
    sendImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    successImgUpload (file, response) {
      if (response._status) {
        this.uploadedFileId = response.fileId
      }
    },
    getTopForScheduleContext () {
      let r = 52

      if (this.isRFQNew) {
        let b1 = this.rfq.undoAvailable
        let b2 = this.rfq?.id && this.isRfqToOemGen && this.rfq.isExtensionTime()
        let b3 = !this.rfq.undoAvailable && !this.isSelectedImportDMS && this.isEnableSettingsRfqImportPdfQuote
        let b4 = !this.rfq.undoAvailable && !this.isSelectedImportCSV && this.isEnableSettingsRfqImportCsvQuote
        let b5 = this.isRfqToOemGen
        let count = Number(b1) + Number(b2) + Number(b3) + Number(b4) + Number(b5)
        r += 52 * count
      }
      return r
    },
    getTopForAssignToContext () {
      if (this.isRFQNew) {
        let b1 = this.rfq.undoAvailable
        let b2 = this.rfq?.id && this.isRfqToOemGen && this.rfq.isExtensionTime()
        if (b1 && b2) {
          return 104
        } else if (b1 || b2) {
          return 52
        }
      } else {
        if (this.isCanEditPricedQuote) {
          return 52
        }
      }
      return 0
    },
    showOrHideScheduleContext (v) {
      if (this.isLockedRfq) {
        return
      }
      if (this.isSchedulePickerClicked || this.schedulePickerTag === 'select') {
        if (v) {
          this.isSchedulePickerClicked = false
          this.isShowScheduleContext = v
        }
      } else {
        this.isShowScheduleContext = v
      }
    },
    showOrHideAssignToContext (v) {
      if (this.isLockedRfq) {
        return
      }
      this.isShowScheduleContext = false
      this.isShowAssignedToContext = v
    },
    onHideDropdown () {
      this.isShowAssignedToContext = false
      this.isShowScheduleContext = false
      this.isSchedulePickerClicked = false
      this.schedulePickerTag = ''
    },
    getRandom () {
      return uuid.v1()
    },
    clickOnAssignedTo (u) {
      let user = _.cloneDeep(u)
      if (Number(user.id) === Number(this.currentUser.id)) {
        user.name = this.currentUser.fullName
      }
      Axios.post('ir/supplier/rfq/' + this.rfq.id + '/assign-user', { userId: user.id })
          .then(response => {
            if (response.data._status) {
              if (!user.id) {
                this.rfq.assignedTo = null
              } else {
                this.rfq.assignedTo = {
                  id: user.id,
                  name: user.name,
                }
              }
              this.$store.commit('supplier/rfq/setAssignedUser', { id: this.rfq.id, user: user })
            }
          })

      if (!_.isEmpty(this.$refs.optionMenu)) {
        this.$refs.optionMenu.hide()
      }
    },
    lockQuote (v) {
      Axios.post('/ir/supplier/rfq/' + this.rfq.id + '/locked', {
        lock: v,
      }).then(response => {
        if (response?.data?._status) {
          if (v) {
            this.rfq.lock = {
              'active': true,
              'lockedBy': this.currentUser.fullName,
              'lockedName': this.currentUser.id,
              'lockTime': null
            }
            this.$toast.success('Quote has been locked')
          } else {
            if (v) {
              this.rfq.lock = {
                'active': false,
                'lockedBy': null,
                'lockedName': null,
                'lockTime': null
              }
              this.$toast.success('Quote has been unlocked')
            }
          }
        }
      }).catch(error => {
        this.$toast.error('Quote has not been locked')
        console.log(error)
      })
    },
    updateOrderingByIndex (ind) {
      // _.forEach(this.rfq.parts, (prt) => {
      // if (!prt.isManualByOemSupplier) {
      //   if (prt.listOrderNumber >= ind) {
      //     prt.listOrderNumber += 1;
      //   }
      // }
      // })

    },
    updatePartsOrdering () {
      let data = { sorts: [] }
      // this.rfq.parts = _.orderBy(this.rfq.parts, [(itm) => itm.id], ['asc'])

      // let isHasManualOemPartInStart = _.find(this.rfq.parts, (p) => {
      //   return p.isManualByOemSupplier && p.listOrderNumber === -1
      // })

      // let i = isHasManualOemPartInStart ? 1 : 0;
      // _.forEach(this.rfq.parts, (p) => {
      //   if (p.isManualByOemSupplier) {
      //     p.listOrderNumber = p.listOrderNumber + 1;
      //   }
      // })
      // this.rfq.parts = _.orderBy(this.rfq.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      let i = 0
      _.forEach(this.rfq.parts, (p) => {
        if (!p.isManualByOemSupplier) {
          p.listOrderNumber2 = _.cloneDeep(p.listOrderNumber)
          p.listOrderNumber = i

          // let isHasManualOemPart = _.find(this.rfq.parts, (p) => {
          //   return p.isManualByOemSupplier && p.listOrderNumber === i
          // })
          // if (isHasManualOemPart) {
          //   p.listOrderNumber += 1;
          // }
          i++
        }
      })

      this.rfq.parts = _.orderBy(this.rfq.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      // this.rfq.parts = _.orderBy(this.rfq.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.isManualByOemSupplier), (itm) => Number(itm.id)])

      _.forEach(this.rfq.parts, (p, ind) => {

        data.sorts.push({
          id: p.id,
          listOrderNumber: p.listOrderNumber
        })
      })

      // _.forEach(this.rfq.parts, (p, ind) => {
      //   let nextManualByOemSuppPart = _.find(this.rfq.parts, (prt) => {
      //     return prt.isManualByOemSupplier && (p.listOrderNumber === prt.listOrderNumber || ind === prt.listOrderNumber) && !p.isAlreadyUsed
      //   })
      //   if (nextManualByOemSuppPart) {
      //     nextManualByOemSuppPart.isAlreadyUsed = true;
      //   }
      //   if (!p.isManualByOemSupplier) {
      //     if (nextManualByOemSuppPart) {
      //       p.listOrderNumber += nextManualByOemSuppPart.listOrderNumber + 0.5
      //     } else {
      //       p.listOrderNumber = ind;
      //     }
      //   }
      //   data.sorts.push({
      //     id: p.id,
      //     listOrderNumber: p.listOrderNumber
      //   })
      // })
      //
      // _.forEach(this.rfq.parts, (p) => {
      //   if (p.isAlreadyUsed) {
      //     p.isAlreadyUsed = false;
      //   }
      // })

      // Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
      //     .then(response => {
      //       console.log('Reorder Response: %o', response.data)
      //     })
      //     .catch(error => {
      //       console.log(error)
      //       this.$toast.error('An error occurred in submitting your request')
      //     })
    },
    onFocusPaintCode () {
      this.$refs['paint-code-field'].focus()
      this.isPaintCodeInFocus = true
      this.showPlaceholderPaintCode = false
      console.log('this.showPlaceholderPaintCode == false = ' + this.showPlaceholderPaintCode)
    },
    onBlurPaintCode () {
      this.isPaintCodeInFocus = false
      if (_.isEmpty(this.rfq.paintCodeForRepair)) {
        this.showPlaceholderPaintCode = true
      }
      if (this.isNeedSavePaintCode) {
        this.isNeedSavePaintCode = false
        this.updatePaintCodeForRepair()
      }
    },
    removeQuoteNbr (nbr) {
      this.isClickRemoveQuote = true
      let supplierId = this.rfq.supplierId
      Axios.post(`/ir/supplier/rfq/disable-email-pdf-quote-numbers`, {
        quoteNumber: nbr,
        rfqId: this.rfq.id,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$toast.success('You have successfully removed ' + nbr)
              this.$store.commit('supplier/rfq/supplierRFQQuoteNbrsRemove', { nbrs: [nbr], cid: supplierId })
            } else {
              this.$toast.error('Removal of ' + nbr + ' has failed')
            }
          })
          .catch(() => {
            this.$toast.error('Removal of ' + nbr + ' has failed')
          })
          .finally(() => {
            this.isClickRemoveQuote = false
          })
    },
    viewQuotes () {
      let filters = this.$route.params.filters
      this.$router.push({ path: this.viewRfqsTableLink })
      if (!_.isEmpty(filters)) {
        if (filters.rfqNumber) {
          setTimeout(() => {
            this.$store.commit('supplier/rfq/setSearchFilterObj', {
              0: {
                search: '',
              },
              1: {
                search: '',
                customer: filters.customer,
              },
              2: {
                search: '',
              },
              3: {
                search: '',
              },
              4: {
                search: '',
              },
            })
          }, 2000)
          this.$store.dispatch('supplier/rfq/setFilter', filters.rfqNumber, { root: true })
          let customers = {}
          this.gettersAllRFQs.forEach(rfq => {
            if (rfq.customerName) {
              customers[rfq.customerName.trim().toLowerCase()] = { label: rfq.customerName.trim() }
            }
          })
          customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

          this.$store.commit('supplier/rfq/setTokens', {
            0: [
              {
                id: 'customer',
                label: 'Customers',
                iconClass: 'bx-user',
                options: [
                  customers
                ],
                isCompleted: true,
                originalIndex: 0,
              },
              {
                label: filters.customer
              }
            ],
            1: [],
            2: [],
            3: [],
            4: [],
          })
        }
      }
      // this.$router.push({name: 'SupplierRFQs' + this.viewRfqsTableLink, params: {filters: filters}});
    },
    openScheduleDropdown () {
      this.scheduleDateTime = null
    },
    saveSchedule (isRemove = false) {
      if (!isRemove && _.isEmpty(this.scheduleDateTime)) {
        this.$toast.error('Schedule Date and Time must not be empty')
        return
      }
      let scheduleTime = isRemove ? null : dayjs(this.scheduleDateTime, 'hh:mmA || DD/MM/YYYY').format('DD/MM/YYYY hh:mmA')
      let scheduleTime2 = isRemove ? null : dayjs(this.scheduleDateTime, 'hh:mmA || DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ')
      Axios.post(`/ir/supplier/rfq/${this.rfq.id}/schedule-submit-quote`, {
        scheduleTime: scheduleTime,
        scheduleTime2: scheduleTime2,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.rfq.scheduleTime = !isRemove ? dayjs(scheduleTime, 'DD/MM/YYYY hh:mmA').format() : null
            }
          })
      if (!_.isEmpty(this.$refs.optionMenu)) {
        this.$refs.optionMenu.hide()
        this.scheduleDateTime = null
      }
    },
    isEmpty (val) {
      return _.isEmpty(val)
    },
    selectQuoteNbr (nbr) {
      setTimeout(() => {
        if (!this.isClickRemoveQuote) {
          this.rfq.supplierQuoteNumber = nbr
          this.isQuoteNbrFocus = false
          this.updateSupplierQuoteNumber(null, true)
        }
      }, 20)
    },
    onQuoteNbrBlur () {
      if (this.isQuoteNbrFocus2) {
        return
      }
      setTimeout(() => {
        this.isQuoteNbrFocus = false
        this.isQuoteNbrFocus2 = false
      }, 100)
    },
    addDelivery (id) {
      // this.loadRFQ(0, () => {
      //   this.$refs.partLV.focusElement('deliveryName-' + id)
      // });
    },
    undoQuote () {
      Axios.post(`/ir/supplier/rfq/${this.rfq.id}/undo`)
          .then((r) => {
            if (r?.data?._status) {
              this.loadRFQ(this.rfq.id)
              this.$set(this, 'importedParts', {})
            }
          })
    },
    sortParts () {
      return
      let parts = _.orderBy(_.cloneDeep(this.rfq.parts), [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      //parts = _.orderBy(parts, [(itm) => Number(itm.listOrderNumber)])
      // _.forEach(parts, (prt, index) => {
      //   if (prt.listOrderNumber === 999) {
      //     prt.listOrderNumber = index;
      //   }
      // })
      return
      let data = { parts: [] }
      parts.forEach(prt => {
        data.parts.push({
          id: prt.id,
          listOrderNumber: prt.listOrderNumber
        })
      })

      let isHasChanges = _.some(this.rfq.parts, (prt) => {
        let newPrt = _.find(parts, (p) => {
          return Number(p.id) === Number(prt.id)
        })
        return !(newPrt && newPrt.listOrderNumber === prt.listOrderNumber)
      })

      if (!isHasChanges) {
        return
      }

      Axios.post('/ir/supplier/rfq/' + this.rfq.id, data)
          .then(() => {
            this.rfq.parts = parts
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
      })
    },
    viewOrder () {
      if (this.rfq && this.rfq.order && this.rfq.order.id) {
        this.$router.push({ name: 'SupplierPartsOrderView', params: { orderId: this.rfq.order.id } })
      } else {
        this.$toast.error('Can not find order')
      }
    },
    setCurrentViewing () {
      let timestamp = this.$store.getters['updater/lastTimestamp']
      Axios.get('/ir/update/rfq-to-supplier/' + this.$route.params.rfq_id + '/' + timestamp)
          .then(response => {
            if (response.data._status) {
              if (!_.isEmpty(response.data.currentlyViewing)) {
                this.currentlyViewing.splice(0, this.currentlyViewing.length)
                _.forEach(response.data.currentlyViewing, (u) => {
                  this.currentlyViewing.push(u)
                })
              } else {
                this.currentlyViewing.splice(0, this.currentlyViewing.length)
              }
              if (!_.isEqual(this.rfq.lock, response.data.lock)) {
                let oldVal = _.cloneDeep(this.rfq.lock)
                this.rfq.lock = response.data.lock
                if (this.isLoadedRFQ && !oldVal?.active && this?.rfq?.lock?.active && this.isRFQNew && Number(this.rfq?.lock?.lockedBy) !== Number(this.currentUser?.id)) {
                  setTimeout(() => {
                    this.$refs.autoLockSettingsBlock.show()
                  }, 100)
                }
              }
            }
          })
    },
    confirmedImport (data) {
      this.$set(this, 'importedParts', {
        parts: data,
        isFromCSV: this.isSelectedImportCSV,
        isFromPDF: this.isSelectedImportDMS,
      })
    },
    initMenu () {
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if (bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = true
        }
      })
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if (bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = false
          if (!_.isEmpty(this.$refs.optionMenu)) this.$refs.optionMenu.hide()
        }
        if (this.isDropdown2Visible) {
          bvEvent.preventDefault()
        }
      })
    },
    deleteQuote () {
      Axios.post('/ir/supplier/rfq/' + this.rfq.id + '/delete', {
        rfqId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been delete')
        this.$store.dispatch('supplier/rfq/init', {}, { root: true })
        this.$store.commit('supplier/rfq/supplierRFQDelete', { id: this.rfq.id })
        this.$router.push('/s/rfqs/#closed')
      }).catch(error => {
        this.$toast.error('Quote haven\'t been delete')
        console.log(error)
      })
    },
    sendPartNumbers () {
      Axios.post(`/ir/supplier/rfq/${this.rfq.id}/send-part-numbers`, {
        rfqId: this.rfq.id
      }).then(response => {
        if (response.data._status) {
          this.$toast.success('Part Nbrs/List have been sent to Repairer')
        }
      }).catch(error => {
        this.$toast.error('Cannot send Part Nbrs/List!')
        console.log(error)
      })
    },
    closeQuote () {
      Axios.post('/ir/supplier/rfq/' + this.rfq.id + '/close', {
        rfqId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been closed')
        this.$store.dispatch('supplier/rfq/init', {}, { root: true })
        this.loadRFQ(this.rfq.id)
      }).catch(error => {
        this.$toast.error('Quote haven\'t been closed')
        console.log(error)
      })
    },
    importQuote (type) {
      if (type === 'csv') {
        this.$refs['supplier-rfq-view-table'].selectTab('#part-list')
        this.isSelectedImportCSV = true
        this.isSelectedImportDMS = false
      } else {
        this.$refs['supplier-rfq-view-table'].selectTab('#part-list')
        this.isSelectedImportDMS = true
        this.isSelectedImportCSV = false
      }
    },
    processOrder () {
      let id = this.rfq.id
      Axios.post('/ir/supplier/rfq/' + id + '/process-order', { rfqId: id })
          .then(response => {
            if (response.data && response.data.status && response.data.status == true) {
              this.$toast.success('Order has been processed')
              if (response.data.orderId) {
                this.$router.push({
                  name: 'SupplierPartsOrderView',
                  params: { orderId: String(response.data.orderId) }
                })
              } else {
                this.loadRFQ(id)
              }
              this.$store.dispatch('supplier/rfq/init', {}, { root: true })
              return
            }
            this.$toast.error('Order hasn`t been processed')
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Order hasn`t been processed')
          })
    },
    updatePaintCodeForRepair () {
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, {
        setPaintCodeForRepair: this.rfq.paintCodeForRepair
      }).then(response => {
        if (response.data.status) {
        } else {
          this.$toast.error('Cannot save Paint Code')
        }
      }).catch(error => {
        this.$toast.error('Cannot save Paint Code')
      })
    },
    addImportedPdf (fileId) {
      if (!fileId) {
        return
      }
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, {
        fileIds: [fileId],
        updateFromPdfFile: true,
      }).then(response => {
        if (response.data._status && response.data.rfq) {
          this.rfq.files = response.data.rfq.files
        } else {
          this.$toast.error('Cannot add Pdf in the File section')
        }
      }).catch(error => {
        this.$toast.error('Cannot add Pdf in the File section')
        console.log(error)
      })
    },
    // getPDFData () {
    //   Axios.get(`/ir/supplier/rfq/${this.rfq.id}/matched-email-pdf-data`).then((r) => {
    //     if (r?.data?._status) {
    //       console.log(r.data.parts)
    //       if (!_.isEmpty(r.data.data.parts)) {
    //         this.loadedPDFFiles = r.data.data.parts;
    //         this.importQuote('dms')
    //       }
    //     }
    //   })
    // },
    updateSupplierQuoteNumber (val = null, isSelectedFromList = false) {
      NProgress.start()
      let supplierQuoteNumber = this.rfq.supplierQuoteNumber
      if (val) {
        supplierQuoteNumber = val
        this.rfq.supplierQuoteNumber = val
      }
      let params = {
        setSupplierQuoteNumber: supplierQuoteNumber,
      }

      if (this.isSelectedImportCSV) {
        params.updateFromCsvFile = true
      } else {
        params.updateFromPdfFile = true
      }

      if (isSelectedFromList) {
        params.isSelectedFromList = true
        this.$store.commit('supplier/rfq/supplierRFQQuoteNbrsRemove', { nbrs: [val], cid: this.supplierId })
      }
      // this.rfq.dmsQuoteNumber = this.rfq.dmsQuoteNumber.substr(0,15);
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, params).then(response => {
        if (response.data.status) {
          if (response.data.hasOwnProperty('undoAvailable')) {
            this.rfq.undoAvailable = response.data.undoAvailable
          }
          if (!_.isEmpty(response.data.matchedPdfFromEmail)) {
            if (response?.data?.matchedPdfFromEmail?.data?.parts) {
              this.isImportingQuoteParts = true
              this.loadedPDFFiles = response.data.matchedPdfFromEmail.data.parts
              this.importQuote('dms')
            }
            if (response?.data?.matchedPdfFromEmail?.pdfFileId) {
              this.addImportedPdf(response.data.matchedPdfFromEmail.pdfFileId)
            }
          }
          //this.loadRFQ(this.rfq.id)
        } else {
          this.$toast.error('Cannot save Quote Number')
        }
      }).catch(error => {
        this.$toast.error('Cannot save Quote Number')
        console.log(error)
      })
          .finally(() => {
            NProgress.done()
          })
    },
    pinClickImage: function (index) {
      this.shownGallery = true
      this.galleryCurrentIndex = index
    },
    onCloseGallery: function () {
      this.shownGallery = false
    },
    reversedImageList () {
      return this.imageSource.slice().reverse()
    },
    changedTab: function (obj) {
      this.activeTab = obj.tab.name
      if (obj.tab.hash === '#part-list' && !this.isAlreadyAutoClickedPinImage && this.isRfqToUsed && !this.pinImage && !this.isMobile) {
        this.pinImage = true
        this.$store.commit('supplier/rfq/updatePinImage', true)
        this.isAlreadyAutoClickedPinImage = true
      }

      setTimeout(() => {
        this.calculateWidth()
      }, 10)
    },
    editVehicleDetails: function () {
      this.isEditVehicleDetails = true
    },
    editPartList: function () {
      this.isEditPartList = true
    },
    cancelEdit: function () {
      this.isEditPartList = false
      this.isEditVehicleDetails = false
    },
    autoFileImage: function (event) {
      let update = {}
      update.fileIds = this.fileIds
    },
    checkOfferForOrder: function (partId, offerId) {
      if (this.ifOfferChecked(partId, offerId)) {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: 0 })
      } else {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: offerId })
      }
    },
    saveCountDiagramModal() {
      Axios.post('/ir/supplier/rfq/' + this.rfq.id, {
        countDiagramModal: this.rfq.properties.isCanShowPartsDiagram
      })
          .then(() => {})
          .catch(error => {
            console.log(error)
          }).finally(() => {})
    },
    loadRFQ: function (id = 0, callback = false) {
      if (!id) id = this.rfq.id
      console.log('loadRFQ')
      this.getRFQById(id).then(response => {
        if (!response.data.status) {
          this.$toast.error('Page not found')
          this.$router.push('/s')
        } else {
          if (response.data.isMerged) {
            this.$router.push({ name: 'SupplierRFQView', params: { rfq_id: response.data.mergedTo } })
          } else if (!_.isEmpty(response.data.rfq)) {
            let data = _.cloneDeep(response.data.rfq)
            // let isHasUnOrderedParts = _.some(data.parts, (p) => {
            //   return Number(p.listOrderNumber) === 999
            // })
            let isHasUnOrderedParts = true
            console.log(isHasUnOrderedParts, 'isHasUnOrderedParts')
            if (isHasUnOrderedParts) {
              // let isHasManualOemPartInStart = _.find(this.rfq.parts, (p) => {
              //   return p.isManualByOemSupplier && p.listOrderNumber === -1
              // })

              // let i = isHasManualOemPartInStart ? 1 : 0;
              // _.forEach(data.parts, (p) => {
              //   if (p.isManualByOemSupplier) {
              //     p.listOrderNumber = p.listOrderNumber + 1;
              //   }
              // })
              this.isAdditionalRfq = !_.isEmpty(data.additionalParts) || !_.isEmpty(data.additionalPartsToSuppliers)

              _.forEach(data.additionalParts, (p) => {
                p.isAdditional = true
                if (data.additionalPartsToSuppliers && Object.keys(data.additionalPartsToSuppliers).length > 0) {
                  p.additionalSuppId = Object.keys(data.additionalPartsToSuppliers)[0]
                }
                data.parts.push(p)
              })

              if (_.isEmpty(data.partsToSuppliers)) {
                data.partsToSuppliers = {}
              }
              _.forIn(data.additionalPartsToSuppliers, (s) => {
                let currentSupp = _.find(data.partsToSuppliers, (cS) => {
                  return Number(cS.id) === Number(s.id)
                })
                if (currentSupp) {
                  _.forEach(s.parts, (sP) => {
                    currentSupp.parts.push(sP)
                  })
                } else if (Number(s.supplierId) !== Number(this.companyInfo.id)) {
                  s.isAdditional = true
                  data.partsToSuppliers[s.id] = s
                }
              })

              if (data.additionalPartsToSuppliers && Object.keys(data.additionalPartsToSuppliers).length > 0) {
                data.parts = _.orderBy(data.parts, (p) => {
                  if (p.additionalSuppId) {
                    return p.additionalSuppId
                  }
                  return data.id
                })
              } else if (!_.isEmpty(data.additionalParts)) {
                data.parts = _.orderBy(data.parts, [(itm) => (itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
              }

              // data.parts = _.orderBy(data.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
              let i = 0
              _.forEach(data.parts, (p) => {
                if (!p.isManualByOemSupplier) {
                  p.listOrderNumber2 = _.cloneDeep(p.listOrderNumber)
                  p.listOrderNumber = i

                  // let isHasManualOemPart = _.find(this.rfq.parts, (p) => {
                  //   return p.isManualByOemSupplier && p.listOrderNumber === i
                  // })
                  // if (isHasManualOemPart) {
                  //   p.listOrderNumber += 1;
                  // }
                  i++
                }
              })
            }
            this.rfq = new RFQ(data)

            this.filterRfqFiles(this.rfq.files)

            if (!this.isClosedPreviewImage) {
              let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
                return f.type === 'scheme'
              })
              if (indexOfSchemeImg !== -1) {
                this.schemeImage = this.rfq.files[indexOfSchemeImg]
                this.$nextTick(() => {
                  this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
                })
              }
            }

            if (this?.rfq?.properties?.isInEditMode) {
              this.priceResubmitActiveFlag = this.rfq.properties.isInEditMode
            }

            if (_.isEmpty(this.rfq.paintCodeForRepair) && !this.isPaintCodeInFocus) {
              this.showPlaceholderPaintCode = true
            } else {
              this.showPlaceholderPaintCode = false
            }

            if (response.data.chatMessages) {
              this.chatMessages = response.data.chatMessages
              if (this.unviewedMessagesIds.length > 0) {
                this.markMessagesAsViewed(this.unviewedMessagesIds)
              }
            }

            let isHasScheme = _.some(this.rfq.files, (f) => {
              return f.type === 'scheme'
            })

            if (!this.isShowedDiagramModal && this.rfq.properties.isAskForPartsDiagram && !isHasScheme && this.isRfqToOemGen && Number(this.rfq.properties.isCanShowPartsDiagram) < 3) {
              this.isShowedDiagramModal = true
              this.$refs.partsDiagramRequested.show()
              this.rfq.properties.isCanShowPartsDiagram++
              this.saveCountDiagramModal()
            }

            if (!this.isShowDiagram && this.$route?.params?.shemeId && (this.isRFQNew || this.isRFQPriced)) {
              this.isShowDiagram = true
              let isHasFile = _.some(this.rfq.files, (f) => {
                return Number(f.id) == Number(this.$route.params.shemeId)
              })
              if (isHasFile) {
                setTimeout(() => {
                  this.$refs['supplier-rfq-view-table'].selectTab('#files')
                  this.selectSchemeImage(false)
                }, 0)
              }
            }

            if (callback) {
              callback()
            }
            //  this.sortParts();
          }

        }
      }).catch(error => {
        console.log(error)
      })
    },
    partListPriced () {
      this.loadRFQ(this.rfq.id)
      //this.$store.dispatch('supplier/rfq/init', {}, { root: true })
    },
    vehicleDetailsTabChange () {
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    partListTabChange () {
      this.isHiddenOptions = true
      this.classTabContent = 'tab-content-table'
    },
    imagesTabChange () {
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    filesTabChange () {
      this.cardSaveElement = 'Files'
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    clickBrowse: function (id) {
      $(id).trigger('click')
    },
    calculateWidth () {
      if (!this.$refs.partLV) {
        return
      }
      let w = this.$refs.partLV.$el.querySelector('div').clientWidth
      if (!w) {
        return
      }
      w += 24
      this.widthStyle = {
        width: w + 'px'
      }
    },
    addOptional (part) {
      // sample optional-part data
      const index = this.rfq.parts.findIndex(_part => part.id === _part.id)
      //    console.log('adding optional part to ', part, index);
      if (index >= 0) {
        this.rfq.parts.splice(index + 1, 0, new Part({
          id: null,
          price: 0,
          status: null,
          qty: this.rfq.parts[index].qty,
          comment: '',
          name: 'Optional Part',
          partNumber: '',
          rrp: null,
          stock: 0,
          stockComment: '',
          optionalPartComplete: true,
          type: 'optional' // assumed optional part type defined here
        }))

      } else {
        console.log('Part selected not found')
      }
    },
    setOptionalType (optionalPart, typeName) {
      const partIndex = this.rfq.parts.findIndex(_part => _part === optionalPart)
      if (partIndex) {
        this.rfq.parts[partIndex].name = this.rfq.parts[partIndex].name.split(' - ')[0].concat([` - ${typeName}`])
        this.rfq.parts[partIndex].optionalPartComplete = true
      }
    },
    setTitle (isNeedToClear = false) {
      let text = !isNeedToClear && (!_.isEmpty(this.rfq.supplierQuoteNumber) || !_.isEmpty(this.rfq.repairer)) ? '' : 'PartsSearch.com.au'
      if (!isNeedToClear && !_.isEmpty(this.rfq.supplierQuoteNumber)) {
        text += this.rfq.supplierQuoteNumber
      }
      if (!isNeedToClear && !_.isEmpty(this.rfq.repairer)) {
        if (!_.isEmpty(this.rfq.supplierQuoteNumber)) {
          text += ' - '
        }
        text += this.rfq.repairer
      }
      let title = document.getElementsByTagName('title')
      if (title?.[0]) {
        title[0].innerText = text
      }
    },
    onChatMessage (data) {
      if (data.type === 'newMessage') {
        this.chatMessages.push(data)
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, data.companyId)

          if (data.messageId && isNotMyMessage) {
            this.markMessagesAsViewed([data.messageId])
          }
        } else {
          if (data.messageId && data.companyId != this.companyInfo.id) {
            this.markMessagesAsViewed([data.messageId])
          }
        }
      }
      if (data.type === 'repairerIsOnline') {
        this.chatRepairerIsOnline = true
        this.repairerLeavedTheChat()
      }
      if (data.type === 'typingInChat' && data.id !== this.currentUser.id) {
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    onChatInputTyping () {
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    sendChatMessage (text) {
      if (!text || !text.trim()) {
        return new Promise((resolve) => {
          resolve(false)
        })
      }
      return axios.post('/centrifuge/supplier/send-message-to-rfq', {
        rfqId: this.rfq.id,
        message: text.trim(),
      })
    },
    onChatMessageSubmit ({ message, resolve }) {
      if (this.unviewedMessagesIds.length > 0) {
        this.markMessagesAsViewed(this.unviewedMessagesIds)
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed (messageIds = []) {
      return axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    },
  },
  created: function () {
    let id = this.$route.params.rfq_id
    this.loadRFQ(id, () => {
      this.setTitle()
      this.isLoadedRFQ = true
      this.interval2 = setInterval(() => {
        if (this.isMounted) {
          if (this.isLoadedRFQ && this.currentUser.id) {
            if (this.isLockedRfq) {
              setTimeout(() => {
                this.$refs.autoLockSettingsBlock.show()
              }, 100)
            }

            clearInterval(this.interval2)
          }
        }
      }, 10)
      this.setTitle()
      this.$nextTick(() => {
        setTimeout(() => {
          this.setDirectCommentBlockVisible()
        }, 0)
      })
      this.isLoadedRFQ = true      // this.updatePartsOrdering()
      if (this.$route.hash == '#isEdit' && this.isRFQPriced && this.rfq?.properties?.isAvailableToUpdate) {
        this.priceResubmitActiveFlag = true
      }
    })
    this.calculateWidth();

    (async () => {
      this._chat = await subscribeToRfqChannel(id, true, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'supplierIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if (this.onlineStatusIntervalId !== null) {
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })()

  },
  beforeDestroy () {
    clearInterval(this.timestampInterval)

    if (this.interval !== null) {
      clearInterval(this.interval)
    }
    if (this.interval2 !== null) {
      clearInterval(this.interval2)
    }
    if (this.interval3 !== null) {
      clearInterval(this.interval3)
    }
    if (this.interval4 !== null) {
      clearInterval(this.interval4)
    }
    if (this.interval5 !== null) {
      clearInterval(this.interval4)
    }

    this.setTitle(true)
    this.$store.commit('supplier/rfq/updatePinImage', false)
    this.$store.commit('supplier/rfq/supplierCurrentRFQUpdate', null)
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('resize', this.setDirectCommentBlockVisible)

    if (this._chat) {
      this._chat.unsubscribe()
    }
  },
  mounted: function () {

    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.setDirectCommentBlockVisible)
    this.scheduleDateTime = dayjs(new Date()).format('hh:mmA || DD/MM/YYYY')
    this.isMobile = isMobileOnly
    // this.loadRFQ(this.$route.params.rfq_id);
    this.initMenu()
    this.calculateWidth()
    setInterval(() => {this.calculateWidth()}, 500)
    this.interval = setInterval(() => {
      this.setCurrentViewing()
    }, 5000)
    this.isMounted = true
    console.log('isAutoConfirm', !_.isEmpty(this.loadedPDFFiles) || !this.isEnableSettingsRfqImportPdfShowModal || this.isSelectedImportCSV, !_.isEmpty(this.loadedPDFFiles), !this.isEnableSettingsRfqImportPdfShowModal, this.isSelectedImportCSV)
    this.toastContent = {
      component: ToastTemplate,
      props: {
        toastText: '',
        toastTitle: '',
      },
      listeners: {
        dismissAll: () => this.customToast.clear()
      }
    }

    this.interval3 = setInterval(() => {
      if (this.companyInfo.id) {
        if (this.$refs['supplier-rfq-view-table'].activeTabHash === '#part-list' && this.isRfqToUsed && !this.pinImage && !this.isMobile) {
          this.pinImage = true
          this.$store.commit('supplier/rfq/updatePinImage', true)
        }
        clearInterval(this.interval3)
      }
    }, 10)

    this.interval4 = setInterval(() => {
      if (this.companyInfo.id && this.rfq.id) {
        if (this.isPartsTypeOemGen) {
          if (window.Clipboard) {
            window.addEventListener('paste', this.pasteHandler)
          }
        }
        clearInterval(this.interval4)
      }
    }, 10)

    this.repairerLeavedTheChat = _.debounce(function () {
      this.chatRepairerIsOnline = false
    }, 5000)

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  filters: {
    maxFour (str) {
      switch (str) {
        case 'New':
          return 'New'
        case 'Priced':
          return 'Prcd'
        case 'Lost':
          return 'Lost'
        case 'Won':
          return 'Won'
        case 'Ordered':
          return 'Ord'
        case 'Cancelled':
          return 'Canc'
        default:
          return str.substring(0, 4)
      }
    }
  },
  components: {
    BestPriceCountdown,
    WindowNotification,
    PartListView,
    QuotePricingProgress,
    RfqTimer,
    ImageLightbox,
    ImageLightboxWithoutGallery,
    ImageGallery,
    vinDate,
    CustomerAccountDeliver,
    vueDropzone: vue2Dropzone,
    FileUpload,
    'image-edditor': imageEditor,
    'image-gallery': imageGallery,
    DatePicker,
    RfqImport,
    Chat,
    AssignTo,
    StickyHeader,
    SearchFilter,
    PdfViewer,
    VueCountdown
  },
  watch: {
    getterQuoteNumbers: {
      handler (val) {
        console.log('watch.getterQuoteNumbers', _.cloneDeep(val))
        this.callComputed++
      },
      deep: true,
      immediate: true,
    },
    currentRFQ: {
      handler: function (val) {
        if (!(!_.isEmpty(val) && Number(this.rfq.id) === Number(val.id))) {
          return
        }
        this.callComputed++
        if (this.rfq && val.rfqUpdateRequested) {
          this.rfq.rfqUpdateRequested = val.rfqUpdateRequested
          this.rfq.rfqUpdateRequestedOn = val.rfqUpdateRequestedOn
        }

        if (val && val.status != '' && this.rfq.status != '' && val.status != this.rfq.status) {
          if ((val.status === 'Closed' || (val.status === 'Decline' && this.rfq.properties.isSentAsDirectOrder)) && !this.isShowedCancelNotice) {
            this.toastContent.props = {
              toastText: this.rfq.properties.isSentAsDirectOrder ? 'Direct Order has been DECLINED. RFQ ' + this.rfq.number : 'The customer has CANCELLED this RFQ for ' + this.rfq.QPNumber,
              toastTitle: ''
            }
            this.$store.commit('showToast', {
              toastContent: _.cloneDeep(this.toastContent),
              toastOptions: this.toastOptions
            })
            this.isShowedCancelNotice = true
          }
          this.loadRFQ(this.$route.params.rfq_id)
          return
        }
        if (this.rfq?.rfqUpdateRequested !== val?.rfqUpdateRequested) {
          this.rfq.rfqUpdateRequested = val.rfqUpdateRequested
        }
        if (val.status && this.rfq.status !== val.status) {
          this.rfq.status = val.status
        }

        if (val.statusId && this.rfq.statusId !== val.statusId) {
          this.rfq.statusId = val.statusId
        }

        if (val.dmsQuoteNumber && this.rfq.dmsQuoteNumber !== val.dmsQuoteNumber) {
          this.rfq.dmsQuoteNumber = val.dmsQuoteNumber
        }

        if (val.dateDue !== this.rfq.dateDue) {
          this.rfq.dateDue = val.dateDue
        }

        if (val.QPNumber && val.QPNumber !== this.rfq.QPNumber) {
          this.rfq.QPNumber = val.QPNumber
        }

        if (val.assignedTo && val.assignedTo !== this.rfq.assignedTo) {
          this.rfq.assignedTo = val.assignedTo
        }

        if (val.businessName && val.businessName !== this.rfq.businessName) {
          this.rfq.businessName = val.businessName
        }

        if (val.cuttOff && val.cuttOff !== this.rfq.cuttOff) {
          this.rfq.cuttOff = val.cuttOff
        }

        if (val.discountsApplied !== this.rfq.discountsApplied) {
          this.rfq.discountsApplied = val.discountsApplied
        }
        // if (val.hasOwnProperty('supplierQuoteNumber') && val.supplierQuoteNumber !== this.rfq.supplierQuoteNumber) {
        //   this.rfq.supplierQuoteNumber = val.supplierQuoteNumber;
        // }
        if (val.hasOwnProperty('repairerQuoteNumber') && val.repairerQuoteNumber !== this.rfq.repairerQuoteNumber) {
          this.rfq.repairerQuoteNumber = val.repairerQuoteNumber
          this.setTitle()
        }

        if (val.files) {
          this.$set(this.rfq, 'files', val.files)
        }

        if (val.images) {
          this.$set(this.rfq, 'images', val.images)
        }

        if (val.location) {
          this.rfq.location = val.location
        }

        if (val.partsDeliveryETA && this.rfq.partsDeliveryETA !== val.partsDeliveryETA) {
          this.rfq.partsDeliveryETA = val.partsDeliveryETA
        }

        if (val.partType && this.rfq.partsType !== val.partType) {
          this.rfq.partsType = val.partType
        }

        if (val.properties && this.rfq.properties !== val.properties) {
          this.rfq.properties = val.properties
        }

        if (val.qtyPartsInRfq && this.rfq.qtyPartsInRfq !== val.qtyPartsInRfq) {
          this.rfq.qtyPartsInRfq = val.qtyPartsInRfq
        }

        if (val.repairer && this.rfq.repairer !== val.repairer) {
          this.rfq.repairer = val.repairer
        }

        if (val.rfqUpdateRequested && this.rfq.rfqUpdateRequested !== val.rfqUpdateRequested) {
          this.rfq.rfqUpdateRequested = val.rfqUpdateRequested
        }

        if (val.rfqUpdateRequestedOn && this.rfq.rfqUpdateRequestedOn !== val.rfqUpdateRequestedOn) {
          this.rfq.rfqUpdateRequestedOn = val.rfqUpdateRequestedOn
        }

        if (val.rfqUpdateSentOn && this.rfq.rfqUpdateSentOn !== val.rfqUpdateSentOn) {
          this.rfq.rfqUpdateSentOn = val.rfqUpdateSentOn
        }

        if (val.vehicle && this.rfq.vehicle !== val.vehicle) {
          this.rfq.vehicle = val.vehicle
        }

        if (val.extensionTime) {
          this.$set(this.rfq, 'extensionTime', val.extensionTime)
        }

        this.rfq.dueCountdown = val.dueCountdown

        if (!(this.rfq.order && this.rfq.order.id) && val.order && val.order.id) {
          this.$set(this.rfq, 'order', val.order)
        }

        // if (val && val.status != '' && this.rfq.status != '' && val.status != this.rfq.status && this.rfq.id) {
        //   this.loadRFQ(this.$route.params.rfq_id)
        //   return
        // }

        if (val.hasOwnProperty('undoAvailable')) {
          this.rfq.undoAvailable = val.undoAvailable
        }
        if (val && val.status && val.status === 'Priced' && val.properties && val.properties.isInEditMode && val.properties.isInEditMode === true) {
          this.priceResubmitActiveFlag = true  //#390 task
        }
        if (val && val.partsToSuppliers) {
          for (let suppId in val.partsToSuppliers) {

            if (!_.isEqual(val.partsToSuppliers[suppId]?.pdfFile, this.rfq.suppliers[suppId]?.pdfFile)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'pdfFile', val.partsToSuppliers[suppId]?.pdfFile)
              })
            }

            if (!_.isEqual(val.partsToSuppliers[suppId]?.isNonRegistered, this.rfq.suppliers[suppId]?.isNonRegistered)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'isNonRegistered', val.partsToSuppliers[suppId]?.isNonRegistered)
              })
            }


            if (!_.isEqual(val.partsToSuppliers[suppId]?.secondRequestBestPrice, this.rfq.suppliers[suppId]?.secondRequestBestPrice)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'secondRequestBestPrice', val.partsToSuppliers[suppId]?.secondRequestBestPrice)
              })
            }

            if (!_.isEqual(val.partsToSuppliers[suppId]?.secondRequestBestPriceTimeEnd, this.rfq.suppliers[suppId]?.secondRequestBestPriceTimeEnd)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'secondRequestBestPriceTimeEnd', val.partsToSuppliers[suppId]?.secondRequestBestPriceTimeEnd)
              })
            }

            if (!_.isEqual(val.partsToSuppliers[suppId]?.thirdRequestBestPrice, this.rfq.suppliers[suppId]?.thirdRequestBestPrice)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'thirdRequestBestPrice', val.partsToSuppliers[suppId]?.thirdRequestBestPrice)
              })
            }

            if (!_.isEqual(val.partsToSuppliers[suppId]?.thirdRequestBestPriceTimeEnd, this.rfq.suppliers[suppId]?.thirdRequestBestPriceTimeEnd)) {
              this.$nextTick(() => {
                this.$set(this.rfq.suppliers[suppId], 'thirdRequestBestPriceTimeEnd', val.partsToSuppliers[suppId]?.thirdRequestBestPriceTimeEnd)
              })
            }

            _.forEach(val.partsToSuppliers[suppId].parts, newPart => {
              if (!this.rfq.suppliers[suppId]) {
                return
              }
              let indexOfOldPart = _.findIndex(this.rfq.suppliers[suppId].parts, prt => {
                return Number(prt.originalPartId) === Number(newPart.originalPartId)
              })
              if (indexOfOldPart === -1) {
                return
              }
              let oldPart = this.rfq.suppliers[suppId].parts[indexOfOldPart]
              if (oldPart) {
                if (oldPart.price !== newPart.price) {
                  this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'price', newPart.price)
                  if (newPart.firstPrice) {
                    this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'firstPrice', newPart.firstPrice)
                  } else if (oldPart.firstPrice) {
                    this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'firstPrice', null)
                  }
                }
                if (oldPart.stock !== newPart.stock) {
                  this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'stock', newPart.stock)
                }

                if (oldPart.listOrderNumber !== newPart.listOrderNumber) {
                  console.log('watcher.currentRfq', newPart.listOrderNumber)
                  //this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'listOrderNumber', newPart.listOrderNumber)
                }
                if (oldPart.priceHistory !== newPart.priceHistory) {
                  console.log('watcher.currentRfq', newPart.priceHistory)
                  this.$set(this.rfq.suppliers[suppId].parts[indexOfOldPart], 'priceHistory', newPart.priceHistory)
                }
              }
            })
          }
        }

        if (val && val.parts && !this.isImportingQuoteParts) {
          // if (val.parts.length < this.rfq.parts.length){
          //     this.loadRFQ(this.$route.params.rfq_id)
          //     return
          // }
          let idsForRemoving = []
          _.forEach(this.rfq.parts, oldPart => {
            let partForRemoving = _.find(val.parts, (p) => {
              return Number(p.id) === Number(oldPart.id)
            })
            if (!partForRemoving && oldPart.isManualByOemSupplier) {
              idsForRemoving.push(oldPart.id)
            }
          })

          _.forEach(idsForRemoving, (id) => {
            let ind = _.findIndex(this.rfq.parts, (p) => {
              return Number(p.id) === Number(id)
            })
            if (ind !== -1) {
              this.rfq.parts.splice(ind, 1)
            }
          })

          let isNeedUpdatePartsOrdering = false
          _.forEach(val.parts, newPart => {
            let oldPart = _.find(this.rfq.parts, (p) => {
              return Number(p.id) === Number(newPart.id)
            })
            if (oldPart) {
              let index = this.rfq.parts.indexOf(oldPart)
              if (oldPart.condition !== newPart.condition) {
                this.$set(this.rfq.parts[index], 'condition', newPart.condition)
              }
              if (oldPart.oemPartNumber !== newPart.oemPartNumber && newPart.hasOwnProperty('oemPartNumber')) {
                this.$set(this.rfq.parts[index], 'oemPartNumber', newPart.oemPartNumber)
              }
              if (!_.isEqual(oldPart.photoForPart, newPart.photoForPart)) {
                this.$set(this.rfq.parts[index], 'photoForPart', newPart.photoForPart)
              }

              if (!_.isEqual(oldPart.stock, newPart.stock) && (oldPart.stock == 7 || newPart.stock == 7)) {
                this.$set(this.rfq.parts[index], 'stock', newPart.stock)
              }

              if (!_.isEqual(oldPart.oemListPrice, newPart.oemListPrice) || (_.isEqual(oldPart.oemListPrice, newPart.oemListPrice) && (oldPart.stock == 7 || oldPart.stock == null))) {
                this.$set(this.rfq.parts[index], 'oemListPrice', newPart.oemListPrice)
                if (!_.isEqual(oldPart.price, newPart.price) || (_.isEqual(oldPart.price, newPart.price) && (oldPart.stock == 7 || oldPart.stock == null))) {
                  this.$set(this.rfq.parts[index], 'price', newPart.price)
                }
              }

              if (oldPart.isManualByOemSupplier) {
                if (!_.isEqual(oldPart.number, newPart.number)) {
                  this.$set(this.rfq.parts[index], 'number', newPart.number)
                }
                if (!_.isEqual(oldPart.name, newPart.name)) {
                  this.$set(this.rfq.parts[index], 'name', newPart.name)
                }
                if (!_.isEqual(oldPart.price, newPart.price)) {
                  this.$set(this.rfq.parts[index], 'price', newPart.price)
                }
                if (!_.isEqual(oldPart.qty, newPart.qty)) {
                  this.$set(this.rfq.parts[index], 'qty', newPart.qty)
                }
                if (!_.isEqual(oldPart.rrp, newPart.rrp)) {
                  this.$set(this.rfq.parts[index], 'rrp', newPart.rrp)
                }
                if (!_.isEqual(oldPart.stock, newPart.stock)) {
                  this.$set(this.rfq.parts[index], 'stock', newPart.stock)
                }
                if (!_.isEqual(oldPart.stockComment, newPart.stockComment)) {
                  this.$set(this.rfq.parts[index], 'stockComment', newPart.stockComment)
                }
                if (!_.isEqual(oldPart.listOrderNumber, newPart.listOrderNumber)) {
                  this.$set(this.rfq.parts[index], 'listOrderNumber', newPart.listOrderNumber)
                  isNeedUpdatePartsOrdering = true
                }
              }
            } else if (newPart.isManualByOemSupplier) {
              this.rfq.parts.push(new Part(newPart))
            }
          })
          if (isNeedUpdatePartsOrdering) {
            this.updatePartsOrdering()
          }

        }

        // if (val && val.parts) {
        //   let _parts = []
        //   _.forEach(val.parts, part => {
        //     _parts.push(new Part(part))
        //   })
        //   _parts = _.orderBy(_parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
        //  this.rfq.parts = _parts
        //   this.sortParts();
        // }
        if (val && val.estimator) {
          this.rfq.estimator = val.estimator
        }
        if (val && val.quoteValidTill && val.quoteValidTill !== this.rfq.quoteValidTill) {
          this.rfq.quoteValidTill = val.quoteValidTill
        }

        this.rfq.secondRequestBestPrice = val.secondRequestBestPrice
        this.rfq.secondRequestBestPriceTimeEnd = val.secondRequestBestPriceTimeEnd
        this.rfq.thirdRequestBestPrice = val.thirdRequestBestPrice
        this.rfq.thirdRequestBestPriceTimeEnd = val.thirdRequestBestPriceTimeEnd

        if (!_.isEqual(this.rfq.images, val.images)) {
          if (_.isEmpty(val.images)) {
            this.rfq.images = []
          } else {
            this.rfq.images = val.images
          }
        }

        if (!_.isEqual(this.rfq.lock, val.lock)) {
          let oldVal = _.cloneDeep(this.rfq.lock)
          this.rfq.lock = val.lock

          if (this.isLoadedRFQ && !oldVal?.active && this?.rfq?.lock?.active && this.isRFQNew && Number(this.rfq?.lock?.lockedBy) !== Number(this.currentUser?.id)) {
            setTimeout(() => {
              this.$refs.autoLockSettingsBlock.show()
            }, 100)
          }
        }

        if (!_.isEqual(this.rfq.files, val.files)) {
          if (_.isEmpty(val.files)) {
            this.rfq.files = []
          } else {
            this.filterRfqFiles(val.files)
            // this.rfq.files = val.files
          }
        }

        if (this.schemeImage) {
          let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
            return Number(f.id) === Number(this.schemeImage.id)
          })
          if (indexOfSchemeImg !== -1) {
            this.schemeImage = this.rfq.files[indexOfSchemeImg]
            this.$nextTick(() => {
              this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
            })
          }
        }

        if (!this.isClosedPreviewImage && !this.schemeImage) {
          let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
            return f.type === 'scheme'
          })
          if (indexOfSchemeImg !== -1) {
            this.schemeImage = this.rfq.files[indexOfSchemeImg]
            this.$nextTick(() => {
              this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
            })
          }
        }

        if (!_.isEqual(this.rfq.assignedTo, val.assignedTo)) {
          this.rfq.assignedTo = val.assignedTo
        }

        if (!_.isEqual(this.rfq.isRebateEnabled, val.isRebateEnabled)) {
          this.rfq.isRebateEnabled = val.isRebateEnabled
        }

        if (!_.isEqual(this.rfq.rebatePercent, val.rebatePercent)) {
          this.rfq.rebatePercent = val.rebatePercent
        }

        if (!_.isEqual(this.rfq.properties, val.properties)) {
          this.rfq.properties = val.properties
        }

        if (!this.isClaimNumberFieldFocused && !_.isEqual(this.rfq.claimNumber, val.claimNumber)) {
          this.rfq.claimNumber = val.claimNumber
        }

        if (this?.rfq?.properties?.isInEditMode) {
          this.priceResubmitActiveFlag = this.rfq.properties.isInEditMode
        }

        if (!_.isEqual(this.rfq.paintCodeForRepair, val.paintCodeForRepair) && !this.isPaintCodeInFocus) {
          this.rfq.paintCodeForRepair = val.paintCodeForRepair
          this.isNeedSavePaintCode = true
        }

        if (_.isEmpty(this.rfq.paintCodeForRepair) && !this.isPaintCodeInFocus) {
          this.showPlaceholderPaintCode = true
        } else {
          this.showPlaceholderPaintCode = false
        }

        //    if (!_.isEmpty(val)) this.rfq = new RFQ(val)
      },
      deep: true,
      immediate: true,
    },
    pinImage (val) {
      if (!val) this.shownGallery = val
      if (val) {
        let body = document.querySelector('body')
        if (body.classList.contains('compact-sidebar')) {
          this.isOldCompactSideBar = true
          body.classList.remove('compact-sidebar')
        } else {
          this.isOldCompactSideBar = false
        }

      } else {
        if (this.isOldCompactSideBar) {
          let body = document.querySelector('body')
          body.classList.add('compact-sidebar')
        }
      }
    }
  }
}
</script>
<template>
  <div class="supplier-rfq-view-page" :class="{'supplier-oem-gen-rfq-view-page': isPartsTypeOemGen}">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }}</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/s">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <span @click="viewQuotes" style="cursor: pointer;">View Quotes</span>
            </li>
            <li class="breadcrumb-item active">
              <span class="d-inline-block d-lg-none">Edit {{ rfq.status | maxFour }} Quote</span>
              <span class="d-none d-lg-inline-block">Edit {{ rfq.status }} Quote</span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <chat
              v-if="isShowChat"
              @closeChat="onCloseChat"
              :isShowChatInit="!!$route.params.isNeedOpenChat"
              :userName="rfq.repairer ? rfq.repairer : ''"
              :messages="chatMessages"
              :is-chatter-online="chatRepairerIsOnline"
              :typers="chatTypers"
              @onChatMessageSubmit="onChatMessageSubmit"
              @onTyping="onChatInputTyping"
              ref="supplier-rfq-view-chat"
              :class="{'chat-image-gallery active': selectedSchemeIndex != -1 && isCanShowSchemeImage}">
          </chat>
          <window-notification></window-notification>
          <chat
              v-if="isShowChat"
              @closeChat="onCloseChat"
              :isShowChatInit="!!$route.params.isNeedOpenChat"
              :userName="rfq.repairer ? rfq.repairer : ''"
              :messages="chatMessages"
              :is-chatter-online="chatRepairerIsOnline"
              :typers="chatTypers"
              @onChatMessageSubmit="onChatMessageSubmit"
              @onTyping="onChatInputTyping"
              ref="supplier-rfq-view-chat"
              :class="{'chat-image-gallery active': selectedSchemeIndex != -1 && isCanShowSchemeImage}">
          </chat>
          <search-filter
              type="estimates"
              v-model="searchFilter"
              :filterValue="searchFilter"
              :additional-menu="additionalMenu"
              :placeholder="'Search'"
              :isSearch="true"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="8"
          />
        </div>
      </div>
      <div class="">
        <sticky-header
            v-if="isShowStickyHeader && !isShowPdfNR"
            @onFocusPaintCode="onFocusPaintCode"
            @saveClaimNbr="saveClaimNbr"
            @onBlurPaintCode="onBlurPaintCode"
            @onQuoteNbrBlur="onQuoteNbrBlur"
            @selectedAllStock="selectedAllStock"
            @updatePaintCodeForRepair="updatePaintCodeForRepair"
            @viewOrder="viewOrder"
            @updateSupplierQuoteNumber="updateSupplierQuoteNumber"
            @selectQuoteNbr="selectQuoteNbr"
            @selectedPdf="selectedPdfNr"
            @removeQuoteNbr="removeQuoteNbr"
            @onFocusOrBlurClaimNbr="v => isClaimNumberFieldFocused = v"
            :isSelectedImportCSV="isSelectedImportCSV"
            :priceResubmitFlag="priceResubmitActiveFlag"
            :isQuoteNbrFocus="isQuoteNbrFocus"
            :isShowDirectComment="isShowDirectComment"
            :isSelectedImportDMS="isSelectedImportDMS"
            :showPlaceholderPaintCode="showPlaceholderPaintCode"
            :isRfqToUsed="isRfqToUsed"
            :isFlashingPaintCode="isFlashingPaintCode"
            :isRFQNew="isRFQNew"
            :isAdditionalRfq="isAdditionalRfq"
            :isRFQWon="isRFQWon"
            :isNewStatus="rfq.status === 'New'"
            :isRFQPriced="isRFQPriced"
            :priceResubmitActiveFlag="priceResubmitActiveFlag"
            :isLockedRfq="isLockedRfq"
            :computedUpdatedQuoteNbr="computedUpdatedQuoteNbr"
            :quoteNumbersFiltered="quoteNumbersFiltered"
            :activeSupplierId="activeSupplierId"
            :clickedSupplierId="clickedSupplierId"
            :activeColumnIndex="activeColumnIndex"
            @changedClickedSupplierId="changedClickedSupplierId"
            @changedActiveColumnIndex="changedActiveColumnIndex"
            @changedActiveSupplierId="changedActiveSupplierId"
            :rfq="rfq"
        ></sticky-header>
      </div>
    </div>
    <div class="rfq-view-body">
      <tabs
          ref="supplier-rfq-view-table"
          cache-lifetime="0"
          :isNeedSaveInStorage="false"
          :options="{ useUrlFragment: false, defaultTabHash: 'part-list' }"
          :custom-counts="computedCustomCountForTab"
          @changed="changedTab">
        <tab name="Vehicle Details" id="vehicle-details" :class-name="'tab-content-table'">
          <div class="row">
            <div class="col-12 col-lg-5 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="computedSteps"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>

            <div class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :date-due="rfq.dateDue"
                         :openDays="rfq.quoteOpenDays"
                         :isShowOpenDays="isRFQPriced"
                         :date-due-prop="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isGotUpdate || isSentUpdate" class="comments-blinks-text"
                        :class="{'active': isGotUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isGotUpdate && !isSentUpdate" class="col-9">
                  <template v-if="isAdditionalRfq">
                    <div
                        class="form-control"
                        style="font-weight: bold; height: auto;min-height: 48px"
                    >
                      <span>This is an addition to Repairer Quote |  {{ rfq.repairerQuoteNumber }}</span><br/>
                      <span v-if="isHasPreviousNbrs" style="color: red;">This is an additional to
                         <span v-for="(itm, ind) in rfq.supplierPreviousQuoteNumbers"
                               style="font-weight: bold">{{ itm }}<span
                             v-if="ind + 1 < rfq.supplierPreviousQuoteNumbers.length">, </span> </span>
                       </span>
                    </div>
                  </template>
                  <template
                      v-else-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !(isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram)">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div class="rfq-view-direct-order-comment" v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <template v-if="isPartsTypeOemGen">
                        <div class="send-direct-order-comment-block" style="min-height: auto">

                          <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                            <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                              <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                   v-html="rfq.directOrder.comment"></div>
                            </template>
                          </template>

                          <div class="p-2">
                            <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                              REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                            {{ rfq.vehicle.comments }}
                          </div>
                        </div>
                      </template>
                      <template v-else>
                         <textarea
                             class="form-control"
                             placeholder=""
                             rows="3"
                             :disabled="true">
                      </textarea>
                      </template>
                    </template>
                    <template v-else>
                      <textarea
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="true"
                          v-model="rfq.vehicle.comments">
                      </textarea>
                    </template>
                  </template>
                </div>
              </div>

              <div v-if="rfq && rfq.order && rfq.order.deliveryTo && rfq.order.deliveryTo.instruction"
                   class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Special Instruction
                  :</label>
                <div class="col-9 special-instruction-block font-inter--bold">
                  <div v-html="rfq.order.deliveryTo.instruction"></div>
                </div>
              </div>

              <template
                  v-if="isRFQPriced  && (rfq.secondRequestBestPrice === true || rfq.secondRequestBestPrice === false ||  rfq.thirdRequestBestPrice === true || rfq.thirdRequestBestPrice === false)">
                <best-price-countdown
                    :endTime="rfq.thirdRequestBestPriceTimeEnd || rfq.secondRequestBestPriceTimeEnd"
                    :isSecondStart="(rfq.secondRequestBestPrice === true)"
                    :isSecondEnd="(rfq.secondRequestBestPrice === false)"
                    :isThirdStart="(rfq.thirdRequestBestPrice === true && rfq.secondRequestBestPrice === false)"
                    :isThirdEnd="(rfq.thirdRequestBestPrice === false && rfq.secondRequestBestPrice === false)"
                    :isSupplier = true
                    @endCountdown="endCountdown"
                />
              </template>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  label-business="Customer"
                  label-address="Deliver To"
                  :customer="rfq.repairer"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <div class="vehicle-details-block block">
            <div class="block-header bg-navyblue">
              Vehicle Details
            </div>
            <div class="block-body bg-white">
              <div class="form-group row">
                <label class="col col-form-label">Repairer Quoting Package Nbr</label>
                <div class="col-8">
                  <input type="text" class="form-control" v-model="rfq.QPNumber" :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Repairer Name</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder="" v-model="rfq.repairer"
                         :disabled="true">
                </div>
              </div>


              <div class="form-group row">
                <label class="col col-form-label">Rego</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder="" v-model="rfq.vehicle.rego"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">VIN Number</label>
                <div class="col-8">
                  <input type="text" class="form-control" v-model="rfq.vehicle.vin" :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Build Date</label>
                <div class="col-8">
                  <input type="text" class="form-control" v-model="rfq.vehicle.dom" :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Make</label>
                <div class="col-8">
                  <input type="text" class="form-control" v-model="rfq.vehicle.make" :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Model</label>
                <div class="col-8">
                  <input type="text" class="form-control" v-model="rfq.vehicle.model"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Series</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder="" v-model="rfq.vehicle.series"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Colour</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder="" v-model="rfq.vehicle.colour"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Body</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder="" v-model="rfq.vehicle.body"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Transmission</label>
                <div class="col-8">
                  <select class="form-control" v-model="rfq.vehicle.transmission"
                          placeholder="Select vehicle transmission" :disabled="true">
                    <option value="Automatic">Automatic</option>
                    <option value="Manual">Manual</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Odometer</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder=""
                         v-model="rfq.vehicle.odometer"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Estimator</label>
                <div class="col-8">
                  <input type="text" class="form-control" placeholder=""
                         v-model="rfq.estimator"
                         :disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Cutt-Off for Quote</label>
                <div class="col-8">
                  <input :value="rfq.cuttOff | formatTimeDate" type="text" class="form-control"
                         placeholder=""
                         :disabled="true">
                </div>
              </div>

            </div>
          </div>
        </tab>
        <tab name="Part List" id="part-list" :class-name="'tab-content-table part-list-tab-supplier'">

          <div class="row" :class="{'import-dms-additional-block': isSelectedImportDMS || isSelectedImportCSV}">
            <template v-if="!isSelectedImportDMS && !isSelectedImportCSV">
              <div class="col-12 col-lg-5 part-list-qoute-pricing-progress">
                <quote-pricing-progress
                    :steps="computedSteps"
                    :date-due-prop="rfq.dateDue"
                    :step="currentQPPStep">
                </quote-pricing-progress>
              </div>

              <div class="w-100 d-block d-xl-none"></div>

              <div class="col-12 col-sm-6 col-lg-4">
                <rfq-timer :countdown="computedDueDate"
                           :date-due-prop="rfq.dateDue"
                           :openDays="rfq.quoteOpenDays"
                           :isShowOpenDays="isRFQPriced"
                           :date-due="rfq.dateDue"
                >
                </rfq-timer>
                <div class="form-group row">
                  <label class="col col-form-label pl-0 color-lightgray"
                         style="font-size: 1rem; white-space: nowrap;">Comments :
                    <span v-if="isGotUpdate || isSentUpdate" class="comments-blinks-text"
                          :class="{'active': isGotUpdate}">Update RFQ <span
                        class="comments-after-blinks-text">requested</span></span>
                  </label>
                  <div v-if="!isGotUpdate && !isSentUpdate" class="col-9">
                    <template v-if="isAdditionalRfq">
                      <div
                          class="form-control"
                          style="font-weight: bold; height: auto;min-height: 48px"
                      >
                        <span>This is an addition to Repairer Quote |  {{ rfq.repairerQuoteNumber }}</span><br/>
                        <span v-if="isHasPreviousNbrs" style="color: red;">This is an additional to
                         <span v-for="(itm, ind) in rfq.supplierPreviousQuoteNumbers"
                               style="font-weight: bold">{{ itm }}<span
                             v-if="ind + 1 < rfq.supplierPreviousQuoteNumbers.length">, </span> </span>
                       </span>
                      </div>
                    </template>
                    <template
                        v-else-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !(isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram)">
                      <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                        <div class="rfq-view-direct-order-comment" v-html="rfq.directOrder.comment"></div>
                      </template>
                    </template>

                    <template v-else>
                      <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                        <template v-if="isPartsTypeOemGen">
                          <div class="send-direct-order-comment-block" style="min-height: auto">

                            <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                              <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                                <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                     v-html="rfq.directOrder.comment"></div>
                              </template>
                            </template>

                            <div class="p-2">
                              <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                                REQUEST</b></div>
                              <div class="pb-1">
                                <span style="font-weight: 600;">Comment: </span>
                              </div>
                              <span>{{ rfq.vehicle.comments }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                         <textarea
                             class="form-control"
                             placeholder=""
                             rows="3"
                             :disabled="true">
                      </textarea>
                        </template>
                      </template>
                      <template v-else>
                      <textarea
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="true"
                          v-model="rfq.vehicle.comments">
                                          </textarea>
                      </template>
                    </template>
                  </div>
                </div>
                <template
                    v-if="isRFQPriced  && (rfq.secondRequestBestPrice === true || rfq.secondRequestBestPrice === false ||  rfq.thirdRequestBestPrice === true || rfq.thirdRequestBestPrice === false)">
                  <best-price-countdown
                      :endTime="rfq.thirdRequestBestPriceTimeEnd || rfq.secondRequestBestPriceTimeEnd"
                      :isSecondStart="(rfq.secondRequestBestPrice === true)"
                      :isSecondEnd="(rfq.secondRequestBestPrice === false)"
                      :isThirdStart="(rfq.thirdRequestBestPrice === true && rfq.secondRequestBestPrice === false)"
                      :isThirdEnd="(rfq.thirdRequestBestPrice === false && rfq.secondRequestBestPrice === false)"
                      :isSupplier = true
                      @endCountdown="endCountdown"
                  />
                </template>

              </div>
            </template>
            <div
                v-else-if="(isSelectedImportDMS || isSelectedImportCSV) && !isLoading && !isLoadPartsForImporting && !isStartImportingParts"
                style="text-align: center" class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
              <p style="padding-top: 15px; font-weight: bold; font-size: 14px">Import DMS Quote <span
                  v-if="isSelectedImportCSV">CSV</span> <span v-else>PDF</span></p>
              <p><strong>Recommendation:</strong> Your Quote Part descriptions and your customers<br/>
                list order to
                match. Additional parts, linked or Alternative Names can be<br/> added after import.</p>
            </div>
            <div v-else-if="(isSelectedImportDMS || isSelectedImportCSV) && isLoading"
                 style="text-align: center; height: 120px; padding-top: 50px;"
                 class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
              <p style="font-size: 14px; font-weight: bold">Importing and Preparing your Quote</p>
            </div>
            <div
                v-else-if="(isSelectedImportDMS || isSelectedImportCSV) && !isLoading && isLoadPartsForImporting && !isStartImportingParts"
                style="text-align: center; height: 120px; padding-top: 34px;"
                class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
              <p style="font-size: 14px; font-weight: bold">Imported Quoted Check</p>
              <p>Confirm the Quote information below is correct</p>
            </div>
            <div
                v-else-if="(isSelectedImportDMS || isSelectedImportCSV) && !isLoading && isLoadPartsForImporting && isStartImportingParts"
                style="text-align: center; height: 120px; padding-top: 50px;"
                class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
              <p style="font-size: 14px; font-weight: bold">Importing Your Quote</p>
            </div>
            <div class="col-12 col-lg-3 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  label-business="Customer"
                  label-address="Deliver To"
                  :customer="rfq.repairer"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="d-flex">
              <div v-if="isLockedRfq && rfq.assignedTo && rfq.assignedTo.id" style="margin: 15px;">
                <span class="color-lightgray part-list-assigned-to-text">Assigned to:  </span>
                <span class="part-list-assigned-to-text ml-1">{{ rfq.assignedTo.name }}</span>
              </div>

              <div style="margin: 15px;">
                <span style="font-weight: 700"
                      class="color-lightgray part-list-assigned-to-text">Currently Viewing:  </span>
                <span v-for="(user, index) in currentlyViewing" :key="user && user.id ? user.id : getRandom()"
                      style="color: #29BBC1;" class="part-list-assigned-to-text ml-1">
                            {{ user.name }}<span v-if="index + 1 !== currentlyViewing.length">, </span>
                          </span>
              </div>


              <div v-if="isShowLockedTo" style="margin: 15px;">
                <span style="font-weight: 700" class="color-lightgray part-list-assigned-to-text">Locked To:  </span>
                <span style="color: #29BBC1;" class="part-list-assigned-to-text ml-1">
                            {{ computedLockedName }}
                          </span>
              </div>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <div v-if="!isSelectedImportDMS && !isSelectedImportCSV && !isShowPdfNR" id="additional-info"
               class="d-flex flex-row align-items-center justify-content-between additional-info flex-wrap">
            <div class="d-flex align-items-center">
              <vin-date :vin="rfq.vehicle.vin"
                        :date="rfq.vehicle.dom ? rfq.vehicle.dom : ''"
                        :hideTrackCode="true"
                        style="margin: 2px 0;"></vin-date>
              <template v-if="isRfqToUsed">
                <div class="vehicle-details-information-block">
                  <p>Rego: {{ rfq.vehicle.rego }}</p>
                  <p>Make: {{ rfq.vehicle.make }}</p>
                  <p>Model: {{ rfq.vehicle.model }}</p>
                  <p>Series: {{ rfq.vehicle.series }}</p>
                  <p>Body: {{ rfq.vehicle.body }}</p>
                </div>
              </template>
              <template v-if="rfq && rfq.properties && rfq.properties.isAskForPaintCode">
                <b-form-group label="Paint Code"
                              label-for="paint-code"
                              class="m-0 paint-code d-flex align-items-center"
                              style="padding-top:5px"
                              label-cols="auto">
                  <b-form-input ref="paint-code-field"
                                id="paint-code"
                                class="paint-code-input"
                                @focus="onFocusPaintCode"
                                @blur="onBlurPaintCode"
                                :class="{ 'border-for-paint-code': isFlashingPaintCode}"
                                style="width: 138px; font-size: 10px; background-color: rgba(41, 187, 193, 0.1);"
                                v-model="rfq.paintCodeForRepair"
                                @change="updatePaintCodeForRepair()">
                  </b-form-input>
                  <div v-if="showPlaceholderPaintCode" @click="onFocusPaintCode" class="paint-code-placeholder">Paint
                    Code Requested
                  </div>
                </b-form-group>
              </template>
            </div>
            <div class="d-flex px-15  flex-wrap">
              <b-form-group v-if="rfq && rfq.properties && rfq.properties.isEnableNillStock"
                            label="Claim Nbr:"
                            label-for="quote-nbr-field"
                            class="m-0  quote-nbr d-flex align-items-center"
                            style="padding-top:5px"
                            label-cols="auto">
                <b-form-input id="quote-nbr-field"
                              style="width: 138px;"
                              :disabled="false"
                              @change="saveClaimNbr"
                              v-model="rfq.claimNumber"
                              @focus="isClaimNumberFieldFocused = true"
                              @blur="isClaimNumberFieldFocused = false"
                              class="font-13">
                </b-form-input>
              </b-form-group>


              <template v-if="isAdditionalRfq && isHasPreviousNbrs">
                <div class="d-flex flex-column" style="padding-bottom: 0px !important; padding-top: 20px">
                  <b-form-group label="Quote Nbr:"
                                label-for="quote-nbr-field"
                                class="m-0  quote-nbr d-flex align-items-center"
                                style="padding:2px 0;"
                                label-cols="auto">
                    <b-form-input id="quote-nbr-field"
                                  style="width: 138px; margin-right: 10px; margin-left: 10px;"
                                  class="font-13"
                                  :disabled="!(isRFQNew || (isRFQPriced && (priceResubmitActiveFlag || isUnLockedSupplierMain()))) || isLockedRfq"
                                  @focus="isQuoteNbrFocus = true; isQuoteNbrFocus2 = true"
                                  @blur="isQuoteNbrFocus2 = false"
                                  v-closable2="{
                                exclude: ['quoteNbrsDropdown'],
                                handler: 'onQuoteNbrBlur'
                                              }" v-model="rfq.supplierQuoteNumber"
                                  autocomplete="off"
                                  @change="updateSupplierQuoteNumber()">
                    </b-form-input>
                    <i
                        v-if="!isEmpty(computedUpdatedQuoteNbr)"
                        class="bx bx-refresh blinking-green"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', title: ' Update Received <br/>' + formatDate(computedUpdatedQuoteNbr.datetime), html: true, placement:'leftbottom'}"
                    ></i>
                    <div ref="quoteNbrsDropdown" class="quote-number-menu"
                         v-if="!isEmpty(quoteNumbersFiltered) && isQuoteNbrFocus && !rfq.undoAvailable">
                      <div v-for="(nbr, key) in quoteNumbersFiltered" :key="key"
                           class="quote-number-menu-item d-flex justify-content-between" @click="selectQuoteNbr(nbr)">
                        <span>{{ nbr }}</span>
                        <i @click="removeQuoteNbr(nbr)" class="bx bx-trash quote-number-close"></i>
                      </div>
                    </div>
                  </b-form-group>
                  <div v-if="isHasPreviousNbrs" class="previous-quote" style="color: red; padding-left: 3px">Previous
                    Quote: <span style="font-weight: bold">{{ rfq.supplierPreviousQuoteNumbers[0] }}</span></div>
                </div>

              </template>
              <template v-else>
                <b-form-group label="Quote Nbr:"
                              label-for="quote-nbr-field"
                              class="m-0  quote-nbr d-flex align-items-center"
                              style="padding:2px 0;"
                              label-cols="auto">
                  <b-form-input id="quote-nbr-field"
                                style="width: 138px; margin-right: 10px; margin-left: 10px;"
                                class="font-13"
                                :disabled="!(isRFQNew || (isRFQPriced && (priceResubmitActiveFlag || isUnLockedSupplierMain()))) || isLockedRfq"
                                @focus="isQuoteNbrFocus = true; isQuoteNbrFocus2 = true"
                                @blur="isQuoteNbrFocus2 = false"
                                v-closable2="{
                                exclude: ['quoteNbrsDropdown'],
                                handler: 'onQuoteNbrBlur'
                              }"
                                v-model="rfq.supplierQuoteNumber"
                                autocomplete="off"
                                @change="updateSupplierQuoteNumber()">
                  </b-form-input>
                  <i
                      v-if="!isEmpty(computedUpdatedQuoteNbr)"
                      class="bx bx-refresh blinking-green"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip', title: ' Update Received <br/>' + formatDate(computedUpdatedQuoteNbr.datetime), html: true, placement:'leftbottom'}"
                  ></i>
                  <div ref="quoteNbrsDropdown" class="quote-number-menu"
                       v-if="!isEmpty(quoteNumbersFiltered) && isQuoteNbrFocus && !rfq.undoAvailable">
                    <div v-for="(nbr, key) in quoteNumbersFiltered" :key="key"
                         class="quote-number-menu-item d-flex justify-content-between"
                         @click="selectQuoteNbr(nbr)">
                      <span>{{ nbr }}</span>
                      <i @click="removeQuoteNbr(nbr)" class="bx bx-trash quote-number-close"></i>
                    </div>
                  </div>
                </b-form-group>
              </template>
              <b-form-group label="Order Nbr:"
                            v-if="isRFQWon"
                            class="m-0  quote-nbr d-flex align-items-center rfq-order-nbr-group"
                            style="padding:2px 0; margin-bottom: 0px !important;"
                            label-cols="auto">
                <span id="quote-nbr-field"
                      @click="viewOrder"
                      style="width: 138px; margin-right: 10px; margin-left: 0px; transform: translateY(5px)"
                      class="font-13 rfq-order-number">
                  {{ rfq.order.number }}
                </span>
                <i
                    v-if="!isEmpty(computedUpdatedQuoteNbr)"
                    class="bx bx-refresh blinking-green"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: ' Update Received <br/>' + formatDate(computedUpdatedQuoteNbr.datetime), html: true, placement:'leftbottom'}"
                ></i>
                <div ref="quoteNbrsDropdown" class="quote-number-menu"
                     v-if="!isEmpty(quoteNumbersFiltered) && isQuoteNbrFocus && !rfq.undoAvailable">
                  <div v-for="(nbr, key) in quoteNumbersFiltered" :key="key"
                       class="quote-number-menu-item d-flex justify-content-between"
                       @click="selectQuoteNbr(nbr)">
                    <span>{{ nbr }}</span>
                    <i @click="removeQuoteNbr(nbr)" class="bx bx-trash quote-number-close"></i>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="Repairer Quote Nbr:"
                            label-for="quote-nbr-field"
                            class="m-0  quote-nbr d-flex align-items-center"
                            style="padding:2px 0"
                            label-cols="auto">
                <b-form-input id="quote-nbr-field"
                              style="width: 138px; margin-left: 10px;"
                              :disabled="true"
                              class="font-13"
                              :value="isAdditionalRfq ? 'Addition to ' + rfq.repairerQuoteNumber :  rfq.repairerQuoteNumber">
                </b-form-input>
              </b-form-group>

            </div>
          </div>

          <div v-if="!isSelectedImportDMS && !isSelectedImportCSV && !isShowPdfNR" class="d-flex flex-row flex-wrap">
            <part-list-view
                ref="partLV"
                v-if="rfq.id"
                :rfq="rfq"
                :isLockedRfq="isLockedRfq"
                :importedParts="computedImportedParts"
                :price-resubmit-flag="priceResubmitActiveFlag"

                :is-can-edit-priced-quote="isCanEditPricedQuote"
                :isRFQNew="isRFQNew"

                :isAdditionalRfq="isAdditionalRfq"
                :isPartListTab="activeTab == 'Part List'"
                @addedImportedParts="addedImportedParts"
                @priced="partListPriced()"
                @declinedOrder="$refs.declineModal.show()"
                @addOptional="addOptional"
                @setOptionalType="setOptionalType"
                @resubmitting="priceResubmitActiveFlag = false"
                @changedClickedSupplierId="changedClickedSupplierId2"
                @changedActiveColumnIndex="changedActiveColumnIndex2"
                @changedActiveSupplierId="changedActiveSupplierId2"@selectedPdf="selectedPdfNr"
            >
            </part-list-view>

            <template
                v-if="isShowDirectComment && ((rfq && rfq.directOrder && rfq.directOrder.comment && _isEmpty(rfq.suppliers)) || (isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram))">

              <template
                  v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !(isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram)">
                <div :style="{width: getDirectBlockWidth()}" class="rfq-view-direct-order-comment direct-commnet-block"
                     style="margin-top: 14px;" v-html="rfq.directOrder.comment"></div>
              </template>

              <template v-else>
                <template v-if="(rfq && rfq.properties && rfq.properties.isAskForPartsDiagram)">
                  <template v-if="isPartsTypeOemGen">

                    <div class="send-direct-order-comment-block direct-commnet-block" style="margin-top: 14px; min-height: max-content !important; max-height: max-content !important;"
                         :style="{width: getDirectBlockWidth()}">

                      <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                        <div class="rfq-view-direct-order-comment direct-commnet-block rfq-view-direct-order-comment-2"
                             v-html="rfq.directOrder.comment"></div>
                      </template>

                      <div class="p-2">
                        <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                          REQUEST</b></div>
                        <div class="pb-1">
                          <span style="font-weight: 600;">Comment: </span>
                        </div>
                        <span>{{ rfq.vehicle.comments }}</span>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </template>
          </div>
          <rfq-import
              v-if="isSelectedImportDMS || isSelectedImportCSV"
              :vin="rfq.vehicle.vin"
              :buildDate="rfq.vehicle.dom"
              :params="{
                rfqId: rfq.id
              }"
              :repairer-parts="rfq.parts"
              :format-files="isSelectedImportCSV ? '.csv' : 'application/pdf'"
              :isAutoConfirm="isAutoConfirm"
              :loadedParts="loadedPDFFiles"
              @loadParts="isLoadPartsForImporting = true;"
              @changedLoading="(value) => isLoading = value"
              @startImporting="(value) => isStartImportingParts = value"
              @confirmedImport="confirmedImport"
              @clickCancel="undoQuote"
              @addImportedPdf="addImportedPdf"
              @addSupplierQuoteNumber=updateSupplierQuoteNumber
              @closeWizard="
                              loadedPDFFiles = [];
                              isSelectedImportDMS = false;
                              isSelectedImportCSV = false;
                              isLoading = false;
                              isLoadPartsForImporting = false;
                              isStartImportingParts = false;">
          </rfq-import>

          <pdf-viewer
              v-if="isShowPdfNR"
              :documentHeight="documentHeight"
              :documentWidth="documentWidth"
              :documentPath="selectedNRPDFUrl"
              :isShowPrint="false"
              :isShowDownload="false"
              :id="'pdfViewer2'"
              @closePdf="isShowPdfNR = false"
          ></pdf-viewer>
        </tab>
        <tab name="Images" id="images" :class-name="'tab-content-table'">
          <div class="row">
            <div class="col-12 col-lg-5 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="computedSteps"
                  :date-due-prop="rfq.dateDue"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>

            <div class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :date-due-prop="rfq.dateDue"
                         :date-due="rfq.dateDue"
                         :openDays="rfq.quoteOpenDays"
                         :isShowOpenDays="isRFQPriced"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isGotUpdate || isSentUpdate" class="comments-blinks-text"
                        :class="{'active': isGotUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isGotUpdate && !isSentUpdate" class="col-9">
                  <template v-if="isAdditionalRfq">
                    <div
                        class="form-control"
                        style="font-weight: bold; height: auto;min-height: 48px"
                    >
                      <span>This is an addition to Repairer Quote |  {{ rfq.repairerQuoteNumber }}</span><br/>
                      <span v-if="isHasPreviousNbrs" style="color: red;">This is an additional to
                         <span v-for="(itm, ind) in rfq.supplierPreviousQuoteNumbers"
                               style="font-weight: bold">{{ itm }}<span
                             v-if="ind + 1 < rfq.supplierPreviousQuoteNumbers.length">, </span> </span>
                       </span>
                    </div>
                  </template>
                  <template
                      v-else-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !(isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram)">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div class="rfq-view-direct-order-comment" v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>

                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <template v-if="isPartsTypeOemGen">
                        <div class="send-direct-order-comment-block" style="min-height: auto">

                          <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                            <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                              <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                   v-html="rfq.directOrder.comment"></div>
                            </template>
                          </template>

                          <div class="p-2">
                            <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                              REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                            <span>{{ rfq.vehicle.comments }}</span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                         <textarea
                             class="form-control"
                             placeholder=""
                             rows="3"
                             :disabled="true">
                      </textarea>
                      </template>
                    </template>
                    <template v-else>
                      <textarea
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="true"
                          v-model="rfq.vehicle.comments">
                      </textarea>
                    </template>
                  </template>
                </div>
              </div>

              <template
                  v-if="isRFQPriced  && (rfq.secondRequestBestPrice === true || rfq.secondRequestBestPrice === false ||  rfq.thirdRequestBestPrice === true || rfq.thirdRequestBestPrice === false)">
                <best-price-countdown
                    :endTime="rfq.thirdRequestBestPriceTimeEnd || rfq.secondRequestBestPriceTimeEnd"
                    :isSecondStart="(rfq.secondRequestBestPrice === true)"
                    :isSecondEnd="(rfq.secondRequestBestPrice === false)"
                    :isThirdStart="(rfq.thirdRequestBestPrice === true && rfq.secondRequestBestPrice === false)"
                    :isThirdEnd="(rfq.thirdRequestBestPrice === false && rfq.secondRequestBestPrice === false)"
                    :isSupplier = true
                    @endCountdown="endCountdown"
                />
              </template>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  label-business="Customer"
                  label-address="Deliver To"
                  :customer="rfq.repairer"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <div class="d-flex flex-row align-items-center justify-content-between pt-15 px-15">
            <vin-date :vin="rfq.vehicle.vin"
                      :date="rfq.vehicle.dom ? rfq.vehicle.dom : ''"
                      :hideTrackCode="true"
                      class="m-0"></vin-date>
            <b-form-group label="Repairer Quote Nbr"
                          style="display: none"
                          label-for="quote-nbr-field"
                          class="font-13 m-0"
                          label-cols="4">
              <b-form-input id="quote-nbr-field"
                            class="font-13"
                            :value="isAdditionalRfq ? 'Addition to ' + rfq.repairerQuoteNumber :  rfq.repairerQuoteNumber">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="image-block block p-0">
            <div class="block-header bg-navyblue">
              Images
            </div>
            <image-lightbox
                :isRemove="false"
                :isEdit="false"
                :isDownload="false"
                :isPreviewImage="true"
                :position="''"
                :imageList="imageSource"
                :path="''"
                class="block-body"
            ></image-lightbox>
          </div>
        </tab>

        <tab
            :isShow="!!(rfq && rfq.properties && rfq.properties.isEnableNillStock && rfq.files.length) || (isPartsTypeOemGen && (isRFQWon || isRFQOrdered || isRFQNew || isRFQPriced))"
            name="Files"
            id="files"
            :class-name="'tab-content-table files-tab-supplier'">

          <div v-if="!isShowPdf" class="row">
            <div class="col-12 col-lg-5 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="computedSteps"
                  :date-due-prop="rfq.dateDue"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>

            <div class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :date-due-prop="rfq.dateDue"
                         :openDays="rfq.quoteOpenDays"
                         :isShowOpenDays="isRFQPriced"
                         :date-due="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isGotUpdate || isSentUpdate" class="comments-blinks-text"
                        :class="{'active': isGotUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isGotUpdate && !isSentUpdate" class="col-9">
                  <template v-if="isAdditionalRfq">
                    <div
                        class="form-control"
                        style="font-weight: bold; height: auto;min-height: 48px"
                    >
                      <span>This is an addition to Repairer Quote |  {{ rfq.repairerQuoteNumber }}</span><br/>
                      <span v-if="isHasPreviousNbrs" style="color: red;">This is an additional to
                         <span v-for="(itm, ind) in rfq.supplierPreviousQuoteNumbers"
                               style="font-weight: bold">{{ itm }}<span
                             v-if="ind + 1 < rfq.supplierPreviousQuoteNumbers.length">, </span> </span>
                       </span>
                    </div>
                  </template>
                  <template
                      v-else-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !(isPartsTypeOemGen && rfq.properties.isAskForPartsDiagram)">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div class="rfq-view-direct-order-comment" v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <template v-if="isPartsTypeOemGen">
                        <div class="send-direct-order-comment-block" style="min-height: auto">

                          <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                            <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                              <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                   v-html="rfq.directOrder.comment"></div>
                            </template>
                          </template>

                          <div class="p-2">
                            <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                              REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                            <span>{{ rfq.vehicle.comments }}</span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                         <textarea
                             class="form-control"
                             placeholder=""
                             rows="3"
                             :disabled="true">
                      </textarea>
                      </template>
                    </template>
                    <template v-else>
                      <textarea
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="true"
                          v-model="rfq.vehicle.comments">
                      </textarea>
                    </template>
                  </template>
                </div>
              </div>

              <template
                  v-if="isRFQPriced  && (rfq.secondRequestBestPrice === true || rfq.secondRequestBestPrice === false ||  rfq.thirdRequestBestPrice === true || rfq.thirdRequestBestPrice === false)">
                <best-price-countdown
                    :endTime="rfq.thirdRequestBestPriceTimeEnd || rfq.secondRequestBestPriceTimeEnd"
                    :isSecondStart="(rfq.secondRequestBestPrice === true)"
                    :isSecondEnd="(rfq.secondRequestBestPrice === false)"
                    :isThirdStart="(rfq.thirdRequestBestPrice === true && rfq.secondRequestBestPrice === false)"
                    :isThirdEnd="(rfq.thirdRequestBestPrice === false && rfq.secondRequestBestPrice === false)"
                    :isSupplier = true
                    @endCountdown="endCountdown"
                />
              </template>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  label-business="Customer"
                  label-address="Deliver To"
                  :customer="rfq.repairer"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <div v-if="!isShowPdf" class="d-flex flex-row align-items-center justify-content-between pt-15 px-15">
            <vin-date :vin="rfq.vehicle.vin"
                      :date="rfq.vehicle.dom ? rfq.vehicle.dom : ''"
                      :hideTrackCode="true"
                      class="m-0"></vin-date>
            <b-form-group label="Repairer Quote Nbr"
                          style="display: none"
                          label-for="quote-nbr-field"
                          class="font-13 m-0"
                          label-cols="4">
              <b-form-input id="quote-nbr-field"
                            class="font-13"
                            :value="isAdditionalRfq ? 'Addition to ' + rfq.repairerQuoteNumber :  rfq.repairerQuoteNumber">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="row"
               :class="{'supplier-view-files-wrapper': (isPartsTypeOemGen && isCanShowSchemeImage) || isHasSchemeImage}">
            <file-upload
                v-if="rfq.id"
                :object-id="rfq.id"
                :isNeedChangePosition="false"
                ref="files-upload"
                :isSelectable="true"
                :isDiagramsLayout="isPartsTypeOemGen && isCanShowSchemeImage"
                :isRepairerDiagram="isHasSchemeImage"
                @selectedRows="selectedFilesRows"
                @updatedFiles="filterRfqFiles"
                :documentHeight="documentHeight"
                :documentWidth="documentWidth"
                @openPDF="openPdf"
                :isQuotes="true"
                :isSupplierRfqViewPage="true"
                :readOnly="!isPartsTypeOemGen"
                type="file"
                :params="{
                rfqId: rfq.id
              }"
                @removedLastFile="removedLastFile"
                :files-accepted="computedFilesAccepted"
                :api-urls="{uploadFile: '/ir/supplier/rfq/uploadFile', main: '/ir/supplier/rfq/'}"
                v-model="rfq.files"
                :class="{'col-lg-6': isPartsTypeOemGen && isCanShowSchemeImage}">
            </file-upload>

            <template v-if="selectedSchemeIndex != -1 && isCanShowSchemeImage && !isShowPdf">
              <div class="row">
                <div class="col-9">
                  <image-gallery :use-editor="true"
                                 :isDownload="true"
                                 :isRemove="false"
                                 :images="computedSchemeImages"
                                 :pinnedImage="[]"
                                 :rfqId="rfq.id"
                                 ref="image-gallery"
                                 :current-index="selectedSchemeIndex"
                                 @close-gallery="selectedSchemeIndex = -1"
                  >
                  </image-gallery>
                </div>
                <div class="col-3"></div>
              </div>
            </template>

            <div v-if="isPartsTypeOemGen && isCanShowSchemeImage && !isShowPdf" class="col-lg-6 files-diagram-wrapper">
              <div
                  v-if="!(isHasSchemeImage && schemeImage)"
                  class="files-diagram-block"
                  :class="{
                   'files-diagram-block-active': images.length == 0
                  }"
              >
                <vue-dropzone
                    v-on:vdropzone-file-added="addImgUpload"
                    v-on:vdropzone-complete="completeImgUpload"
                    v-on:vdropzone-success="successImgUpload"
                    v-on:vdropzone-sending="sendImgUpload"
                    ref="imgUploadDropMyAccount"
                    id="imgUploadDropMyAccount"
                    class="dropify-wrapper dropzoneContainer diagram-block-image diagram-block-image-not-clickable dropzone-width-50"
                    :options="myAccountDropzoneOptions"
                    :useCustomSlot="true"
                >
                  <div v-if="images.length == 0" class="dropify-message" style="transform: translateY(0%);">
                    <p class="upload-title">Paste Image Here...</p>
                  </div>
                  <template v-else>
                    <img :src="images[images.length - 1]" class="diagram-image"/>
                  </template>
                </vue-dropzone>

                <div class="diagram-block-line"></div>

                <div class="diagram-block-button">
                  <button type="button" @click="saveSchemeImg()"
                          class="btn btn-primary waves-effect waves-light browse-btn" :disabled="!uploadedFileId">Save
                  </button>
                  <span class="ml-5">Use for Pasting Parts Diagrams direct from screenshot tools.</span>
                </div>
              </div>

              <div v-else class="files-diagram-block">

                <img
                    :src="schemeImage.previewUrl"
                    @click="selectSchemeImage(false)"
                    style="padding-bottom: 0px;"
                    class="diagram-image"/>
                <div class="w-100 d-flex justify-content-end">
                  <button type="button" @click="selectSchemeImage(true)" style="margin: 15px"
                          class="btn btn-primary waves-effect waves-light browse-btn">Edit
                  </button>
                </div>
              </div>
            </div>
          </div>


        </tab>

        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <label class="form-check-inline checkbox nav-pin-images">
              <input class="form-check-input" name="pinimage" v-model="pinImage"
                     @change="$store.commit('supplier/rfq/updatePinImage', pinImage)" type="checkbox">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Pin Images</span>
            </label>
          </div>
          <div class="tabs-nav-right-options">
            <b-dropdown id="supplier-rfq-view-options" ref="optionMenu" text="Options" @hide="onHideDropdown"
                        class="dropdown-options dropdown-actions">
              <template v-slot:button-content>Actions
                <span class="dropdown-arrow">
                                    <i class='bx bx-down-arrow'></i>
                                </span>
              </template>
              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isCanEditPricedQuote"
                                 :disabled="isLockedRfq"
                                 :class="{'disabled-item': isLockedRfq}"
                                 @click="priceResubmitActiveFlag = true">
                  Edit Priced Quote
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="(isRFQNew || (isRFQPriced && (priceResubmitActiveFlag || isUnLockedSupplierMain()))) && rfq.undoAvailable"
                                 @click="undoQuote"
                                 :disabled="(!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq"
                                 :class="{'disabled-item': (!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq}"
                                 class="import-dms-option">
                  Undo Quote Import
                </b-dropdown-item>
              </div>

              <div class="" @mouseover="showOrHideAssignToContext(true)" @mouseleave="showOrHideAssignToContext(false)">
                <b-dropdown-item
                    :disabled="isLockedRfq"
                    :class="{'disabled-item': isLockedRfq}"
                    class="assign-to-option">
                  <i style="top: 3px; left: 2px;" class='bx bx-chevron-left font-18'></i>Assign To
                </b-dropdown-item>
                <AssignTo
                    v-if="isShowAssignedToContext"
                    :users="computedUsersForAssignedFiltered"
                    :isDisabled="isLockedRfq"
                    :className="'assign-to-context'"
                    :style="{
                      top: getTopForAssignToContext() + 'px'
                    }"
                    @click="clickOnAssignedTo"
                ></AssignTo>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item
                    v-if="(isRFQNew || (isRFQPriced && (priceResubmitActiveFlag || isUnLockedSupplierMain()))) && !rfq.undoAvailable && !isSelectedImportDMS && isEnableSettingsRfqImportPdfQuote"
                    :disabled="(!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq"
                    :class="{'disabled-item': (!isRfqToOemGen &&  rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq}"
                    @click="importQuote('dms')" class="import-dms-option">
                  Import DMS Quote PDF
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item
                    v-if="(isRFQNew || (isRFQPriced && (priceResubmitActiveFlag || isUnLockedSupplierMain()))) && !rfq.undoAvailable && !isSelectedImportCSV && isEnableSettingsRfqImportCsvQuote"
                    :disabled="(!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq"
                    :class="{'disabled-item': (!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq}"
                    @click="importQuote('csv')"
                    class="import-dms-option">
                  Import DMS Quote CSV
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isRfqToOemGen && isRFQNew"
                                 :disabled="(!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq"
                                 :class="{'disabled-item': (!isRfqToOemGen && rfq.dueCountdown == '-1' && isRFQNew) || isLockedRfq}"
                                 @click="sendPartNumbers">
                  Send Part Nbrs/List
                </b-dropdown-item>
              </div>

              <div class="" v-if="rfq.id && isRfqToOemGen && !rfq.scheduleTime && isRFQNew"
                   @mouseover="showOrHideScheduleContext(true)" @mouseleave="showOrHideScheduleContext(false)">
                <b-dropdown-item
                    :disabled="isLockedRfq"
                    :class="{'disabled-item': isLockedRfq}"
                    class="assign-to-option schedule-option">
                  <i style="top: 3px; left: 2px;" class='bx bx-chevron-left font-18'></i>Schedule Submit Quote
                </b-dropdown-item>
                <div
                    v-if="isShowScheduleContext"
                    :style="{
                      top: getTopForScheduleContext() + 'px'
                    }"
                    class="assign-to-context"
                    style="width: 224px; left: -224px">
                  <div class="schedule-context">
                    <p class="schedule-text f-12 font-inter--semibold">Time & Date</p>
                    <date-picker
                        v-model="scheduleDateTime"
                        :placeHolderText="'hh:mmA || dd/mm/yyyy'"
                        @updatedValue="onDatePickerChange"
                        @mouseover="onDatePickerChange"
                        :isDateTime="true"></date-picker>
                    <p class="schedule-text f-12 font-inter--semibold">Scheduled Submission</p>
                    <p v-if="scheduleDateTime" class="schedule-text f-12 font-inter--semibold">{{ scheduleDateTime }}<i
                        @click="saveSchedule(false)" class="bx bx-save save-comment-icon"></i></p>
                    <p v-else class="schedule-text f-12 font-inter--semibold">No Time Scheduled</p>
                  </div>
                </div>
              </div>

              <div v-else-if="isRfqToOemGen && isRFQNew" @mouseover="hideScheduleContext" class="">
                <b-dropdown-item :class="{'disabled-item': isLockedRfq}" class="remove-schd-submit-item"
                                 @click="saveSchedule(true)">Remove Schd
                  Submit Quote
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item :class="{'disabled-item': isLockedRfq}" class="remove-schd-submit-item"
                                 v-if="!isRFQClosed && !isRFQDeclined && !isRFQOrdered" @click="$refs.declineModal.show()">
                  <span v-if="rfq.properties && rfq.properties.isSentAsDirectOrder">Decline Direct Order</span>
                  <span v-else>Decline Quote</span>
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item
                    v-if="isCanLock"
                    @click="lockQuote(true)">
                  Lock Quote
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item
                    v-if="isCanUnLock"
                    @click="lockQuote(false)">
                  Unlock Quote
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item
                    v-if="rfq && rfq.properties && rfq.properties.isEnableNillStock"
                    :class="{'disabled-item': isLockedRfq || !isActiveSendNillStock}"
                    @click="sendNillStock"
                    :disabled="isLockedRfq || !isActiveSendNillStock">
                  Send Nil Stock
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="!isRFQWon && !isRFQOrdered && !isRFQClosed && !isRFQDeclined"
                                 :class="{'disabled-item': isLockedRfq}" :disabled="isLockedRfq" @click="closeQuote">
                  Close
                  Rfq
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isRFQClosed || isRFQDeclined" :disabled="isLockedRfq"
                                 :class="{'disabled-item': isLockedRfq}" @click="deleteQuote">Delete Rfq
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isRFQWon" :disabled="isLockedRfq" :class="{'disabled-item': isLockedRfq}"
                                 @click="processOrder">Process Order
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isRFQOrdered" :disabled="isLockedRfq" :class="{'disabled-item': isLockedRfq}"
                                 @click="viewOrder">View Order
                </b-dropdown-item>
              </div>

              <div @mouseover="hideScheduleContext" class="">
                <b-dropdown-item v-if="isEditable" :disabled="isLockedRfq" :class="{'disabled-item': isLockedRfq}"
                                 @click="cancelEdit">Cancel Edit
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>

        </template>
      </tabs>
      <transition name="slide-fade">
        <div v-if="isPinImage" class="image-pin-area">
          <label class="pin-image">
            <div class="pin-image-label">Pin Images</div>
            <input type="checkbox" name="pinimage" v-model="pinImage"
                   @change="$store.commit('supplier/rfq/updatePinImage', pinImage)">
            <span class="pin-checkmark"><i class="fa fa-check"></i></span>
          </label>
          <image-lightbox-without-gallery
              :isRemove="false"
              :isEdit="false"
              :isDownload="false"
              :isPreviewImage="'true'"
              :position="'pin_images'"
              :imageList="imageSource"
              :path="''"
              @selected="pinClickImage"
          ></image-lightbox-without-gallery>
        </div>
      </transition>

      <template v-if="shownGallery">
        <image-gallery :use-editor="false"
                       :isDownload="false"
                       :isRemove="false"
                       :images="reversedImageList()"
                       :current-index="galleryCurrentIndex"
                       @close-gallery="onCloseGallery">
        </image-gallery>
      </template>

      <b-modal ref="declineModal" id="auto-lock-block-modal" size="custom-auto-lock-block"
               :title="rfq.properties && rfq.properties.isSentAsDirectOrder ? 'Decline Direct Order ' + rfq.number : 'Decline Quote ' + rfq.number">
        <template v-slot:modal-backdrop class="my-backdrop"></template>
        <div class="modal-auto-lock-block form-container">
          <div class="pr-3 pl-3 mt-3">
            <p>You about to Decline
              <span v-if="rfq.properties && rfq.properties.isSentAsDirectOrder">Direct Order</span>
              <span v-else>Quote</span> <b>{{ rfq.number }}</b> for <b>{{ rfq.repairer }}</b></p>
          </div>
          <p class="pr-3 pl-3">Provide a reason below to notify them of this decision.</p>
          <b-textarea v-model="declineReason" class="deny-rfq-modal-textarea">
          </b-textarea>
        </div>
        <template v-slot:modal-footer="{ cancel, yes }">
          <div class="d-flex w-100 justify-content-end mb-3">
            <button class="input-btn modal-decline-rfq-btn-no" @click="cancel()">Cancel</button>
            <button :disabled="!declineReason.length" class="input-btn modal-decline-rfq-btn-yes" @click="declineRfq">
              Confirm
            </button>
          </div>
        </template>
      </b-modal>

      <b-modal ref="autoLockSettingsBlock" id="auto-lock-block-modal" size="custom-auto-lock-block"
               title="Notification - Quote is Locked">
        <template v-slot:modal-backdrop class="my-backdrop"></template>
        <div class="modal-auto-lock-block form-container">
          <div class="pr-3 pl-3">
            <p>This quote has been locked by <b>{{ computedLockedByName }}</b></p>
          </div>
          <div class="pr-3 pl-3">
            <p>{{ computedLockedText }}</p>
          </div>
        </div>
        <template v-slot:modal-footer="{ ok }">
          <template>
            <b-button style="width: 76px !important;" class="input-btn btn modal-auto-lock-block-btn" @click="ok()">
              Close
            </b-button>
          </template>
        </template>
      </b-modal>

      <b-modal ref="partsDiagramRequested" size="custom-auto-lock-block"
               title="Parts Diagram Requested">
        <template v-slot:modal-backdrop class="my-backdrop"></template>
        <div class="modal-auto-lock-block form-container">
          <div class="pr-3 pl-3">
            <p>Your customer has requested a <b>Parts Diagram</b> with the quote.</p>
          </div>
          <div class="pr-3 pl-3">
            <p>Screenshot and Paste a diagram in the <b>Files</b> section.</p>
          </div>
        </div>
        <template v-slot:modal-footer="{ ok }">
          <template>
            <b-button style="width: 76px !important;" class="input-btn btn modal-auto-lock-block-btn" @click="ok()">
              Close
            </b-button>
          </template>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comments-blinks-text {
  color: #F59345;
  font-size: 14px;
}

.comments-after-blinks-text {
  color: black;
}

.comments-blinks-text.active {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.supplier-view-files-wrapper {
  flex-direction: row-reverse !important;
  justify-content: start !important;
}

.supplier-view-files-wrapper {
  flex-direction: row-reverse !important;
  justify-content: start !important;
}

.supplier-rfq-view-page .part-list-tab-supplier .paint-code {
  margin-left: 50px !important;
}


.w-21 {
  width: 21%;
}

.block-uploader {
  margin-top: 15px;
}

.image-block {
  width: 864px;
  margin-top: 15px;
  margin-left: 15px;
}


.image-block {
  width: 100%;
  padding: 15px 15px 0 15px;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;
}

.files-diagram-wrapper {
  max-width: 600px;
}

.diagram-image {
  min-height: 300px;
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
  cursor: pointer;
}

.files-diagram-block {
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 300px;
}

.files-diagram-block-active {
  border: 1px solid #29BBC1;
  border-radius: 3px;
  box-shadow: 0 0 0 0.2rem #29BBC1;
}

.diagram-block-image {
  min-height: 325px;
  border: none;
}

.diagram-image {
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diagram-block-line {
  margin-left: 15px;
  margin-right: 15px;
  height: 2px;
  background-color: rgba(27, 30, 56, 0.25);
}

.diagram-block-button {
  margin: 15px;
}

.diagram-block-button button {
  padding: 10px 20px;
}

.diagram-block-button button:disabled {
  background-color: #a8a8a8 !important;
  border-color: #a8a8a8 !important;
  cursor: not-allowed;
}

.diagram-block-button span {
  color: rgba(0, 0, 0, .5);
  font-weight: normal;
}

@media screen and (min-width: 993px) {
  .image-block {
    width: 864px;
    padding: unset;
    margin-left: 15px;
    margin-top: 15px;
  }
}

.border-for-paint-code {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #29bbc1, 0 0 3px 1px #29bbc1;
  }
}

.import-dms-additional-block .customer-account-deliver {
  margin: 25px 0 0 0;
}

.border-for-paint-code {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #29bbc1, 0 0 3px 1px #29bbc1;
  }
}

.vehicle-details-information-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vehicle-details-information-block p {
  margin-bottom: 0;
  font-weight: bold;
  color: #1C1F39;
  margin-right: 10px;
  margin-left: 20px;
  padding-top: 7px;
}


@media (max-width: 768px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn, .tabs-component .tab-header.float-sm-right div.tabs-nav-right-options {
    display: block !important;
  }

  .theme-supplier .dropdown-options {
    width: 90px;
  }

  .theme-supplier .dropdown-options .dropdown-arrow {
    width: 20px;
  }

  .theme-supplier .dropdown-options .dropdown-arrow .bx-down-arrow {
    margin: 10px 10px 10px 5px;
  }

  .supplier-rfq-view-page .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn {
    display: none !important;
  }
}

.import-dms-additional-block .customer-account-deliver {
  margin: 25px 0 0 0;
}
</style>

<style>
.theme-supplier .supplier-rfq-view-page .search-filter-in-header .btn,
.theme-supplier .supplier-rfq-view-page .search-filter-in-header .btn-chat {
  margin-right: 0px !important;
  min-width: 120px;
}

.theme-supplier .supplier-rfq-view-page .window-notifications {
  right: 120px;
}

.theme-supplier .supplier-rfq-view-page .search-filter-in-header .window-chat {
  right: 90px !important;
}

.supplier-rfq-view-page .send-direct-order-comment-block {
  min-height: auto !important;
}
.direct-commnet-block span {
  word-break: break-all;
}

.send-direct-order-comment-block span {
  word-break: break-all;
}
.modal-direct-order-check .modal-header {
  color: #fff;
  background-color: #29BBC1;
  padding: 13px 28px;
}

.supplier-oem-gen-rfq-view-page .ask-for-parts-diagram-section {
  display: block !important;
}

.modal-direct-order-check {
  max-width: 500px !important;
  margin: 15rem auto !important;
}

.modal-direct-order-check .modal-content {
  border: none;
  border-radius: 5px;
}
.supplier-oem-gen-rfq-view-page .ask-for-parts-diagram-section {
  display: block !important;
}
.previous-quote {
  animation: blinker 1s linear infinite;
}

.rfq-view-direct-order-comment .send-direct-order-comment-block-blinking-text {
  animation: blinker 1s linear infinite;
}

.rfq-view-direct-order-comment-2 .send-direct-order-comment-block {
  border: none !important;

}

.rfq-view-direct-order-comment-2 .send-direct-order-comment-block .p-2 {
   padding-bottom: 0px !important;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.rfq-order-nbr-group .col {
  padding-left: 0px !important;
}

.rfq-order-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-left: 0px;
  color: #007bff;
  font-weight: bolder;
}

.rfq-order-number:hover {
  color: #0056b3;
}

.my-custom-toast-class-2 {
  background-color: white !important;
  color: black;
  border: 2px solid red;
  font-size: 16px;
}

.my-custom-toast-class-2 button {
  color: black;
  font-size: 14px;
}

.my-custom-toast-class-2 button:hover,
.my-custom-toast-class-2 .close-toast:hover {
  color: #d3d3d3;
}

.my-custom-toast-class-2 .close-toast {
  font-size: 20px;
  color: black;
}

.my-custom-toast-class-2 .toast-text {
  margin-top: 10px;
  font-size: 13px;
}

.special-instruction-block .pb-3 {
  padding-bottom: 1px !important
}

.supplier-rfq-view-page .dropdown-options .assign-to-option .dropdown-item {
  height: 46px !important;
  padding: 8px 5px 0px 5px !important;
  overflow: hidden;
}

.supplier-rfq-view-page .dropdown-options .schedule-option .dropdown-item,
.supplier-rfq-view-page .dropdown-options .request-time-option .dropdown-item {
  width: 177px !important;
}

.supplier-rfq-view-page .dropdown-options .assign-to-context .vc-popover-content-wrapper {
  position: fixed !important;
}

.supplier-rfq-view-page .diagram-block-image .dz-message {
  margin: 0;
  text-align: start;
}

.supplier-rfq-view-page .diagram-block-image-not-clickable {
  pointer-events: none;
  cursor: default;
}

.supplier-rfq-view-page .diagram-block-image .dz-message .upload-title {
  font-size: 15px;
  padding-top: 50px;
  padding-left: 30px;
}

.supplier-rfq-view-page .diagram-block-image .dz-details {
  display: none;
}

.supplier-rfq-view-page .diagram-block-image .dz-image,
.supplier-rfq-view-page .diagram-block-image .dz-image-preview {
  display: flex;
  justify-content: center;
}

.supplier-rfq-view-page .block-uploader .files-upload {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>

<style scoped>
.blinking-comment {
  font-weight: 800;
  color: red;
  font-size: 14px;
  line-height: 26px;
  animation: blink 1.5s infinite;
}
@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}
.blinking-comment span{
  font-weight: 500;
}
.dropdown-actions >>> button {
  background-color: #7bbec0 !important;
  border-color: #7bbec0 !important;
  color: #fdfdfd !important;
}

.dropdown-actions >>> button:hover {
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}

.dropdown-actions >>> button span {
  border-color: #7bbec0 !important;
}

.chat-image-gallery.active {
  z-index: 99999;
  position: absolute;
  top: 0;
}

.chat-image-gallery.active >>> .window-chat.active {
  position: fixed;
  right: 0;
}

.chat-image-gallery.active >>> .window-chat {
  position: fixed;
  right: 0;
}

.block-uploader >>> .dropify-wrapper {
  width: 50% !important;
  float: right;
}

@media screen and (max-width: 1400px) {
  .block-uploader >>> .dropify-wrapper {
    width: 100% !important;
  }
}

.compact-sidebar .image-pin-area {
  width: 220px;
}

.blinking-green {
  animation-name: blinking-green;
  animation-duration: 4s;
  font-size: 20px;
  color: white;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 21px;
}

@keyframes blinking-green {
  20% {
    color: #0cb458;
    opacity: 1;
  }
  80% {
    color: #0cb458;
    opacity: 1;
  }
}

.modal-decline-rfq-btn-no {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #1C1F39 !important;
  border: 1px solid #29BBC1;
  border-radius: 3px;
  background-color: white !important;
  margin-right: 10px;
}

.modal-decline-rfq-btn-yes {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #eee !important;
  border: 1px solid #79B1BB;
  border-radius: 3px;
  background-color: #79B1BB !important;
  margin-right: 10px;
}

.modal-decline-rfq-btn-yes:disabled:hover {
  color: #ccc !important;
  cursor: not-allowed;
}

.modal-decline-rfq-btn-yes:disabled {
  background-color: #bdbdbd !important;
}

.modal-decline-rfq-btn-yes:disabled:hover {
  color: #ccc !important;
  cursor: not-allowed;
}

.deny-rfq-modal-textarea {
  width: 92%;
  margin-top: 15px;
  margin-left: 15px;
  height: 80px;
}

.assign-to-context {
  position: absolute;
  top: 0px;
  left: -170px;
  width: 170px;
  padding: 0px;
  box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
  border: 3px;
  border-radius: 3px;
  background-color: #FFFFFF;

  max-height: 460px;
  overflow-y: auto;
  background-clip: border-box;
  overflow-x: hidden;
}

.assign-to-context .context-item {
  height: auto;
  min-height: 46px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding: 15px 5px 15px 15px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.assign-to-context .context-item:hover {
  background-color: rgba(41, 187, 193, 0.1);
  color: #29BBC1;
}

.assign-to-context input {
  margin: 15px;
  display: block;
  font-size: 0.875rem;
  white-space: nowrap;
  width: 140px;
}

.disabled-item {
  opacity: 0.5 !important;
}

.save-comment-icon {
  font-size: 25px !important;
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  right: 14px;
  bottom: 8px;
  top: auto;
}

.quote-number-menu {
  width: 138px;
  position: absolute;
  /*border: 1px solid #fff;*/
  z-index: 999999;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 3px;
  text-align: left;
  background: #fff;
  overflow: auto;
  max-height: 275px;
  top: 35px;
}

.quote-number-close {
  display: none;
  font-size: 14px;
  margin-top: 1px;
  line-height: 14px;
}

.quote-number-close:hover {
  color: red;
}

.quote-number-menu-item {
  min-height: 31px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 7px 15px;
}

.quote-number-menu-item:hover {
  border-bottom: 1px solid #DCDCDC;
  cursor: pointer;
  background-color: rgba(41, 187, 193, 0.1);
  color: #79B1BB;
  font-weight: bold;
}

.quote-number-menu-item:hover .quote-number-close {
  display: inline-block;
}

.paint-code-input {
  position: relative;
}

.paint-code-placeholder {
  position: absolute;
  top: 9px;
  left: 15px;
  font-size: 10px;
  font-weight: bold;
  color: black;
  animation-name: paint-code-placeholder;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes paint-code-placeholder {
  50% {
    color: #29bbc1;
  }
}

/* paint-code-placeholder {
  50% {
    text-shadow: #79B1BB 1px 0 3px;
  }
} */

@media screen and (max-width: 576px) {
  .vehicle-details-block {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .V3 .block .block-header,
  .V3 .block .block-body {
    margin: 15px;
  }

  .supplier-rfq-view-page .additional-info {
    flex-direction: column !important;
    width: 100% !important;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 950px) {
  .block-uploader {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.part-list-assigned-to-text {
  color: #1C1F39;
  font-weight: bold;
}
</style>

<style>
.supplier-rfq-view-page .vin-date {
  margin-top: 0px;
}

.remove-schd-submit-item .dropdown-item {
  padding-top: 9px !important;
  height: auto !important;
}

.supplier-rfq-view-page .schedule-context {
  padding: 15px;
  width: 224px;
}

.supplier-rfq-view-page .schedule-context .schedule-text {
  margin: 5px 0px 5px 0px;
}

.supplier-rfq-view-page .schedule-context .schedule-text:first-child {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .supplier-rfq-view-page .dropdown-options .dropdown-menu {
    min-width: 185px;
  }
}

.supplier-rfq-view-page .options-dropdown-menu-item-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.supplier-rfq-view-page .options-dropdown-menu-item-header header {
  padding: 15px;
}
</style>
