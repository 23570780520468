<script lang="ts">
import {defineComponent} from 'vue'
import dayjs from "dayjs";
import VueCountdown from '@chenfengyuan/vue-countdown';

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

export default defineComponent({
  name: "best-price-countdown",
  props: {
    isSecondStart: {
      type: Boolean,
      default: false,
    },
    isSecondEnd: {
      type: Boolean,
      default: false,
    },
    isThirdStart: {
      type: Boolean,
      default: false,
    },
    isThirdEnd: {
      type: Boolean,
      default: false,
    },
    endTime: {
      type: String,
      default: 0,
    },
    isSupplier:{
      type: Boolean,
      default: false,
    },
    isRepairer:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      time: 0,
    }
  },
  computed: {
    getTime() {
      if (dayjs(this.endTime).isAfter(dayjs())) {
        let time = dayjs(this.endTime).diff(dayjs())
        this.time = time
        return time
      } else {
        return -1
      }
    },
  },
  methods: {
    endCountdown() {
      this.$emit('endCountdown')
    }
  },
  mounted() {

  },
  components: {
    VueCountdown,
  }
})
</script>

<template>
  <div class="blinking-comment">
    <template v-if="(isSecondStart === true || (isSecondEnd === true && isThirdStart === true)) && getTime > 0">
      <div>
        <template v-if="isRepairer">BEST PRICE REQUEST SENT</template>
        <template  v-else-if="isSupplier">CUSTOMER READY TO ORDER - BEST PRICE REQUESTED</template>
        <br>
        <template v-if="isRepairer">Quote Open: </template>
        <template  v-else-if="isSupplier">RFQ Re-opened: </template>
        <span>
          <vue-countdown
              :interval="1000*60"
              :emitEvents="true"
              :time="getTime"
              @end="endCountdown">
            <template slot-scope="props">
              <template v-if="props.totalMinutes >= 60">60</template>
              <template v-else>{{ (props.totalMinutes + 1) }}</template>
              <span v-if="props.totalMinutes == 0"> Min</span><span v-else> Mins</span>
              <span v-if="props.totalMinutes<5"> Closing soon</span>
              <span v-else> remaining</span>
            </template>
          </vue-countdown>
         </span>
      </div>
    </template>
    <template v-else-if="getTime <0 || (isSecondEnd === true && (isThirdStart === false ||  isThirdEnd === true))">
      <div>Pricing now locked ({{ endTime | formatDate }})</div>
    </template>
  </div>
</template>

<style scoped>

</style>
