<script>
/* eslint-disable */
import draggable from 'vuedraggable'
import _ from "lodash";
import Axios from "axios";
import PdfViewer from '../../../../components/utility/pdf-viewer'

export default {
  name: 'repair-rfq-import-pdf',
  components: {
    draggable,
    PdfViewer
  },
  props: {
    importedParts: {
      type: Array,
      default() {return []}
    },
    originalParts: {
      type: Array,
      default() {return []}
    },
    supplierPrice: {
      type: Object,
      default() {return {}}
    },
    pdfFile: {
      type: Object,
      default() {return {
        id: null,
        src: null
      }}
    },
    importedPDF: {
      type: Object,
      default() {return {}}
    },
    isOemGen: {
      type: Boolean,
      default() { return false}
    },
    supplierId: {
      type: [Object, Number, String],
      default() {return ''}
    },
    rfqId: {
      type: [Object, Number, String],
      default() {return ''}
    },
  },
  data: function () {
    return {
      importedParts2: [],
      isAllPartsChecked: false,
      isViewPDF: false,
      documentHeight: 0,
      hoveredInd: -1,
      documentWidth: 0,
    }
  },
  mounted () {
    this.importedParts2 = _.cloneDeep(this.importedParts)
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    computedPath() {
      if (this.pdfFile?.src) {
        return this.pdfFile.src + '?at=' + localStorage.getItem('token')
      }
      return ''
    },
    computedSupplierName() {
      return _.find(this.supplierPrice?.orderedSuppliers, (s) => {
        return Number(s.id) == Number(this.supplierId)
      })?.name || ''
    },
    isHasOEMSupp() {
      return _.some(this.supplierPrice?.orderedSuppliers, (s) => {
        return s.isSupplierTypeOEMPAR
      })
    },
  },
  methods: {
    isMathedPrtNbr(ind) {
      if (!this.isHasOEMSupp) {
        return false
      }
      let oemComment = _.trim(this.supplierPrice.getPartNumber(this.originalParts[ind].id)).replaceAll(' ', '')
      let oeComment = this.importedParts2?.[ind]?.oeNumber ? _.trim(this.importedParts2?.[ind]?.oeNumber).replaceAll(' ', '') : ''
      if (this.isOemGen) {
        oeComment = this.importedParts2?.[ind]?.number
      }

      if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) &&  _.includes(oemComment, oeComment)) {
        return true
      }
      return false
    },
    formatQty(v) {
      return Number(v).toFixed()
    },
    handleResize() {
      this.documentHeight =  window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 180

      this.documentWidth =  window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth + 30
    },
    isHasCheckedParts() {
      return _.some(this.importedParts2, (p) => {
        return p.isChecked
      })
    },
    applyImport() {
      let r = {
        parts: [],
        pdfFileId: this.pdfFile?.id,
        rfqId: this.supplierId,
        QPNumber: this.importedPDF.QPNumber
      }
      let suppIndex = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => {
        return Number(s.id) === Number(this.supplierId)
      })

      _.forEach(this.originalParts, (p, i) => {
        if (this.importedParts2[i].isChecked && p.id) {
          let prt = this.importedParts2[i]
          if (prt.rrp !== null && this.isOemGen) {
            this.$emit('setRrp', {index: i, rrp: prt.rrp})
          }
          if (suppIndex !== -1) {
            let offerInd = _.findIndex(this.supplierPrice.orderedSuppliers[suppIndex].offers, (offer) => {
              return Number(offer.originalPartId) == Number(this.supplierPrice.parts[i].id)
            })
            if (offerInd !== -1) {
              this.$emit('setValueForOffer', {offerInd: offerInd, suppIndex: suppIndex, prt: prt})

              let obj = {
                id: this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].id,
                price: prt.price,
              }
              if (prt.rrp) {
                obj.rrp = prt.rrp
              }
              if (prt.oeNumber) {
                obj.oePartNumber = prt.oeNumber
              }
              if (prt.number) {
                obj.number = prt.number
              }
              if (prt.stock) {
                obj.stock = prt.stock
              }
              if (prt.qty) {
                obj.qty = this.formatQty(prt.qty)
              }
              if (prt.name) {
                obj.altPartName = prt.name
              }
              if (prt.stockComment) {
                obj.stockComment = prt.stockComment
              }
              if (_.isNumber(prt.stockStatus) || _.isString(prt.stockStatus)) {
                obj.stockStatus = prt.stockStatus
              }
              r.parts.push(obj)
            }
          }
        }
      })

      if (!_.isEmpty(r.parts)) {
        NProgress.start()
        Axios.post(`/ir/repairer/rfq/${this.rfqId}/modification-parts`, r)
            .then(response => {
              if (response.data._status) {

              }
            })
            .finally(() => {
              this.$emit('close')
              NProgress.done()
            })
      }
    },
    toggleCheckbox(part, index) {
      if (part.isAddedManually) {
        return
      }
      part.isChecked = !part.isChecked
      let checkedParts = _.filter(this.importedParts2, (p) => {
        return p.isChecked
      })
      this.isAllPartsChecked = checkedParts.length == _.filter(this.importedParts2, (p) => {return !p.isAddedManually}).length
    },
    onCheckboxChange() {
      let checkedParts = _.filter(this.importedParts2, (p) => {
        return p.isChecked
      })
      this.isAllPartsChecked = checkedParts.length == _.filter(this.importedParts2, (p) => {return !p.isAddedManually}).length
    },
    onAllCheckboxCheck() {
      _.forEach(this.importedParts2, (p) => {
        if (!p.isAddedManually) {
          p.isChecked = this.isAllPartsChecked
        }
      })
    },
  }
}

</script>

<template>
  <div class="import-pdf-section prevent-select import-pdf-nr-supp" :class="{'import-pdf-nr-supp-pdf-wrapper': isViewPDF}">

    <div style="margin-top: 26px;">
      <div class="d-flex">
        <div class="" style="width: 30%"></div>
        <div class="" style="width: 30%; display: flex; align-items: center; flex-direction: column">
          <p style="font-weight: bold; font-size: 14px;">Imported PDF Pricing</p>
          <p>Confirm the Quote information below is correct</p>
        </div>
        <div class="" style="width: 30%; display: flex; justify-content: end">
          <div class="" style="display: flex; flex-direction: column">
            <div class="d-flex"><div style="color: #a0a1a7; font-weight: 700; width: 100px">Supplier:</div> <div style="font-weight: 700; color: #1c1f39">{{computedSupplierName}}</div></div>
            <div class="d-flex"><div style="color: #a0a1a7; font-weight: 700; width: 100px">Estimate Nbr:</div> <div v-if="importedPDF && importedPDF.quoteNumber" style="font-weight: 700; color: #1c1f39">{{importedPDF.quoteNumber}}</div></div>
            <div class="d-flex"><div style="color: #a0a1a7; font-weight: 700; width: 100px">VIN:</div> <div v-if="importedPDF && importedPDF.vehicleVin" style="font-weight: 700; color: #1c1f39">{{importedPDF.vehicleVin}}</div></div>
          </div>
        </div>
      </div>

      <div class="d-flex" style="justify-content: end">
        <div class="">
          <button style="height: 41px; box-shadow: none" type="button"
                  @click="isViewPDF = false"
                  :class="{
                  'btn btn-secondary waves-effect waves-light browse-btn browse-btn-files bg-white': !isViewPDF,
                  'confirm-button btn btn-primary waves-effect waves-light browse-btn browse-btn-files': isViewPDF,
                }">
            View Import
          </button>
          <button style="height: 41px; margin-left: 15px; box-shadow: none; margin-right: 150px" type="button"
                  @click="isViewPDF = true"
                  :class="{
                  'btn btn-secondary waves-effect waves-light browse-btn browse-btn-files bg-white': isViewPDF,
                  'confirm-button btn btn-primary waves-effect waves-light browse-btn browse-btn-files': !isViewPDF,
                }"
          >
            View PDF
          </button>
        </div>
      </div>


      <div v-if="!isViewPDF" class="box box-block rs-scroll rs-scroll--y" style="overflow: auto; margin: 10px">
        <div class="import-pdf-section-table">
          <div class="d-flex">
            <div class="d-flex import-pdf-section-header" style="width: 21%">
              <div style="width: 50px; padding-left: 5px">Line</div>
              <div style="flex-grow: 1">Your Part Description</div>
            </div>
            <div class="d-flex import-pdf-section-header" style="width: 79%">
              <div style="flex-grow: 1">Supplier Part Description</div>
              <div style="flex-grow: 1">Supplier OE Part Number</div>
              <div style="width: 60px">Qty</div>
              <div style="width: 100px">Unit List</div>
              <div style="width: 100px">Unit Net</div>
              <div style="border: 0; width: 70px; padding-left: 20px">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input" v-model="isAllPartsChecked" @change="onAllCheckboxCheck"
                         type="checkbox" name="allCheckbox">
                  <span class="icon clickable"><i class='bx bx-check'></i></span>
                </label>
              </div>
            </div>

          </div>

          <div class="d-flex" style=" flex-wrap: nowrap;">
            <div class="left-column" style=" flex-grow: 1; width: 21%">
              <div
                  class="d-flex"
                  v-for="(part, index) in originalParts"
                  @mouseover="hoveredInd = index"
                  @mouseleave="hoveredInd = -1">
                <div class="import-pdf-section-table-item" style="width: 50px; border: 0"><span v-if="part.id">{{ index + 1 }}</span></div>
                <div
                    class="import-pdf-section-table-item"
                    :class="{'hovered-itm': index == hoveredInd}"
                    style="flex-grow: 1; border: 2px solid #29bbc1; font-size: 12px; line-height: 15px"> {{ part.name }}</div>
              </div>
            </div>
            <draggable
                v-model="importedParts2"
                class="dragArea"
                tag="tbody"
                handle=".handle"
                style="flex-grow: 1; width: 79%">
              <div
                  v-for="(part, index) in importedParts2"
                  @mouseover="hoveredInd = index"
                  @mouseleave="hoveredInd = -1"
                  class="handle d-flex right-column"
                  :class="{'active': part.isChecked}">
                <div class="right-column-item" style="flex-grow: 1" @click="toggleCheckbox(part, index, $event)" :class="{'hovered-itm': index == hoveredInd}">
                  {{ part.name }}
                </div>
                <div class="right-column-item"
                     :class="{
                        'matched-item': isMathedPrtNbr(index),
                        'hovered-itm': index == hoveredInd
                      }"
                     style="flex-grow: 1" @click="toggleCheckbox(part, index, $event)"
                >
                  <div
                      v-if="isMathedPrtNbr(index)"
                      class="dark-green-triangle-icon"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'Suppliers OE reference nbr matches', placement:'leftbottom'}"></div>
                  <div class="">
                    <template v-if="!isOemGen">
                      {{ part.oeNumber}}
                    </template>
                    <template v-else>
                      {{ part.number }}
                    </template>
                  </div>
                </div>
                <div class="right-column-item" style="width: 60px" @click="toggleCheckbox(part, index, $event)" :class="{'hovered-itm': index == hoveredInd}">
                  <template v-if="!part.isAddedManually">
                    {{ formatQty(part.qty) }}
                  </template>
                </div>
                <div class="right-column-item" style="width: 100px; text-align: center" @click="toggleCheckbox(part, index, $event)" :class="{'hovered-itm': index == hoveredInd}">
                  <template v-if="!part.isAddedManually">
                    {{ part.rrp | formatMoney }}
                  </template>
                </div>
                <div class="right-column-item" style="width: 100px; text-align: center" @click="toggleCheckbox(part, index, $event)" :class="{'hovered-itm': index == hoveredInd}">
                  <template v-if="!part.isAddedManually">
                    {{ part.price | formatMoney }}
                  </template>
                </div>
                <div class="right-column-item" style="border: 0; width: 70px; padding-left: 20px; padding-top: 3px" :class="{'hovered-itm': index == hoveredInd}">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="part.isChecked"
                           :disabled="part.isAddedManually"
                           @change="onCheckboxChange"
                           name="partCheckbox">
                    <span
                        role='button'
                        class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>

      <pdf-viewer
          v-else
          style="margin-top: 15px"
          @closePdf="isViewPDF = false"
          :documentHeight="documentHeight"
          :documentWidth="documentWidth"
          :documentPath="computedPath"></pdf-viewer>

      <div v-if="!isViewPDF" class="d-flex" style="justify-content: end; height: 150px">
        <button style="height: 41px; margin-left: 15px;" type="button"
                class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Report Issue
        </button>
        <button  style="height: 41px; margin-left: 25px;" type="button" @click="$emit('close')"
                class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Cancel
        </button>
        <button
            :disabled="!isHasCheckedParts()"
            style="height: 41px; margin-left: 15px; margin-right: 150px;" type="button"
            @click="applyImport"
            class="confirm-button btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Apply
        </button>
      </div>
    </div>

  </div>
</template>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.import-pdf-nr-supp .pdf-viewer-close-button {
  margin-right: 14px !important;
}
</style>

<style scoped>
.confirm-button:hover {
  opacity: 0.9;
}

.confirm-button:disabled {
  opacity: 0.65 !important;
}

.btn-secondary:hover {
  color: #1C1F39 !important;
  background-color: #EEEEEE !important;
  border-color: #AAAAAA !important;
}

.hovered-itm {
  background-color: #e9f8f9 !important;
}
.dark-green-triangle-icon {
  position: relative;
  transform: translate(16px, -2px);
  border: 8px solid transparent;
  border-top: 8px solid #1B9C2CFF;
  height: 16px;
  border-right: 8px solid #1B9C2CFF;
  z-index: 999;
}

.matched-item {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.bg-white {
  background-color: white !important;
}
.import-pdf-nr-supp-pdf-wrapper {
  padding-bottom: 0px !important;
}
label.checkbox > input[type="checkbox"] + span.icon i{
  color: #fdfdfd;
  background-color: #77afb9;
  height: 25px;
  border-radius: 3px;
}
label.checkbox > input[type=checkbox]:checked + span.icon i{
  color: white !important;
}
.import-pdf-section-table{
  min-width: 1150px;
  overflow-x: auto;
}
.left-column  div > div,
.right-column .right-column-item {
  cursor: pointer;
  padding: .1rem 15px;
  border: 1px solid rgba(27, 30, 56, .25);
  width: 150px;
  height: 35px;
  margin: 0 5px 5px;
}

.right-column.disabled-column-item .right-column-item,
.right-column.disabled-column-item .right-column-item .form-check-inline .icon,
.confirm-button:disabled {
  cursor: not-allowed !important;
}

.import-pdf-section-header{
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid #b3b3b3;
}
.import-pdf-section-header div{
  /*border: 1px solid #1d1d21bd;*/
  font-size: 15px;
  padding: .75rem 15px;
  font-weight: 700;
  margin: 0 5px;
}
.import-pdf-section-table-item{
  margin: 0 5px 5px;
  height: 35px;
}
.dragArea .right-column.active .right-column-item{
  border: 2px solid rgb(41, 187, 193);
  background-color: #e9f8f9 !important;
}
.dragArea .right-column.active .right-column-item:last-child{
  background-color: #ffffff !important;
}
</style>
